import React from "react";
import * as styles from "./styles.module.scss";
import { ISliceIntroText } from "~schemas";
import { doesRawTextHaveContent } from "~utils";
import {
  Grid,
  IntersectionAnimation,
  PortableTextRenderer,
  SlicePadding,
  UniversalLink
} from "~components";
import { useInView } from "react-intersection-observer";

interface IProps {
  content: ISliceIntroText;
}

const SliceIntroText = ({ content }: IProps) => {
  const { _rawLeftColumn, _rawRightColumn, universalLink: link } = content;

  const hasRawTextContent = doesRawTextHaveContent(
    _rawLeftColumn,
    _rawRightColumn
  );

  if (!hasRawTextContent) return null;

  const hasLink = link?.link?.[0];

  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <div ref={ref}>
      <SlicePadding>
        <Grid>
          {_rawLeftColumn && (
            <div className={styles.leftColumn}>
              <IntersectionAnimation trigger={inView}>
                <PortableTextRenderer
                  textElement="h2"
                  rawText={_rawLeftColumn}
                  className="h1"
                />
              </IntersectionAnimation>
            </div>
          )}

          {_rawRightColumn && (
            <div className={styles.rightColumn}>
              <IntersectionAnimation
                className={styles.rightColumnText}
                delay={150}
                trigger={inView}
              >
                <PortableTextRenderer
                  textElement="p"
                  className="b1"
                  rawText={_rawRightColumn}
                />
              </IntersectionAnimation>

              {hasLink && (
                <IntersectionAnimation delay={300} trigger={inView}>
                  <UniversalLink
                    jumpToSection={link.jumpToSection}
                    link={link.link}
                    text={link.text || `Learn more`}
                    iconRight="arrowRight"
                  />
                </IntersectionAnimation>
              )}
            </div>
          )}
        </Grid>
      </SlicePadding>
    </div>
  );
};

export default SliceIntroText;
