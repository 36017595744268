import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  className?: string;
  element?: string;
}

const Grid = ({ children, className, element }: IProps) => {
  const Element = element || (`div` as any);

  return <Element className={cn(styles.grid, className)}>{children}</Element>;
};
export default Grid;
