import React, { useState } from "react";
import type { IEntity } from "~schemas";
import { SlicePadding } from "~components";
import EntityAccordion, {
  type IProps as IEntityAccordion
} from "./components/EntityAccordion";

interface IProps {
  entity1: IEntity;
  entity2: IEntity;
  entity3: IEntity;
  entity4: IEntity;
}

type TActiveEntity = null | 1 | 2 | 3 | 4;

const MobileEntities = ({ entity1, entity2, entity3, entity4 }: IProps) => {
  const [activeEntity, setActiveEntity] = useState<TActiveEntity>(null);

  const entityAccordions: IEntityAccordion[] = [
    {
      entity: entity1,
      isActive: activeEntity === 1,
      mainColor: `var(--color-regent-grey)`,
      textColor: `var(--color-white)`,
      handleClick: () => setActiveEntity((prev) => (prev === 1 ? null : 1))
    },
    {
      entity: entity2,
      isActive: activeEntity === 2,
      mainColor: `#A7204C`,
      textColor: `var(--color-white)`,
      leftColor: `var(--color-regent-grey)`,
      handleClick: () => setActiveEntity((prev) => (prev === 2 ? null : 2))
    },
    {
      entity: entity3,
      isActive: activeEntity === 3,
      mainColor: `#A75F03`,
      textColor: `var(--color-white)`,
      rightColor: `#A7204C`,
      handleClick: () => setActiveEntity((prev) => (prev === 3 ? null : 3))
    },
    {
      entity: entity4,
      isActive: activeEntity === 4,
      mainColor: `#1C1D3A`,
      textColor: `var(--color-white)`,
      leftColor: `#A75F03`,
      handleClick: () => setActiveEntity((prev) => (prev === 4 ? null : 4))
    }
  ];

  return (
    <SlicePadding xOnly>
      {entityAccordions.map((accordion, i) => (
        <EntityAccordion {...accordion} key={i} />
      ))}
    </SlicePadding>
  );
};

export default MobileEntities;
