import React from "react";
import * as styles from "./styles.module.scss";
import { IAltImage, ICaseStudy, IGlobalLink } from "~schemas";
import cn from "classnames";
import { SVG } from "~components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

interface IProps {
  className?: string;
  globalLink?: IGlobalLink;
  caseStudy?: ICaseStudy;
  backgroundColor: string;
  textColor: string;
}

const LinkTile = ({
  globalLink,
  caseStudy,
  backgroundColor,
  textColor,
  className
}: IProps) => {
  if (globalLink) {
    const { link: linkArray } = globalLink;

    const link = linkArray?.[0];

    if (!link) return null;

    const { category, image, title } = globalLink;

    const Content = () => (
      <LinkTileContent
        backgroundColor={backgroundColor}
        textColor={textColor}
        category={category}
        image={image}
        title={title}
      />
    );

    return (
      <a
        className={cn(className, styles.linkTile)}
        href={link.url}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Content />
      </a>
    );
  }

  if (caseStudy) {
    const { slug, title, linkTileImage } = caseStudy;

    return (
      <Link
        to={`/awesome-stories/${slug.current}`}
        className={cn(className, styles.linkTile)}
      >
        <LinkTileContent
          backgroundColor={backgroundColor}
          textColor={textColor}
          category="Case Study"
          title={title}
          image={{ ...linkTileImage, altText: title }}
        />
      </Link>
    );
  }

  return null;
};

export default LinkTile;

interface ILinkTileContentProps {
  backgroundColor?: string;
  textColor?: string;
  image: IAltImage;
  category: string;
  title: string;
}

const LinkTileContent = ({
  backgroundColor,
  textColor,
  image,
  category,
  title
}: ILinkTileContentProps) => {
  const backgroundStyle = {
    background: backgroundColor || `var(--color-black)`
  };

  return (
    <div
      className={styles.content}
      style={{
        background: backgroundColor || `var(--color-black)`,
        color: textColor || `var(--color-white)`
      }}
    >
      <div className={styles.text}>
        <div className={cn(styles.subheading, `b2`)}>{category}</div>
        <h3 className="h3">{title}</h3>
      </div>
      <div className={styles.imageContainer}>
        <GatsbyImage
          alt={image.altText || ``}
          image={image.asset.gatsbyImageData}
          className={styles.image}
          style={{ position: `absolute`, inset: 0 }}
        />
        <div className={styles.iconContainer} style={backgroundStyle}>
          <SVG svg="arrowRightCircle" className={styles.icon} />
        </div>
        <div
          className={cn(styles.blockGraphic, styles.topLeft)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.middleLeft)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.bottomLeft)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.bottomMiddle)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.bottomRight)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.middleRight)}
          style={backgroundStyle}
        />
      </div>
    </div>
  );
};
