import React from "react";
import * as styles from "./styles.module.scss";
import { ICountUp } from "~schemas";
import cn from "classnames";
import CountUpItem from "./components/CountUpItem";

interface IProps {
  data: ICountUp;
}

const CountUp = ({ data: { items } }: IProps) => {
  return (
    <div
      className={cn(styles.container, {
        [styles.fourItems]: items.length === 4
      })}
    >
      {items?.[0] &&
        items.map((countUpItem, i) => {
          return <CountUpItem key={i} item={countUpItem} />;
        })}
    </div>
  );
};

export default CountUp;
