import React from "react";
import cn from "classnames";
import { IFilters } from "../..";
import * as styles from "./styles.module.scss";
import { SVG } from "~components";
import { useAccordion } from "~hooks";
import FilterItem from "../FilterItem";
import CategoryFilterItem from "../CategoryFilterItem";

interface IProps {
  className?: string;
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
}

const Filters = ({
  className,
  filters: { categories, types },
  setFilters
}: IProps) => {
  const {
    containerHeight: categoriesContainerHeight,
    contentRef: categoriesContentRef,
    isExpanded: isCategoriesExpanded,
    setIsExpanded: setIsCategoriesExpanded,
    calculateContentHeight: calculateCategoriesContentHeight
  } = useAccordion();

  const {
    containerHeight: typesContainerHeight,
    contentRef: typesContentRef,
    isExpanded: isTypesExpanded,
    setIsExpanded: setIsTypesExpanded
  } = useAccordion();

  const areAnyCategoryFiltersActive = categories?.some(
    (category) => category.active
  );
  const areAnyTypeFiltersActive = types?.some((type) => type.active);

  const clearAllCategoryFilters = () => {
    setFilters((prev) => ({
      ...prev,
      categories: prev.categories.map((category) => ({
        ...category,
        active: false,
        subcategories: category.subcategories?.map((subcategory) => ({
          ...subcategory,
          active: false
        }))
      }))
    }));
  };

  const clearAllTypeFilters = () => {
    setFilters((prev) => ({
      ...prev,
      types: prev.types.map((type) => ({
        ...type,
        active: false
      }))
    }));
  };

  return (
    <div className={cn(className)}>
      {categories?.[1] && (
        <div
          className={cn(styles.filterContainer, {
            [styles.isExpanded]: isCategoriesExpanded
          })}
        >
          <button
            onClick={() => setIsCategoriesExpanded((prev) => !prev)}
            className={cn("b1", styles.expandButton)}
          >
            Category
            <SVG svg="chevronDown" className={styles.chevronDown} />
          </button>

          <div
            className={styles.accordion}
            style={{ height: categoriesContainerHeight }}
          >
            <div ref={categoriesContentRef} className={styles.accordionContent}>
              <FilterItem
                className={styles.filterItem}
                isActive={!areAnyCategoryFiltersActive}
                onChange={clearAllCategoryFilters}
                text="All"
              />
              {categories.map((category) => (
                <CategoryFilterItem
                  key={category.title}
                  className={styles.filterItem}
                  category={category}
                  setFilters={setFilters}
                  calculateCategoriesContentHeight={
                    calculateCategoriesContentHeight
                  }
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {types?.[1] && (
        <div
          className={cn(styles.filterContainer, {
            [styles.isExpanded]: isTypesExpanded
          })}
        >
          <button
            onClick={() => setIsTypesExpanded((prev) => !prev)}
            className={cn("b1", styles.expandButton)}
          >
            Types
            <SVG svg="chevronDown" className={styles.chevronDown} />
          </button>

          <div
            className={styles.accordion}
            style={{ height: typesContainerHeight }}
          >
            <div ref={typesContentRef} className={styles.accordionContent}>
              <FilterItem
                className={styles.filterItem}
                isActive={!areAnyTypeFiltersActive}
                onChange={clearAllTypeFilters}
                text="All"
              />
              {types.map((type) => (
                <FilterItem
                  key={type.title}
                  className={styles.filterItem}
                  isActive={type.active}
                  text={type.title}
                  onChange={(e) => {
                    setFilters((prev) => ({
                      ...prev,
                      types: prev.types.map((t) => {
                        if (t.title === type.title) {
                          return {
                            ...t,
                            active: e.target.checked
                          };
                        }
                        return t;
                      })
                    }));
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
