import React, { useEffect, useState } from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { IToolboxResource } from "~schemas";
import { SVG } from "~components";
import { useApp, useBreakpoints } from "~hooks";

type TTooltipText = "Add to Toolbox" | "Remove from Toolbox";

interface IProps {
  resource: IToolboxResource;
}

const ResourceCard = ({
  resource: {
    category,
    description,
    subheading1,
    subheading2,
    name,
    link,
    _id,
    type,
    additionalResources,
    subcategories
  }
}: IProps) => {
  const [tooltipText, setTooltipText] =
    useState<TTooltipText>("Add to Toolbox");

  const { largeTablet } = useBreakpoints();

  const { toolbox, setToolbox, updateNotification } = useApp();

  const addToToolbox = () => {
    setToolbox((prev) => {
      const existingItem = prev.find((contact) => contact._id === _id);

      if (existingItem) {
        return prev;
      }

      const newItem: IToolboxResource = {
        _id,
        category,
        description,
        name,
        type,
        additionalResources,
        link,
        subcategories,
        subheading1,
        subheading2
      };

      return [...prev, newItem];
    });
    updateNotification("Added to your Toolbox!");
  };

  const removeFromToolbox = () => {
    setToolbox((prev) => {
      return prev.filter((resource) => resource._id !== _id);
    });
  };

  const handleButtonClick = () => {
    isInToolbox ? removeFromToolbox() : addToToolbox();
  };

  const isInToolbox = toolbox.find((resource) => resource._id === _id);

  const hasLink = link?.title && link.url;

  useEffect(() => {
    const TIME_FOR_ASIDE_TO_APPEAR = 500;

    if (isInToolbox) {
      setTimeout(() => {
        setTooltipText("Remove from Toolbox");
      }, TIME_FOR_ASIDE_TO_APPEAR);
    } else {
      setTooltipText("Add to Toolbox");
    }
  }, [isInToolbox]);

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.isInToolbox]: isInToolbox
      })}
    >
      <div className={styles.border} aria-hidden />

      <div className={styles.cardContent}>
        <div>
          <div className={cn("caption", styles.categoryTag)}>
            {category.title}
          </div>

          <h1 className={cn(largeTablet ? "h3" : "h2", styles.title)}>
            {name}
          </h1>

          <div className={cn("b2", styles.subheading1)}>{subheading1}</div>
          <div className={cn("b2", styles.subheading2)}>{subheading2}</div>

          <p className={cn("b2", styles.description)}>{description}</p>
        </div>

        <div className={styles.bottomItems}>
          {hasLink && (
            <a
              className={cn("b2", styles.link)}
              href={link?.url}
              target="_blank"
            >
              {link?.title}

              <SVG svg="externalLink" className={styles.linkIcon} />
            </a>
          )}

          <div className={styles.toolboxButtonContainer}>
            <button
              onClick={handleButtonClick}
              className={cn("b2", styles.toolboxButton, {
                [styles.isInToolbox]: isInToolbox
              })}
            >
              <span className={styles.toolboxButtonText}>
                {isInToolbox ? "Added to Toolbox" : "Add to Toolbox"}
              </span>
              <SVG
                svg={isInToolbox ? "checkCircle" : "plusCircle"}
                className={styles.circleIcon}
              />

              <div className={styles.tooltip}>
                <span className={cn("b2", styles.tooltipText)}>
                  {tooltipText}
                </span>
                <div className={styles.triangle} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceCard;
