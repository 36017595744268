import React from "react";
import AtfLetter1M from "../../../Letters/1M";
import AtfLetter2A from "../../../Letters/2A";
import AtfLetter3K from "../../../Letters/3K";
import AtfLetter4E from "../../../Letters/4E";
import * as styles from "./styles.module.scss";
import type {
  IAltImage,
  ICaseStudy,
  ICaseStudyReference,
  IExternalUrl,
  IGlobalLink,
  IPageReference
} from "~schemas";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { SVG } from "~components";
import cn from "classnames";
import type { IRectangleDimensions, TQuadrantNumber } from "../..";
import getQuadrantSizeStyles from "./getQuadrantSizeStyles";

interface IProps {
  number: TQuadrantNumber;
  activeQuadrant: TQuadrantNumber;
  setActiveQuadrant: React.Dispatch<React.SetStateAction<TQuadrantNumber>>;
  contentData: IGlobalLink | ICaseStudy;
  quadrantContentSize: IRectangleDimensions;
  quadrantContainerSize: IRectangleDimensions;
  expandedQuadrantSizeMultiplier: number;
  isAnimationEnabled: boolean;
  setIsAnimationEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const Quadrant = ({
  number,
  activeQuadrant,
  setActiveQuadrant,
  contentData,
  quadrantContentSize,
  quadrantContainerSize,
  expandedQuadrantSizeMultiplier,
  isAnimationEnabled,
  setIsAnimationEnabled
}: IProps) => {
  let QuadrantPattern;
  switch (number) {
    case 1:
      QuadrantPattern = AtfLetter1M;
      break;
    case 2:
      QuadrantPattern = AtfLetter2A;
      break;
    case 3:
      QuadrantPattern = AtfLetter3K;
      break;
    case 4:
      QuadrantPattern = AtfLetter4E;
      break;

    default:
      QuadrantPattern = AtfLetter1M;
  }

  const getQuadrantBackgroundColor = () => {
    switch (number) {
      case 1:
        return `var(--color-light-pink)`;
      case 2:
        return `var(--color-gold)`;
      case 3:
        return `var(--color-beige)`;
      case 4:
        return `var(--color-construction-orange)`;
      default:
        return `var(--color-light-pink)`;
    }
  };

  const getQuadrantPosition = () => {
    switch (number) {
      case 1:
        return { top: 0, left: 0 };
      case 2:
        return { top: 0, right: 0 };
      case 3:
        return { bottom: 0, left: 0 };
      case 4:
        return { bottom: 0, right: 0 };
      default:
        return null;
    }
  };

  const isActiveQuadrant = number === activeQuadrant;

  const title = contentData?.title;
  let category = ``;
  let image: IAltImage;
  let link;

  const isCaseStudy = (data: IGlobalLink | ICaseStudy): data is ICaseStudy => {
    return data._type === "caseStudy";
  };

  if (isCaseStudy(contentData)) {
    category = "Case Study";
    image = contentData.linkTileImage as IAltImage;
    const linkData: ICaseStudyReference = {
      _key: contentData.slug.current,
      _type: `caseStudyReference`,
      caseStudy: {
        slug: {
          current: contentData.slug.current
        }
      }
    };

    link = linkData;
  } else {
    category = contentData.category;
    image = contentData.image;
    link = contentData.link[0];
  }

  const handleFocus = () => {
    setActiveQuadrant(number);
  };

  const handleBlur = () => {
    setActiveQuadrant(0);
  };

  const handleMouseMove = () => {
    setIsAnimationEnabled(true);
    setActiveQuadrant(number);
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.enableAnimation]: isAnimationEnabled
      })}
      style={getQuadrantSizeStyles(
        number,
        quadrantContainerSize,
        expandedQuadrantSizeMultiplier,
        activeQuadrant
      )}
      onMouseMove={handleMouseMove}
    >
      <QuadrantLink
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        link={link}
        title={title}
      >
        <div
          className={styles.contentContainer}
          style={{
            background: getQuadrantBackgroundColor(),
            height: `${quadrantContentSize.height}px`,
            width: `${quadrantContentSize.width}px`,
            ...getQuadrantPosition()
          }}
        >
          <div
            className={cn(styles.content, {
              [styles.active]: isActiveQuadrant
            })}
          >
            <div className={styles.leftColumn}>
              <div>
                <div className={cn(`b1`, styles.subheading)}>{category}</div>
                <h3 className="h2">{title}</h3>
              </div>

              <SVG className={styles.linkIcon} svg="arrowRightCircle" />
            </div>

            <div>
              {image && (
                <GatsbyImage
                  image={image.asset.gatsbyImageData}
                  alt={image.altText || ``}
                  style={{ height: `50%` }}
                />
              )}
            </div>
          </div>
        </div>
      </QuadrantLink>

      <div className={styles.quadrantPatternContainer}>
        <QuadrantPattern active={isActiveQuadrant} />
      </div>
    </div>
  );
};

export default Quadrant;

interface IQuadrantLink {
  children: JSX.Element;
  link: IExternalUrl | IPageReference | ICaseStudyReference;
  handleFocus: () => void;
  handleBlur: () => void;
  title: string;
}

const QuadrantLink = ({
  children,
  link,
  handleBlur,
  handleFocus,
  title
}: IQuadrantLink) => {
  if (link._type === "externalUrl") {
    return (
      <a
        onBlur={handleBlur}
        onFocus={handleFocus}
        href={link.url}
        className={styles.link}
        target="_blank"
        rel="noreferrer noopener"
        aria-label={`Go to link: ${title}`}
      >
        {children}
      </a>
    );
  }

  if (link._type === `pageReference`) {
    return (
      <Link
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={styles.link}
        to={`/${link.page.slug.current}`}
        aria-label={`Go to page: ${title}`}
      >
        {children}
      </Link>
    );
  }

  if (link._type === `caseStudyReference`) {
    return (
      <Link
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={styles.link}
        to={`/awesome-stories/${link.caseStudy.slug.current}`}
        aria-label={`Go to case study: ${title}`}
      >
        {children}
      </Link>
    );
  }

  return null;
};
