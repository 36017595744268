// extracted by mini-css-extract-plugin
export var border = "styles-module--border--fd6ca";
export var bottomItems = "styles-module--bottomItems--94008";
export var cardContent = "styles-module--cardContent--e8ebd";
export var categoryTag = "styles-module--categoryTag--59c49";
export var circleIcon = "styles-module--circleIcon--88a82";
export var description = "styles-module--description--0dfac";
export var desktop = "1340px";
export var giant = "2200px";
export var isInToolbox = "styles-module--isInToolbox--f3495";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--d1b8d";
export var linkIcon = "styles-module--linkIcon--04cf1";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheading1 = "styles-module--subheading1--9423a";
export var subheading2 = "styles-module--subheading2--679fb";
export var tablet = "769px";
export var title = "styles-module--title--cc269";
export var toolboxButton = "styles-module--toolboxButton--079a0";
export var toolboxButtonContainer = "styles-module--toolboxButtonContainer--15534";
export var toolboxButtonText = "styles-module--toolboxButtonText--397d0";
export var tooltip = "styles-module--tooltip--faebe";
export var tooltipText = "styles-module--tooltipText--ca01d";
export var triangle = "styles-module--triangle--d498f";
export var wrapper = "styles-module--wrapper--0f748";