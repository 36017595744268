import React from "react";
import { IUniversalLink } from "~schemas";
import cn from "classnames";
import { Link } from "gatsby";

interface IProps {
  link: IUniversalLink;
  children: React.ReactNode;
  className?: string;
}

const UniversalLinkWrapper = ({
  link: { link: linkArray, jumpToSection },
  children,
  className
}: IProps) => {
  const link = linkArray?.[0];
  const jumpToSectionText = jumpToSection ? `#${jumpToSection}` : "";

  switch (link._type) {
    case "externalUrl":
      return (
        <a
          className={cn(className)}
          target="_blank"
          rel="noopener noreferrer"
          href={link.url}
        >
          {children}
        </a>
      );

    case "pageReference":
      return (
        <Link
          to={`/${link.page.slug.current}${jumpToSectionText}`}
          className={cn(className)}
        >
          {children}
        </Link>
      );

    case "caseStudyReference":
      return (
        <Link
          to={`awesome-stories/${link.caseStudy.slug.current}${jumpToSectionText}`}
          className={cn(className)}
        >
          {children}
        </Link>
      );

    default:
      return null;
  }
};

export default UniversalLinkWrapper;
