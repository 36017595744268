import React from "react";
import { useSiteMetadata } from "~hooks";
import FacebookSEO from "./Facebook";
import TwitterSEO from "./Twitter";

interface IProps {
  pageTitle: string;
  pathname?: string;
}

const SEO = ({ pageTitle, pathname }: IProps) => {
  const meta = useSiteMetadata();
  const siteTitle = meta.title;
  const tagline = `Making Australia’s startup scene Awesome`;
  const titleText =
    pageTitle === `Home`
      ? `${siteTitle} | ${tagline}`
      : `${pageTitle} | ${siteTitle}`;

  const ogImageUrl = "/images/og-image.png";

  return (
    <>
      <title>{titleText}</title>
      <link rel="canonical" href={`${meta.siteUrl}${pathname}`} />
      <meta name="description" content={meta.description}></meta>
      <FacebookSEO
        description={meta.description}
        ogImageUrl={ogImageUrl}
        siteUrl={meta.siteUrl}
        title={titleText}
      />
      <TwitterSEO
        description={meta.description}
        ogImageUrl={ogImageUrl}
        title={titleText}
      />
    </>
  );
};

export default SEO;
