// extracted by mini-css-extract-plugin
export var absoluteContent = "styles-module--absoluteContent--0f562";
export var captionContainer = "styles-module--captionContainer--8e1cc";
export var container = "styles-module--container--48249";
export var desktop = "1340px";
export var giant = "2200px";
export var hidden = "styles-module--hidden--4a82f";
export var icon = "styles-module--icon--1699a";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var playPauseButton = "styles-module--playPauseButton--b825e";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var video = "styles-module--video--379c0";
export var visible = "styles-module--visible--ac0bf";