import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.scss";
import { useTrapFocus } from "~hooks";
import { graphql, useStaticQuery } from "gatsby";
import { ISettings } from "~schemas";
import { PortableTextRenderer, SVG } from "~components";
import { localStorage } from "~utils";
import cn from "classnames";

interface IQueryResults {
  sanitySettings: ISettings;
}

const Splashscreen = () => {
  const LAST_SESSION_KEY = `euphemia_last_session_abc`;
  const SESSION_EXPIRY_THRESHOLD_MINUTES = 30;
  const NUMBER_OF_FRACTURE_PIECES = 12;

  const updateLastSessionLocalStorage = () => {
    const currentTimeMilliseconds = new Date().getTime();
    localStorage.setItem(LAST_SESSION_KEY, currentTimeMilliseconds);
  };

  const shouldShowSplashscreenOnLoad = () => {
    const currentTimeMilliseconds = new Date().getTime();
    const timeLastSessionStartedMilliseconds =
      localStorage.getItem(LAST_SESSION_KEY);

    const differenceMilliseconds =
      currentTimeMilliseconds - timeLastSessionStartedMilliseconds;
    const millisecondsInMinute = 1000 * 60;
    const minutesDifference = differenceMilliseconds / millisecondsInMinute;

    if (minutesDifference > SESSION_EXPIRY_THRESHOLD_MINUTES) {
      return true;
    }
    return false;
  };

  const [isSplashScreenVisible, setIsSpashscreenVisible] = useState(false);

  useEffect(() => {
    if (shouldShowSplashscreenOnLoad()) {
      setIsSpashscreenVisible(true);
    }
  }, []);

  const {
    sanitySettings: { _rawSplashscreenText }
  }: IQueryResults = useStaticQuery(query);

  const contentRef = useRef<HTMLDivElement>(null);
  useTrapFocus(isSplashScreenVisible, contentRef);

  // Disable page scroll
  useEffect(() => {
    if (isSplashScreenVisible) {
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.classList.remove(styles.disableScroll);
    }
  }, [isSplashScreenVisible]);

  const fractureArray = [...Array(NUMBER_OF_FRACTURE_PIECES)];

  const handleClick = () => {
    setIsSpashscreenVisible(false);
    updateLastSessionLocalStorage();
  };

  return (
    <div
      aria-hidden={!isSplashScreenVisible}
      className={cn(styles.container, {
        [styles.hidden]: !isSplashScreenVisible
      })}
      onClick={handleClick}
    >
      <div className={styles.relative}>
        <div className={styles.fractureContainer}>
          <div className={styles.fractureRelative}>
            {fractureArray.map((_e, i) => (
              <div key={i} className={styles.fracturePiece} />
            ))}
          </div>
        </div>

        <div
          className={styles.content}
          ref={contentRef}
          aria-hidden={!isSplashScreenVisible}
        >
          <PortableTextRenderer
            rawText={_rawSplashscreenText}
            className={styles.textBlock}
            textElement="p"
          />

          <button
            type="button"
            disabled={!isSplashScreenVisible}
            className={cn(`button-text`, styles.button)}
            onClick={handleClick}
          >
            Click anywhere to continue
            <SVG svg="arrowRight" className={styles.arrowIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Splashscreen;

const query = graphql`
  query SplashscreenQuery {
    sanitySettings {
      _rawSplashscreenText
    }
  }
`;
