import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { SVG } from "~components";

interface IProps {}

const Graphic2 = (props: IProps) => {
  return (
    <>
      <div className={cn(styles.shape, styles.square)} />
      <SVG
        svg="triangle"
        className={cn(styles.shape, styles.triangle, styles.triangleTop)}
      />
      <SVG
        svg="triangle"
        className={cn(styles.shape, styles.triangle, styles.triangleBottom)}
      />
    </>
  );
};

export default Graphic2;
