import { useEffect } from "react";

const useDisableFocus = (
  focusDisabled: boolean,
  containerRef: React.MutableRefObject<HTMLDivElement | HTMLUListElement | null>
) => {
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const focusableElementTypes = `button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`;
    const focusableContentNodesList = container.querySelectorAll(
      focusableElementTypes
    ) as NodeListOf<any>;
    const focusableElements = Array.from(focusableContentNodesList).filter(
      // <a> tags aren't 'disabled' but will have the attribute
      (element) => !element.disabled && !element.hasAttribute(`disabled`)
    );

    if (focusDisabled) {
      focusableElements.forEach((element) => {
        element.setAttribute(`tabindex`, `-1`);
      });
    } else {
      focusableElements.forEach((element) => {
        element.removeAttribute(`tabindex`);
      });
    }
  }, [focusDisabled, containerRef]);
};

export default useDisableFocus;
