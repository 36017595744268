import React from "react";
import { useDarkMode } from "~hooks";
import { Helmet } from "react-helmet";

const Favicon = () => {
  const isDarkMode = useDarkMode();
  const favicon = isDarkMode
    ? `/images/favicon-darkmode.png`
    : `/images/favicon-default.png`;

  return (
    // Need to use Helmet to allow for dynamic favicons
    <Helmet>
      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
  );
};

export default Favicon;
