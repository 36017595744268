import React, { useRef, useState } from "react";
import * as styles from "./styles.module.scss";
import { IPortfolioItem } from "~schemas";
import { Button, IntersectionAnimation, SlicePadding } from "~components";
import PortfolioItem from "../PortfolioItem";
import HeaderAndCheckboxFilter from "./components/HeaderAndCheckboxFilter";

interface IProps {
  _rawTextGrid: any;
  portfoliloItems: IPortfolioItem[];
  inView: boolean;
}

export interface ITalliedItem {
  name: string;
  tally: number;
}

type TAction = `loadMore` | `filter`;

const GridGallery = ({ portfoliloItems, inView, _rawTextGrid }: IProps) => {
  const talliedCountries: ITalliedItem[] = [];
  portfoliloItems.forEach(({ country }) => {
    const existingCountry = talliedCountries.find(
      ({ name }) => name === country
    );
    if (existingCountry) {
      existingCountry.tally++;
    } else {
      talliedCountries.push({ name: country, tally: 1 });
    }
  });

  const talliedTypes: ITalliedItem[] = [];
  portfoliloItems.forEach(({ type }) => {
    const existingCountry = talliedTypes.find(({ name }) => name === type);
    if (existingCountry) {
      existingCountry.tally++;
    } else {
      talliedTypes.push({ name: type, tally: 1 });
    }
  });

  const talliedFilterItems: ITalliedItem[] = [
    ...talliedCountries,
    ...talliedTypes
  ];

  const [activeCategories, setActiveCategories] = useState<string[]>([]);

  const isFilteringEnabled = activeCategories?.[0];

  const getFilteredItems = () => {
    // Show items that are in ANY of the active categories
    if (!isFilteringEnabled) return portfoliloItems;

    const isItemActive = (item: IPortfolioItem) => {
      if (activeCategories.includes(item.country)) return true;
      if (activeCategories.includes(item.type)) return true;
      if (activeCategories.includes(`exit`) && item.exit) return true;

      return false;
    };

    return portfoliloItems.filter(isItemActive);
  };

  const filteredItems = getFilteredItems();

  const ITEM_INCREMENT_NUMBER = 8;

  const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(
    ITEM_INCREMENT_NUMBER
  );

  const itemsToShow = filteredItems.slice(0, numberOfItemsToShow);

  const allItemsOfCurrentFilterAreShown =
    numberOfItemsToShow >= filteredItems.length;

  const handleLoadMore = () => {
    lastActionRef.current = `loadMore`;
    setNumberOfItemsToShow((prev) => prev + ITEM_INCREMENT_NUMBER);
  };

  const lastActionRef = useRef<TAction>();

  const getTileAnimationDelay: (index: number) => number = (index) => {
    const DELAY_INCREMENT = 150;

    if (!lastActionRef.current || lastActionRef.current === `filter`) {
      return index * DELAY_INCREMENT;
    }

    const numberOfItemsAlreadyVisible =
      numberOfItemsToShow - ITEM_INCREMENT_NUMBER;

    return (index - numberOfItemsAlreadyVisible) * DELAY_INCREMENT;
  };

  const onClickCategory = (category?: string) => {
    lastActionRef.current = `filter`;

    if (!category) {
      setActiveCategories([]);
      return;
    }

    setActiveCategories((prev) => {
      if (prev.includes(category)) {
        return prev.filter((activeCategory) => activeCategory !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  return (
    <>
      <HeaderAndCheckboxFilter
        _rawHeaderText={_rawTextGrid}
        filterOptions={talliedFilterItems}
        activeCategories={activeCategories}
        onClickCategory={onClickCategory}
        allPortfolioItems={portfoliloItems}
        numberOfFilteredItems={filteredItems.length}
      />

      <SlicePadding config={{ paddingTop: "none" }}>
        {/* Update key to retrigger animation */}
        <div className={styles.grid} key={`key-${activeCategories.join(``)} `}>
          {itemsToShow.map((item, i) => (
            <IntersectionAnimation
              delay={getTileAnimationDelay(i)}
              trigger={inView}
              key={item._key}
            >
              <PortfolioItem item={item} index={i} />
            </IntersectionAnimation>
          ))}
        </div>

        {!allItemsOfCurrentFilterAreShown && (
          <div className={styles.loadMoreButtonContainer}>
            <Button iconRight="plus" onClick={handleLoadMore}>
              Load More
            </Button>
          </div>
        )}
      </SlicePadding>
    </>
  );
};

export default GridGallery;
