// extracted by mini-css-extract-plugin
export var active1 = "styles-module--active1--3774a";
export var active2 = "styles-module--active2--e3ea7";
export var active3 = "styles-module--active3--92fe9";
export var active4 = "styles-module--active4--beaee";
export var background = "styles-module--background--c06a8";
export var container = "styles-module--container--e2ee8";
export var description = "styles-module--description--7ad8e";
export var entity = "styles-module--entity--5d9ac";
export var entity1 = "styles-module--entity1--3809d";
export var entity2 = "styles-module--entity2--af4b2";
export var entity3 = "styles-module--entity3--af77b";
export var entity4 = "styles-module--entity4--f725b";
export var entityContent = "styles-module--entityContent--70d96";
export var statistic = "styles-module--statistic--b0b9b";
export var textContent = "styles-module--textContent--56d6f";
export var title = "styles-module--title--db317";