import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { NetworkLetterGraphic, TNetworkLetterGraphic } from "~components";
import { TActiveBlockIndex } from "../..";

export interface INetworkBlock {
  textColor: string;
  title: string;
  letterGraphic: TNetworkLetterGraphic;
}

export interface IProps extends INetworkBlock {
  index: TActiveBlockIndex;
  setActiveBlockIndex: React.Dispatch<React.SetStateAction<TActiveBlockIndex>>;
  activeBlockIndex: TActiveBlockIndex;
  hasPassedBreakpoint?: boolean;
}

const NetworkBlock = ({
  textColor,
  title,
  index,
  letterGraphic,
  activeBlockIndex,
  setActiveBlockIndex,
  hasPassedBreakpoint
}: IProps) => {
  const handleMouseMove = () => {
    if (hasPassedBreakpoint && setActiveBlockIndex) {
      setActiveBlockIndex(index);
    }
  };

  return (
    <div
      className={styles.container}
      onMouseMove={handleMouseMove}
      style={{
        color: textColor || `var(--color-white)`
      }}
    >
      <NetworkLetterGraphic
        className={styles.letterGraphic}
        graphic={letterGraphic}
        active={activeBlockIndex === index}
      />

      <h4 className={cn(`h2`, styles.title)}>{title}</h4>
    </div>
  );
};

export default NetworkBlock;
