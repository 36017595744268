// extracted by mini-css-extract-plugin
export var animationDuration = "5000ms";
export var appear = "styles-module--appear--e2658";
export var appearDown = "styles-module--appear-down--689fb";
export var appearLeft = "styles-module--appear-left--cbafc";
export var appearRight = "styles-module--appear-right--e735e";
export var appearUp = "styles-module--appear-up--529ff";
export var canvas = "styles-module--canvas--ef00f";
export var container = "styles-module--container--dc28c";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var opacityAndPointerEvents = "styles-module--opacity-and-pointer-events--defc8";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var updatePointerEvents = "styles-module--update-pointer-events--1845d";