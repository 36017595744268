import React from "react";
import { PortableText } from "@portabletext/react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

type TTextElement = `h1` | `h2` | `h3` | `h4` | `h5` | `h6` | `p`;

interface IProps {
  rawText: any;
  className?: string;
  textElement?: TTextElement;
}

const customComponentsBase = {
  listItem: {
    number: ({ children }: any) => {
      const nodeStyle = children.props.node.style;
      return (
        <li className={cn(styles.orderedListItem, nodeStyle)}>{children}</li>
      );
    },
    bullet: ({ children }: any) => {
      const nodeStyle = children.props.node.style;
      return (
        <li className={cn(styles.unorderedListItem, nodeStyle)}>{children}</li>
      );
    }
  },
  marks: {
    link: ({ children, value }: any) => {
      const rel = value.href.startsWith("/")
        ? undefined
        : "noreferrer noopener";
      return (
        <a
          className={styles.anchor}
          style={{ textDecoration: `underline` }}
          href={value.href}
          rel={rel}
        >
          {children}
        </a>
      );
    }
  }
};

const cmsElementTextComponents = {
  ...customComponentsBase,

  block: {
    normal: ({ children }: any) => <p>{children}</p>,
    d2: ({ children }: any) => <h2 className="d2">{children}</h2>,
    h1: ({ children }: any) => <h2 className="h1">{children}</h2>,
    h2: ({ children }: any) => <h3 className="h2">{children}</h3>,
    h3: ({ children }: any) => <h4 className="h3">{children}</h4>,
    h4: ({ children }: any) => <h5 className="h4">{children}</h5>,
    b1: ({ children }: any) => <p className="b1">{children}</p>,
    b2: ({ children }: any) => <p className="b2">{children}</p>,
    caption: ({ children }: any) => <p className="caption">{children}</p>,
    button: ({ children }: any) => <p className="button">{children}</p>,
    underline: ({ children }: any) => <p className="underline">{children}</p>
  }
};

const specifiedElementTextComponents = (textElement: TTextElement) => {
  const Element: any = textElement;

  return {
    ...customComponentsBase,

    block: {
      normal: ({ children }: any) => <Element>{children}</Element>,
      d2: ({ children }: any) => <Element className="d2">{children}</Element>,
      h1: ({ children }: any) => <Element className="h1">{children}</Element>,
      h2: ({ children }: any) => <Element className="h2">{children}</Element>,
      h3: ({ children }: any) => <Element className="h3">{children}</Element>,
      h4: ({ children }: any) => <Element className="h4">{children}</Element>,
      b1: ({ children }: any) => <Element className="b1">{children}</Element>,
      b2: ({ children }: any) => <Element className="b2">{children}</Element>,
      caption: ({ children }: any) => (
        <Element className="caption">{children}</Element>
      ),
      button: ({ children }: any) => (
        <Element className="button">{children}</Element>
      ),
      underline: ({ children }: any) => (
        <Element className="underline">{children}</Element>
      )
    }
  };
};

const PortableTextRenderer = ({ rawText, className, textElement }: IProps) => {
  const getComponents = () => {
    if (!textElement) return cmsElementTextComponents;

    return specifiedElementTextComponents(textElement);
  };

  return (
    <div className={cn(styles.container, className)}>
      <PortableText value={rawText} components={getComponents()} />
    </div>
  );
};

export default PortableTextRenderer;
