// extracted by mini-css-extract-plugin
export var container = "styles-module--container--74532";
export var desktop = "styles-module--desktop--58ac9";
export var filled1 = "styles-module--filled1--b0904";
export var filled2 = "styles-module--filled2--27ccb";
export var filled3 = "styles-module--filled3--c27da";
export var giant = "2200px";
export var graphicGrid = "styles-module--graphicGrid--2d6c8";
export var header = "styles-module--header--79e44";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mainContent = "styles-module--mainContent--c9eea";
export var mainContentContainer = "styles-module--mainContentContainer--61d29";
export var mobile = "400px";
export var scrollingText = "styles-module--scrollingText--83f3f";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--31a66";
export var wordScroller = "styles-module--wordScroller--7f672";