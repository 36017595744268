// extracted by mini-css-extract-plugin
export var bottomMargin = "styles-module--bottomMargin--f3363";
export var buttons = "styles-module--buttons--d4d8c";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--2e2d2";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftColumn = "styles-module--leftColumn--3bcbe";
export var linkTags = "styles-module--linkTags--8d979";
export var mobile = "400px";
export var rightColumn = "styles-module--rightColumn--36563";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";