// extracted by mini-css-extract-plugin
export var container = "styles-module--container--2fef9";
export var desktop = "1340px";
export var disabled = "styles-module--disabled--36ffe";
export var error = "styles-module--error--a5541";
export var fileUpload = "styles-module--fileUpload--2f77c";
export var giant = "2200px";
export var hint = "styles-module--hint--aebb9";
export var icon = "styles-module--icon--b34c6";
export var label = "styles-module--label--fc8a8";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var topText = "styles-module--topText--d0634";
export var warning = "styles-module--warning--86fdd";