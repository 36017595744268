import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import * as styles from "./styles.module.scss";

const FooterSpinner = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  const calculateContainerSize = () => {
    const container = containerRef.current;
    if (!container) return;

    const { width, height } = container.getBoundingClientRect();
    setContainerSize({ width, height });
  };

  // Container size
  useEffect(() => {
    window.addEventListener(`resize`, calculateContainerSize);
    setTimeout(calculateContainerSize, 100); // Don't know why but height is wrong without timeout

    return () => {
      window.removeEventListener(`resize`, calculateContainerSize);
    };
  }, []);

  // Canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container) return;

    // Boilerplate
    const scene = new THREE.Scene();

    const camera = new THREE.PerspectiveCamera(
      75,
      containerSize.width / containerSize.height,
      0.1,
      100
    );
    camera.position.y = 1;
    camera.rotation.x = -1.56;

    scene.add(camera);

    const renderer = new THREE.WebGLRenderer({
      canvas: canvas
    });
    renderer.setSize(containerSize.width, containerSize.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Create spinner
    const colors = [
      `#FF8731`,
      `#C6B792`,
      `#C3423D`,
      `#50DBEE`,
      `#FFABAB`,
      `#007171`,
      `#00394F`,
      `#5FFF95`,
      `#77007C`,
      `#FFDE31`
    ];

    const cylinderGroup = new THREE.Group();

    const SEGMENTS = 20;
    const RADIUS = 5;

    for (let i = 0; i < SEGMENTS; i++) {
      var geometry = new THREE.CylinderGeometry(
        RADIUS,
        RADIUS,
        0.1,
        1,
        1,
        false,
        i * ((2 * Math.PI) / SEGMENTS),
        (2 * Math.PI) / SEGMENTS
      );
      const material = new THREE.MeshBasicMaterial({
        color: colors[i % colors.length]
      });
      const cylinder = new THREE.Mesh(geometry, material);
      cylinderGroup.add(cylinder);
      cylinder.position.y = 0.5;
    }

    scene.add(cylinderGroup);

    // Animation
    const clock = new THREE.Clock();

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();
      cylinderGroup.rotation.y = -elapsedTime;

      renderer.render(scene, camera);

      window.requestAnimationFrame(tick);
    };

    tick();

    // Resize
    const calculateCanvasSizes = () => {
      camera.aspect = containerSize.width / containerSize.height;
      camera.updateProjectionMatrix();

      renderer.setSize(containerSize.width, containerSize.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    calculateCanvasSizes();
    window.addEventListener(`resize`, calculateCanvasSizes);

    return () => window.removeEventListener(`resize`, calculateCanvasSizes);
  }, [containerSize.height, containerSize.width]);

  return (
    <div className={styles.container} ref={containerRef}>
      <canvas className={styles.canvas} ref={canvasRef} />;
    </div>
  );
};

export default FooterSpinner;
