import React, { useEffect } from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { ICategory, IFilter, IFilters } from "../..";
import FilterItem from "../FilterItem";

interface IProps {
  category: ICategory;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
  className?: string;
  calculateCategoriesContentHeight: () => void;
}

const CategoryFilterItem = ({
  category: { active, title, subcategories },
  className,
  setFilters,
  calculateCategoriesContentHeight
}: IProps) => {
  const getAllSubcategoriesUnchecked: (
    subcategories?: IFilter[]
  ) => IFilter[] = (subcategories) => {
    if (!subcategories) return [];

    return subcategories?.map((subcategory) => ({
      ...subcategory,
      active: false
    }));
  };

  const updateCategory = (
    category: ICategory,
    isActive: boolean
  ): ICategory => ({
    ...category,
    active: isActive,
    subcategories: isActive
      ? getAllSubcategoriesUnchecked(category.subcategories)
      : category.subcategories
  });

  const updateCategories = (
    filters: IFilters,
    isChecked: boolean
  ): IFilters => ({
    ...filters,
    categories: filters.categories.map((category) =>
      category.title === title ? updateCategory(category, isChecked) : category
    )
  });

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    setFilters((prevFilters) => updateCategories(prevFilters, isChecked));
  };

  const handleSubcategoryChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    subcategoryTitle: string
  ) => {
    const isChecked = e.target.checked;

    setFilters((prev) => ({
      ...prev,
      categories: prev.categories.map((currentCategory) => {
        if (currentCategory.title === title) {
          return {
            ...currentCategory,
            subcategories: currentCategory.subcategories?.map(
              (currentSubcategory) => {
                if (currentSubcategory.title === subcategoryTitle) {
                  return {
                    ...currentSubcategory,
                    active: isChecked
                  };
                }

                return currentSubcategory;
              }
            )
          };
        }

        return currentCategory;
      })
    }));
  };

  useEffect(() => {
    calculateCategoriesContentHeight();
  }, [active]);

  return (
    <div className={cn(className)}>
      <FilterItem
        isActive={active}
        text={title}
        onChange={handleCategoryChange}
      />

      {subcategories?.[0] && active && (
        <div className={styles.accordion}>
          {subcategories.map((subcategory) => (
            <FilterItem
              key={subcategory.title}
              isActive={subcategory.active}
              text={subcategory.title}
              onChange={(e) => handleSubcategoryChange(e, subcategory.title)}
              className={styles.subcategoryFilterItem}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryFilterItem;
