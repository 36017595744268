// extracted by mini-css-extract-plugin
export var container = "styles-module--container--cc591";
export var desktop = "1340px";
export var founder = "styles-module--founder--26b18";
export var founderListItem = "styles-module--founderListItem--096cf";
export var founderName = "styles-module--founderName--06f60";
export var founders = "styles-module--founders--c4916";
export var giant = "2200px";
export var grid = "styles-module--grid--a6382";
export var info = "styles-module--info--2ddc0";
export var label = "styles-module--label--6602c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";