import React, { CSSProperties } from "react";
import * as styles from "./styles.module.scss";

interface IProps {
  color: string;
}

const HeroBackgroundGraphic = ({ color }: IProps) => {
  const colorStyle: CSSProperties = {
    background: color
  };

  return (
    <div className={styles.container}>
      {/* Mobile */}
      <div className={styles.mobile} style={colorStyle} />
      <div className={styles.mobile} />

      <div className={styles.mobile} />
      <div className={styles.mobile} style={colorStyle} />

      <div className={styles.mobile} style={colorStyle} />
      <div className={styles.mobile} />

      <div className={styles.mobile} />
      <div className={styles.mobile} style={colorStyle} />

      {/* Desktop */}
      <div className={styles.desktop} style={colorStyle} />
      <div className={styles.desktop} />
      <div className={styles.desktop} style={colorStyle} />
      <div className={styles.desktop} />

      <div className={styles.desktop} />
      <div className={styles.desktop} style={colorStyle} />
      <div className={styles.desktop} />
      <div className={styles.desktop} style={colorStyle} />
    </div>
  );
};

export default HeroBackgroundGraphic;
