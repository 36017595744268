import React, { useState } from "react";
import {
  IntersectionAnimation,
  Button,
  SliceIntroText,
  SlicePadding
} from "~components";
import type { ICaseStudy, IGlobalLink, ILinkTiles } from "~schemas";
import { useInView } from "react-intersection-observer";
import * as styles from "./styles.module.scss";
import LinkTile from "./components/LinkTile";
import { doesRawTextHaveContent } from "~utils";
import { graphql, useStaticQuery } from "gatsby";
import HeaderAndFilter from "./components/HeaderAndFilter";
import { useBreakpoints } from "~hooks";

interface IProps {
  data: ILinkTiles;
}

export type TFilterCategory = null | ICategoryWithTally;

export interface ICategoryWithTally {
  name: string;
  tally: number;
}

interface ICaseStudyWithCategory extends ICaseStudy {
  category: string;
}

const LinkTiles = ({
  data: {
    variation,
    introText,
    _rawHeaderText,
    linkTiles: sanityLinkTiles,
    allCaseStudies: includeAllCaseStudies,
    allGlobalLinks: includeAllExternalLinks,
    showAllTilesByDefault
  }
}: IProps) => {
  const allTilesQuery = useStaticQuery(query);
  const allExternalLinks: IGlobalLink[] =
    allTilesQuery?.allSanityGlobalLink?.nodes || [];
  const allCaseStudies: ICaseStudy[] = allTilesQuery?.allSanityCaseStudy?.nodes;

  const getLinkTiles: () => (IGlobalLink | ICaseStudyWithCategory)[] = () => {
    const linkTiles = [];
    if (includeAllCaseStudies) {
      linkTiles.push(...allCaseStudies);
    }
    if (includeAllExternalLinks) {
      linkTiles.push(...allExternalLinks);
    }
    if (!includeAllCaseStudies && !includeAllExternalLinks) {
      linkTiles.push(...sanityLinkTiles);
    }

    const linkTilesWithCaseStudyCategory: (
      | IGlobalLink
      | ICaseStudyWithCategory
    )[] = linkTiles.map((tile) => {
      if (tile._type === `caseStudy`) {
        return {
          ...tile,
          category: `Case Study`
        };
      }

      return tile as IGlobalLink;
    });

    return linkTilesWithCaseStudyCategory;
  };

  const { largeTablet } = useBreakpoints();
  const minimumTiles = largeTablet ? 3 : 4;

  const linkTiles = getLinkTiles();

  const initialShowAllLinks =
    variation === `filter` ||
    showAllTilesByDefault ||
    linkTiles.length <= minimumTiles;
  const [showAllTiles, setShowAllTiles] = useState(initialShowAllLinks);

  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  const hasRawTextContent = doesRawTextHaveContent(
    introText?._rawLeftColumn,
    introText?._rawRightColumn
  );

  const colorThemes = [
    {
      backgroundColor: `var(--color-dark-purple)`,
      textColor: `var(--color-white)`
    },
    {
      backgroundColor: `var(--color-forest-green)`,
      textColor: `var(--color-white)`
    },
    {
      backgroundColor: `var(--color-construction-orange)`,
      textColor: `var(--color-black)`
    },
    {
      backgroundColor: `var(--color-dark-blue)`,
      textColor: `var(--color-white)`
    },
    {
      backgroundColor: `var(--color-beige)`,
      textColor: `var(--color-black)`
    },
    {
      backgroundColor: `var(--color-gold)`,
      textColor: `var(--color-black)`
    }
  ];

  const getTileColors = (index: number) => {
    return colorThemes[index % colorThemes.length];
  };

  // Filtering
  const categories = linkTiles.map((tile) => tile.category);
  const categoriesWithTally: ICategoryWithTally[] = [];

  categories.forEach((category) => {
    const categoryExists = categoriesWithTally.find(
      (item) => item.name === category
    );

    if (categoryExists) {
      categoryExists.tally++;
    } else {
      categoriesWithTally.push({ name: category, tally: 1 });
    }
  });

  const [selectedCategory, setSelectedCategory] =
    useState<TFilterCategory>(null);

  const filteredLinks = linkTiles.filter((tile) => {
    if (!selectedCategory) return true;
    return tile.category === selectedCategory.name;
  });

  const visibleLinks = showAllTiles
    ? filteredLinks
    : filteredLinks.slice(0, minimumTiles);

  const transitionDelay = (index: number) => {
    if (variation === `text`) {
      if (initialShowAllLinks) {
        return 150 * index;
      }
      return showAllTiles ? 150 * (index - minimumTiles) : 150 * index;
    }

    // For `filter` variation, we need to account for when tiles are re-rendered on filter change
    if (variation === `filter`) {
      return 150 * index;
    }

    return 0; // Shouldn't ever reach this
  };

  return (
    <>
      {variation === `text` && introText && (
        <SliceIntroText content={introText} />
      )}

      {variation === `filter` && (
        <HeaderAndFilter
          _rawHeaderText={_rawHeaderText}
          categories={categoriesWithTally}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      )}

      <div ref={ref}>
        <SlicePadding
          config={{ paddingTop: hasRawTextContent ? `none` : `regular` }}
        >
          <div
            className={styles.linkTiles}
            key={selectedCategory?.name || `none`} // Retrigger tile animation on filter change
          >
            {visibleLinks.map((tile, i) => {
              const { backgroundColor, textColor } = getTileColors(i);

              return (
                <IntersectionAnimation
                  key={tile._id}
                  delay={transitionDelay(i)}
                  animation="fadeGrow"
                  trigger={inView}
                  className={styles.linkTile}
                >
                  <LinkTile
                    caseStudy={tile._type === "caseStudy" ? tile : undefined}
                    globalLink={tile._type === "globalLink" ? tile : undefined}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                  />
                </IntersectionAnimation>
              );
            })}
          </div>

          {variation !== `filter` && !showAllTiles && (
            <Button
              onClick={() => setShowAllTiles(true)}
              className={styles.loadMoreButton}
              iconRight="plus"
            >
              Load More
            </Button>
          )}
        </SlicePadding>
      </div>
    </>
  );
};

export default LinkTiles;

const query = graphql`
  query AllLinkTilesQuery {
    allSanityGlobalLink(sort: { orderRank: ASC }) {
      nodes {
        ...GlobalLinkFragment
      }
    }
    allSanityCaseStudy(sort: { orderRank: ASC }) {
      nodes {
        ...CaseStudyLinkFragment
      }
    }
  }
`;
