import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { Button, SVG } from "~components";
import Filters from "../Filters";
import { IFilters } from "../..";
import { useDisablePageScroll } from "~hooks";

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
}

const MobileFilterMenu = ({
  isOpen,
  setIsOpen,
  filters,
  setFilters
}: IProps) => {
  const closeMenu = () => {
    setIsOpen(false);
  };

  useDisablePageScroll(isOpen);

  return (
    <div className={cn(styles.container, { [styles.active]: isOpen })}>
      <div className={styles.relativeContainer}>
        <div
          aria-hidden
          onClick={closeMenu}
          className={styles.contrastBackdrop}
        />
        <div className={styles.cardContent}>
          <div className={styles.headerContainer}>
            <h2 className={cn("h3")}>Filters</h2>

            <button
              aria-label="Close filters menu"
              onClick={closeMenu}
              className={styles.xButton}
            >
              <SVG svg="x" />
            </button>
          </div>

          <div className={styles.flexContainer}>
            <div className={styles.filtersContainer}>
              <Filters
                className={styles.filters}
                filters={filters}
                setFilters={setFilters}
              />
            </div>

            <Button fluid onClick={closeMenu}>
              Apply Filters
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFilterMenu;
