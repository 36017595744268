import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";
import {
  IntersectionAnimation,
  PortableTextRenderer,
  SlicePadding,
  UniversalLink
} from "~components";
import { useInView } from "react-intersection-observer";
import { useBreakpoints, useInterval } from "~hooks";
import cn from "classnames";
import { INetworkPreview } from "~schemas";
import NetworkBlock, { INetworkBlock } from "./components/NetworkBlock";

interface IProps {
  data: INetworkPreview;
}

export type TActiveBlockIndex = 0 | 1 | 2 | 3 | null;

const Preview = ({
  data: { _rawText, header, universalLink: link }
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  const blocks: INetworkBlock[] = [
    {
      textColor: `var(--color-black)`,
      title: `Accountants`,
      letterGraphic: `1A`
    },
    {
      textColor: `var(--color-white)`,
      title: `Lawyers`,
      letterGraphic: `2L`
    },
    {
      textColor: `var(--color-white)`,
      title: `Advisors`,
      letterGraphic: `3A`
    },
    {
      textColor: `var(--color-black)`,
      title: `Peers`,
      letterGraphic: `4P`
    }
  ];

  const [activeBlockIndex, setActiveBlockIndex] =
    useState<TActiveBlockIndex>(null);

  const { largeTablet } = useBreakpoints();

  useEffect(() => {
    if (largeTablet) {
      setActiveBlockIndex(null);
    }
  }, [largeTablet]);

  // On mobile, animate through the blocks automatically
  const updateActiveBlock = () => {
    setActiveBlockIndex((prev) => {
      if (prev === null || prev === 3) {
        return 0;
      }

      return (prev + 1) as TActiveBlockIndex;
    });
  };

  useInterval(updateActiveBlock, largeTablet ? null : 1000);

  const handleMouseLeave = () => {
    if (largeTablet) {
      setActiveBlockIndex(null);
    }
  };

  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.blockContainer} onMouseLeave={handleMouseLeave}>
        {blocks.map((block, i) => (
          <IntersectionAnimation
            key={i}
            animation="fadeGrow"
            trigger={inView}
            delay={largeTablet ? 100 + i * 100 : 400 + i * 100}
          >
            <NetworkBlock
              index={i as TActiveBlockIndex}
              letterGraphic={block.letterGraphic}
              title={block.title}
              textColor={block.textColor}
              activeBlockIndex={activeBlockIndex}
              setActiveBlockIndex={setActiveBlockIndex}
              hasPassedBreakpoint={largeTablet}
            />
          </IntersectionAnimation>
        ))}
      </div>

      <SlicePadding className={styles.textContainer}>
        <IntersectionAnimation delay={largeTablet ? 600 : 100} trigger={inView}>
          <h2 className={cn(`h1`, styles.header)}>{header}</h2>
        </IntersectionAnimation>

        <IntersectionAnimation delay={largeTablet ? 750 : 250} trigger={inView}>
          <PortableTextRenderer
            textElement="p"
            className={cn(`b1`, styles.text)}
            rawText={_rawText}
          />

          {link?.link[0] && (
            <UniversalLink
              iconRight="arrowRight"
              text={link.text}
              link={link.link}
              className={styles.button}
            />
          )}
        </IntersectionAnimation>
      </SlicePadding>
    </div>
  );
};

export default Preview;
