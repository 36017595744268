// extracted by mini-css-extract-plugin
export var blockGraphic = "styles-module--blockGraphic--4c274";
export var bottomLeft = "styles-module--bottomLeft--101b8";
export var bottomMiddle = "styles-module--bottomMiddle--70a73";
export var bottomRight = "styles-module--bottomRight--88cc3";
export var container = "styles-module--container--4aa96";
export var desktop = "1340px";
export var giant = "2200px";
export var icon = "styles-module--icon--e8e3f";
export var iconContainer = "styles-module--iconContainer--3d7bb";
export var image = "styles-module--image--69dc0";
export var imageContainer = "styles-module--imageContainer--d3a10";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var linkTile = "styles-module--linkTile--2b36d";
export var middleLeft = "styles-module--middleLeft--f524c";
export var middleRight = "styles-module--middleRight--37a5a";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheading = "styles-module--subheading--3a7fd";
export var tablet = "769px";
export var text = "styles-module--text--7e710";
export var title = "styles-module--title--94a24";
export var topLeft = "styles-module--topLeft--7b791";