// extracted by mini-css-extract-plugin
export var button = "styles-module--button--3c5f5";
export var container = "styles-module--container--67838";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textAndButton = "styles-module--textAndButton--bb79e";
export var textAndButtonContent = "styles-module--textAndButtonContent--868e7";