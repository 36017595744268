import React, { useEffect, useRef } from "react";
import { ICaseStudyAtf } from "~schemas";
import { IntersectionAnimation, SlicePadding } from "~components";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { useInView } from "react-intersection-observer";

interface IProps extends ICaseStudyAtf {
  title: string;
}

const CaseStudyAtf = ({ description, video, tags, title }: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setTimeout(() => {
      videoRef.current?.play();
    }, 600);
  }, []);

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.videoContainer}>
        <video
          ref={videoRef}
          className={styles.video}
          playsInline
          muted
          src={video.asset.url}
        />
      </div>

      <SlicePadding className={styles.mainContent}>
        <div className={styles.titleAndTags}>
          <div>
            <IntersectionAnimation trigger={inView}>
              <h1 className={cn(`d2`, styles.title)}>{title}</h1>

              {tags?.[0] && (
                <div className={styles.tags}>
                  {tags.map((tag, i) => (
                    <IntersectionAnimation
                      trigger={inView}
                      key={i}
                      animation="fadeGrow"
                      delay={50 + (i + 1) * 150}
                    >
                      <div className={cn(`b2`, styles.tag)}>{tag}</div>
                    </IntersectionAnimation>
                  ))}
                </div>
              )}
            </IntersectionAnimation>
          </div>
        </div>

        <IntersectionAnimation delay={300} trigger={inView}>
          <p className={cn(`h4`, styles.description)}>{description}</p>
        </IntersectionAnimation>
      </SlicePadding>
    </div>
  );
};

export default CaseStudyAtf;
