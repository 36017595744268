import React, { CSSProperties } from "react";
import type { IStatistics } from "~schemas";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { CountUp, SlicePadding } from "~components";
import { IColorTheme } from "~types";
import { getThemeColors } from "~utils";
import BreadkdownItem from "./components/BreakdownItem";

interface IProps {
  data: IStatistics;
  theme: IColorTheme;
}

const Statistics = ({
  data: {
    disableProportions,
    header,
    colorTheme,
    heroStatistic,
    statistics,
    subheader
  }
}: IProps) => {
  const { backgroundColor, highlightColor, textColor } =
    getThemeColors(colorTheme);

  const themeColorsStyle = {
    "--statistics-background-color": backgroundColor,
    "--statistics-text-color": textColor,
    "--statistics-highlight-color": highlightColor
  } as CSSProperties;

  return (
    <div className={styles.container}>
      <SlicePadding className={styles.textContainer}>
        <h2 className={cn("h2", styles.header)}>{header}</h2>
        {subheader && <p className={cn("b1")}>{subheader}</p>}
      </SlicePadding>

      <div style={themeColorsStyle} className={styles.statsContainer}>
        {heroStatistic && (
          <div className={cn(styles.heroStatContainer)}>
            <CountUp
              className={cn("d1", styles.heroStat)}
              number={parseInt(heroStatistic)}
            />

            <div className={styles.graphicContainer}>
              <div className={cn(styles.graphicSegment, styles.segment1)} />
              <div className={cn(styles.graphicSegment, styles.segment2)} />
              <div className={cn(styles.graphicSegment, styles.segment3)} />
              <div className={cn(styles.graphicSegment, styles.segment4)} />
            </div>
          </div>
        )}

        {statistics && (
          <div
            className={cn(styles.statItems, {
              [styles.fullHeight]: !heroStatistic
            })}
          >
            {statistics.map((stat, i) => (
              <BreadkdownItem
                disableProportions={disableProportions}
                index={i}
                hasHeroStatistic={heroStatistic ? true : false}
                stat={stat}
                key={i}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Statistics;
