// extracted by mini-css-extract-plugin
export var arrowRightIcon = "styles-module--arrowRightIcon--bd8d9";
export var desktop = "1340px";
export var desktopLinkLabel = "styles-module--desktopLinkLabel--0bae1";
export var giant = "2200px";
export var hoverBlock = "styles-module--hoverBlock--18d6b";
export var hoverBlock1 = "styles-module--hoverBlock1--25f3e";
export var hoverBlock2 = "styles-module--hoverBlock2--d7545";
export var hoverBlock3 = "styles-module--hoverBlock3--1e360";
export var hoverBlock4 = "styles-module--hoverBlock4--2dd5b";
export var hoverBlock5 = "styles-module--hoverBlock5--3844b";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logoLink = "styles-module--logoLink--38c92";
export var logoLinkImage = "styles-module--logoLinkImage--bbcf7";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";