import { useState, useEffect } from "react";
import * as styles from "~styles/disableScroll.module.scss";

const useDisablePageScroll = (disableScroll: boolean) => {
  const [
    lastScrollYBeforeDisablingScroll,
    setLastScrollYBeforeDisablingScroll
  ] = useState(0);

  useEffect(() => {
    if (disableScroll) {
      const scrollY = document.documentElement.scrollTop;
      setLastScrollYBeforeDisablingScroll(scrollY);
      document.body.style.top = `-${scrollY}px`;
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.style.top = `initial`;
      document.body.classList.remove(styles.disableScroll);
      document.documentElement.scrollTop = lastScrollYBeforeDisablingScroll;
      document.body.scrollTop = lastScrollYBeforeDisablingScroll;
    }
  }, [disableScroll]);
};

export default useDisablePageScroll;
