import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.scss";
import { IFounderLink } from "~schemas";
import { SVG, SlicePadding } from "~components";
import cn from "classnames";
import FounderLink from "../FounderLink";

export interface IProps {
  nameShort: string;
  links: IFounderLink[];
}

const LinksAccordion = ({ links, nameShort }: IProps) => {
  if (!links?.[0]) return null;

  const [contentHeight, setContentHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLUListElement>(null);

  const calculateContentHeight = () => {
    const height = contentRef.current?.offsetHeight || 0;
    setContentHeight(height);
  };

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  const handleClick = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <button
        onClick={handleClick}
        type="button"
        className={cn(`button-text`, styles.button)}
      >
        <SlicePadding className={styles.buttonPadding} xOnly>
          <span>Learn more about {nameShort}</span>
          <SVG
            svg="chevronDown"
            className={cn(styles.chevronIcon, { [styles.rotate]: isExpanded })}
          />
        </SlicePadding>
      </button>
      <div
        className={styles.contentContainer}
        aria-hidden={!isExpanded}
        style={{ height: isExpanded ? `${contentHeight}px` : 0 }}
      >
        <ul className={styles.content} ref={contentRef}>
          {links.map((link, i) => (
            <li key={i}>
              <FounderLink {...link} isVisible={isExpanded} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LinksAccordion;
