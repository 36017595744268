// extracted by mini-css-extract-plugin
export var container = "styles-module--container--101d6";
export var desktop = "1340px";
export var giant = "2200px";
export var graphicContainer = "styles-module--graphicContainer--3690a";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var overflowContainer = "styles-module--overflowContainer--b9aad";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--1572d";