import React from "react";
import { IntersectionAnimation, SlicePadding } from "~components";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./styles.module.scss";
import type { ISimpleImage } from "~schemas";
import { useInView } from "react-intersection-observer";

interface IProps {
  data: ISimpleImage;
}

const SimpleImage = ({ data: { image, caption } }: IProps) => {
  if (!image) {
    return null;
  }

  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <div className={styles.container} ref={ref}>
      <GatsbyImage
        style={{ width: `100%` }}
        alt=""
        image={image.asset.gatsbyImageData}
        objectFit="contain"
      />

      {caption && (
        <SlicePadding xOnly className={styles.captionContainer}>
          <IntersectionAnimation trigger={inView} delay={200} animation="fade">
            <p className="b2">{caption}</p>
          </IntersectionAnimation>
        </SlicePadding>
      )}
    </div>
  );
};

export default SimpleImage;
