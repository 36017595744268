import React from "react";
import Graphic1 from "./Graphic1";
import Graphic2 from "./Graphic2";
import Graphic3 from "./Graphic3";
import Graphic4 from "./Graphic4";
import Graphic5 from "./Graphic5";
import Graphic6 from "./Graphic6";
import Graphic7 from "./Graphic7";
import Graphic8 from "./Graphic8";
import Graphic9 from "./Graphic9";
import Graphic10 from "./Graphic10";
import { TMagicGraphicVariation } from "~schemas";

interface IProps {
  graphicVariation: TMagicGraphicVariation;
}

const MagicGraphic = ({ graphicVariation }: IProps) => {
  switch (graphicVariation) {
    case `1`:
      return <Graphic1 />;
    case `2`:
      return <Graphic2 />;
    case `3`:
      return <Graphic3 />;
    case `4`:
      return <Graphic4 />;
    case `5`:
      return <Graphic5 />;
    case `6`:
      return <Graphic6 />;
    case `7`:
      return <Graphic7 />;
    case `8`:
      return <Graphic8 />;
    case `9`:
      return <Graphic9 />;
    case `10`:
      return <Graphic10 />;
    default:
      return <div>No matching graphic, check configuration</div>;
  }
};

export default MagicGraphic;
