// extracted by mini-css-extract-plugin
export var active = "styles-module--active--f419f";
export var container = "styles-module--container--91a68";
export var content = "styles-module--content--3b770";
export var desktop = "1340px";
export var giant = "2200px";
export var graphic = "styles-module--graphic--3d073";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var plusIcon = "styles-module--plusIcon--c0618";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";