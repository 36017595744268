import React from "react";
import {
  Button,
  PortableTextRenderer,
  SlicePadding,
  Grid,
  IntersectionAnimation
} from "~components";
import * as styles from "./styles.module.scss";
import type { IHomepageAtf } from "~schemas";
import { useBreakpoints } from "~hooks";
import HomepageAtfDesktop from "./components/Desktop";
import HomepageAtfMobile from "./components/Mobile";

interface IProps {
  data: IHomepageAtf;
}

const HomepageAtf = ({
  data: {
    _rawText,
    link: linkArray,
    quadrant1,
    quadrant2,
    quadrant3,
    quadrant4
  }
}: IProps) => {
  const link = linkArray[0];

  const { largeTablet } = useBreakpoints();

  return (
    <Grid className={styles.container}>
      {!largeTablet && (
        <HomepageAtfMobile
          quadrant1={quadrant1}
          quadrant2={quadrant2}
          quadrant3={quadrant3}
          quadrant4={quadrant4}
        />
      )}

      <SlicePadding
        config={{
          paddingBottom: `small`,
          paddingTop: `small`
        }}
        className={styles.textAndButton}
      >
        <div className={styles.textAndButtonContent}>
          <IntersectionAnimation trigger={true}>
            <PortableTextRenderer textElement="h1" rawText={_rawText} />
          </IntersectionAnimation>

          <IntersectionAnimation delay={200} trigger={true}>
            {link._type === `linkExternal` && (
              <Button className={styles.button} href={link.url}>
                {link.title}
              </Button>
            )}
            {link._type === `linkInternal` && (
              <Button
                className={styles.button}
                to={link.reference.slug.current}
              >
                {link.title}
              </Button>
            )}
          </IntersectionAnimation>
        </div>
      </SlicePadding>

      {largeTablet && (
        <HomepageAtfDesktop
          quadrant1={quadrant1}
          quadrant2={quadrant2}
          quadrant3={quadrant3}
          quadrant4={quadrant4}
        />
      )}
    </Grid>
  );
};

export default HomepageAtf;
