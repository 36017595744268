import React, { Children, cloneElement, useRef } from "react";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import "swiper/css/bundle";
import PaginationTimer from "./components/PaginationTimer";

interface IProps {
  children: React.ReactNode;
  className?: string;
  withTimer?: boolean;
  setActiveIndex?: React.Dispatch<React.SetStateAction<number>>;
}

const SwiperCarousel = ({
  children,
  className,
  withTimer,
  setActiveIndex
}: IProps) => {
  const childrenArray = Children.toArray(children);
  const swiperRef = useRef<SwiperType | null>(null);

  const handleIndexChange = (e: SwiperType) => {
    setActiveIndex && setActiveIndex(e.activeIndex);
  };

  return (
    <>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onRealIndexChange={handleIndexChange}
        className={cn(className)}
        speed={500}
      >
        {Children.map(childrenArray, (child: any, i) => (
          <SwiperSlide key={i}>{cloneElement(child)}</SwiperSlide>
        ))}
      </Swiper>

      {withTimer && (
        <PaginationTimer
          swiperRef={swiperRef}
          numberOfSlides={Children.count(children)}
        />
      )}
    </>
  );
};

export default SwiperCarousel;
