import React, { useEffect, useRef } from "react";
import * as styles from "./styles.module.scss";

const FooterAnimateTiles = () => {
  const gridColumns = styles.columns;
  const gridRows = styles.rows;
  const gridSlots = gridColumns * gridRows;
  const gridSlotArray = [...Array(gridSlots)];

  const tileClassName = `footer-mobile-magic-tile`;

  const containerRef = useRef<HTMLDivElement>(null);

  // Same idea as with the scratchie tiles
  useEffect(() => {
    const tiles = containerRef.current?.querySelectorAll<HTMLElement>(
      `.${tileClassName}`
    );

    const animateTiles = (tileIndex = 0) => {
      if (!tiles?.[tileIndex]) return;

      const tile = tiles[tileIndex];

      const tileTransitionDelay = tileIndex * 20;

      setTimeout(() => {
        tile.style.transition = `0.2s`;
        tile.style.background = `var(--color-mint-green)`;
      }, tileTransitionDelay);

      setTimeout(() => {
        tile.style.transition = `2s`;
        tile.style.background = `none`;
      }, tileTransitionDelay + 400);

      animateTiles(tileIndex + 1);
    };

    animateTiles();
  }, []);

  return (
    <div ref={containerRef} className={styles.container}>
      {gridSlotArray.map((_e, i) => (
        <div className={tileClassName} key={i} />
      ))}
    </div>
  );
};

export default FooterAnimateTiles;
