// extracted by mini-css-extract-plugin
export var container = "styles-module--container--ce2bf";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var paddingBottomRegular = "styles-module--paddingBottomRegular--b8ef2";
export var paddingBottomSmall = "styles-module--paddingBottomSmall--df1b3";
export var paddingTopRegular = "styles-module--paddingTopRegular--c8f34";
export var paddingTopSmall = "styles-module--paddingTopSmall--290c5";
export var paddingXRegular = "styles-module--paddingXRegular--4003b";
export var paddingXSmall = "styles-module--paddingXSmall--7f941";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";