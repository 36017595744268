import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  onClick: () => void;
  active: boolean;
  text: string;
  numberOfItems: number;
}

const CheckboxFilterItem = ({
  active,
  onClick,
  text,
  numberOfItems
}: IProps) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <div>
        <span>{text}</span>
        {` `}
        <span>({numberOfItems})</span>
      </div>
      <div className={cn(styles.checkbox, { [styles.active]: active })} />
    </button>
  );
};

export default CheckboxFilterItem;
