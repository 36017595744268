import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";
import { ICountUpItem, IUniversalLinkWithText } from "~schemas";
import cn from "classnames";
import {
  SlicePadding,
  MagicGraphic,
  CountUp,
  UniversalLinkWrapper,
  SVG
} from "~components";
import { getMagicGraphicColorStyles } from "~utils";

interface IProps {
  item: ICountUpItem;
}

const CountUpItemContent = ({
  item: { graphic, number, showPlus, title, postNumberText, preNumberText }
}: IProps) => {
  const [overrideNumber, setOverrideNumber] = useState(0);

  useEffect(() => {
    if (title?.toLowerCase() !== `mario kart tournaments`) {
      return;
    }

    const load = async () => {
      try {
        const response = await fetch(
          `/.netlify/functions/get-mariokart-stats`,
          {
            method: `GET`,
            headers: {
              Accept: `application/json`,
              "Content-Type": `application/json`
            }
          }
        );

        if (!response.ok) {
          console.error(`Failed to fetch data`);
          return;
        }

        const jsonData = await response.json();

        if (
          typeof jsonData?.match_count === `number` &&
          jsonData?.match_count > 0
        ) {
          setOverrideNumber(jsonData.match_count);
        }
      } catch (error) {
        console.error(`Error fetching data: `, error);
      }
    };

    load();
  }, [title]);

  return (
    <div
      className="countUpItemTileContainer"
      style={getMagicGraphicColorStyles(graphic.variation)}
    >
      <SlicePadding xOnly className={styles.padding}>
        <div className={cn(`b1`, styles.title)}>{title}</div>

        <div className={styles.numberAndGraphic}>
          <CountUp
            number={overrideNumber || number}
            className={cn(`h1`, styles.number)}
            showPlus={showPlus && !overrideNumber}
            postNumberText={postNumberText}
            preNumberText={preNumberText}
          />

          <div className={styles.graphicContainer}>
            <div className={styles.graphic}>
              <MagicGraphic graphicVariation={graphic.variation} />
            </div>
          </div>
        </div>
      </SlicePadding>
    </div>
  );
};

const CountUpLink = ({ item }: IProps) => {
  const itemLink = item.link as IUniversalLinkWithText;
  const linkText = itemLink?.text;

  const graphicStyles = getMagicGraphicColorStyles(item.graphic.variation);

  return (
    <UniversalLinkWrapper className={styles.linkContainer} link={itemLink}>
      <CountUpItemContent item={item} />

      {linkText && (
        <div style={graphicStyles} className={styles.linkTextContainer}>
          <p className={cn("button-text")}>{linkText}</p>
          <SVG svg="arrowRight" className={styles.arrowIcon} />
        </div>
      )}
    </UniversalLinkWrapper>
  );
};

const CountUpItem = ({ item }: IProps) => {
  const itemLink = item.link;

  return (
    <>
      {itemLink ? (
        <CountUpLink item={item} />
      ) : (
        <CountUpItemContent item={item} />
      )}
    </>
  );
};

export default CountUpItem;
