import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.scss";
import CategoryButton from "./components/CategoryButton";
import { IToolboxResource } from "~schemas";
import { getAdvisorCategoryInfo } from "~utils";
import Advisor from "./components/Advisor";
import cn from "classnames";
import { useApp, useBreakpoints } from "~hooks";
import AdvisorLarge from "../../../AdvisorLarge";
import {
  TActiveAdvisorBlockIndex,
  TAdvisorCategory
} from "src/slices/Advisors";

interface IProps {
  advisors: IToolboxResource[];
  category: TAdvisorCategory;
  activeBlockIndex: TActiveAdvisorBlockIndex;
  setActiveBlockIndex: React.Dispatch<
    React.SetStateAction<TActiveAdvisorBlockIndex>
  >;
}

const Category = ({
  activeBlockIndex,
  category,
  setActiveBlockIndex,
  advisors
}: IProps) => {
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { index } = getAdvisorCategoryInfo(category);
  const { tablet } = useBreakpoints();
  const { setIsHeaderVisible } = useApp();

  const isActive = activeBlockIndex === index;

  const calculateContentHeight = () => {
    // Need this :(
    setTimeout(() => {
      const height = contentRef.current?.offsetHeight || 0;
      setContentHeight(height);
    }, 100);
  };

  const scrollToTopOfContainer = () => {
    const node = containerRef.current;
    if (!node) return;

    setTimeout(() => {
      node.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }, 350); // Transition duration is 300ms

    setTimeout(() => {
      setIsHeaderVisible(false);
    }, 1000); // After smooth scroll has finished
  };

  const AdvisorComponent = tablet ? AdvisorLarge : Advisor;

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  useEffect(() => {
    if (isActive) {
      scrollToTopOfContainer();
    }
  }, [isActive]);

  return (
    <div ref={containerRef}>
      <CategoryButton
        active={isActive}
        category={category}
        setActiveBlockIndex={setActiveBlockIndex}
      />
      <div
        className={styles.advisorsContainer}
        style={{ height: isActive ? `${contentHeight}px` : 0 }}
      >
        <div ref={contentRef}>
          {advisors?.[0] &&
            advisors.map((advisor, i) => (
              <AdvisorComponent advisor={advisor} key={i} />
            ))}

          {!advisors?.[0] && (
            <p className={cn(`h4`, styles.noAdvisors)}>
              No people in this category... yet
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Category;
