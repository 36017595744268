import React from "react";
import * as styles from "./styles.module.scss";
import { SVG } from "~components";
import cn from "classnames";

interface IProps {}

const Graphic4 = (props: IProps) => {
  return (
    <>
      <SVG svg="triangle" className={cn(styles.triangle, styles.triangle1)} />
      <SVG svg="triangle" className={cn(styles.triangle, styles.triangle2)} />
      <SVG svg="triangle" className={cn(styles.triangle, styles.triangle3)} />
      <SVG svg="triangle" className={cn(styles.triangle, styles.triangle4)} />
    </>
  );
};

export default Graphic4;
