import React from "react";
import type { IEntities } from "~schemas";
import { useBreakpoints } from "~hooks";
import MobileEntities from "./components/Mobile";
import DesktopEntities from "./components/Desktop";
import { SliceIntroText } from "~components";

interface IProps {
  data: IEntities;
}

const Entities = ({
  data: { introText, entity1, entity2, entity3, entity4 }
}: IProps) => {
  const { smallDesktop } = useBreakpoints();

  return (
    <>
      <SliceIntroText content={introText} />

      {!smallDesktop && (
        <MobileEntities
          entity1={entity1}
          entity2={entity2}
          entity3={entity3}
          entity4={entity4}
        />
      )}

      {smallDesktop && (
        <DesktopEntities
          entity1={entity1}
          entity2={entity2}
          entity3={entity3}
          entity4={entity4}
        />
      )}
    </>
  );
};

export default Entities;
