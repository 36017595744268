// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--8b00d";
export var appearDown = "styles-module--appear-down--1859b";
export var appearLeft = "styles-module--appear-left--7d4b3";
export var appearRight = "styles-module--appear-right--da10f";
export var appearUp = "styles-module--appear-up--68ab7";
export var container = "styles-module--container--d7c53";
export var content = "styles-module--content--269e2";
export var fader = "styles-module--fader--3de10";
export var opacityAndPointerEvents = "styles-module--opacity-and-pointer-events--bae4e";
export var preview = "styles-module--preview--8e96c";
export var sidebar = "styles-module--sidebar--75542";
export var updatePointerEvents = "styles-module--update-pointer-events--f18ff";
export var visible = "styles-module--visible--98c03";