import React, { CSSProperties, useEffect } from "react";
import cn from "classnames";
import { PortableTextRenderer, SVG, SlicePadding } from "~components";
import * as styles from "./styles.module.scss";
import { ISyndicateCompany, TColorTheme } from "~schemas";
import { useAccordion, useEmbla } from "~hooks";
import CompanySlide from "./components/CompanySlide";
import { getThemeColors } from "~utils";

interface IProps {
  companies: ISyndicateCompany[];
  _rawDisclaimer: any;
}

const SyndicateCompanies = ({ _rawDisclaimer, companies }: IProps) => {
  const { calculateContentHeight, containerHeight, contentRef } = useAccordion({
    openByDefault: true
  });

  const { activeSlideIndex, emblaRef, scrollNext, scrollPrev } = useEmbla(
    companies.length
  );

  const colorThemes: TColorTheme[] = [
    "Forest Green",
    "Dark Purple",
    "Gold",
    "Dark Blue",
    "Orange",
    "Beige"
  ];

  const getCompanyColors = (index: number) => {
    const colorTheme = colorThemes[index % colorThemes.length];
    return getThemeColors(colorTheme);
  };

  const { backgroundColor, textColor } = getCompanyColors(activeSlideIndex);

  const style = {
    "--syndicate-background-color": backgroundColor,
    "--syndicate-text-color": textColor
  } as CSSProperties;

  useEffect(() => {
    calculateContentHeight();
  }, [activeSlideIndex]);

  return (
    <div style={style} className={styles.container}>
      <SlicePadding className={styles.flexContainer}>
        <div className={styles.textSection}>
          <div
            style={{ height: containerHeight }}
            className={styles.textAccordion}
          >
            <div ref={contentRef} className={styles.accordionContent}>
              {companies.map((company, i) => (
                <p
                  key={i}
                  className={cn("b1", styles.text, {
                    [styles.active]: activeSlideIndex === i
                  })}
                >
                  {company.text}
                </p>
              ))}
            </div>
          </div>

          <PortableTextRenderer
            className={cn("caption")}
            rawText={_rawDisclaimer}
          />
        </div>

        {companies.length > 1 && (
          <div className={styles.navButtons}>
            <button
              disabled={activeSlideIndex === 0}
              onClick={scrollPrev}
              className={styles.navButton}
            >
              <SVG svg="chevronLeft" />
            </button>

            <button
              disabled={activeSlideIndex === companies.length - 1}
              onClick={scrollNext}
              className={styles.navButton}
            >
              <SVG svg="chevronRight" />
            </button>
          </div>
        )}
      </SlicePadding>

      {companies.length > 1 && (
        <div className={styles.carouselContainer}>
          <div ref={emblaRef} className={styles.carousel}>
            <div className={styles.carouselContent}>
              {companies.map((company, i) => (
                <CompanySlide
                  company={company}
                  key={i}
                  className={styles.carouselSlide}
                />
              ))}
            </div>
          </div>

          <div className={styles.arrowIconContainer}>
            <SVG className={styles.arrowCircleIcon} svg="arrowRightCircle" />
          </div>
        </div>
      )}

      {companies.length === 1 && <CompanySlide company={companies[0]} />}
    </div>
  );
};

export default SyndicateCompanies;
