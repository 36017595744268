import React, { useEffect, useRef, useState } from "react";
import { IFounderProfile } from "~schemas";
import ProfileBlock, { TProfileVariant } from "./components/ProfileBlock";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import BottomContent from "./components/BottomContent";

interface IProps {
  profile1: IFounderProfile;
  profile2: IFounderProfile;
}

export type TActiveProfile = null | TProfileVariant;

const FounderProfilesDesktop = ({ profile1, profile2 }: IProps) => {
  const [activeProfile, setActiveProfile] = useState<TActiveProfile>(null);
  const [mostRecentActiveProfile, setMostRecentActiveProfile] =
    useState<TProfileVariant>(1);
  const [profile1BottomHeight, setProfile1BottomHeight] = useState(0);
  const [profile2BottomHeight, setProfile2BottomHeight] = useState(0);

  const profile1BottomRef = useRef<HTMLDivElement>(null);
  const profile2BottomRef = useRef<HTMLDivElement>(null);

  const calculateContentHeight = () => {
    const profile1Height = profile1BottomRef.current?.offsetHeight || 0;
    const profile2Height = profile2BottomRef.current?.offsetHeight || 0;
    setProfile1BottomHeight(profile1Height);
    setProfile2BottomHeight(profile2Height);
  };

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  // The initial height calculation is incorrect for some reason >:(
  // This ensures height is correct
  useEffect(() => {
    calculateContentHeight();
  }, [activeProfile]);

  const bottomContentHeight = () => {
    switch (activeProfile) {
      case null:
        return 0;
      case 1:
        return profile1BottomHeight;
      case 2:
        return profile2BottomHeight;
      default:
        return 0;
    }
  };

  const handleClick = (profile: TProfileVariant) => {
    if (profile === activeProfile) {
      setActiveProfile(null);
      return;
    }
    setActiveProfile(profile);
  };

  useEffect(() => {
    if (activeProfile) {
      setMostRecentActiveProfile(activeProfile);
    }
  }, [activeProfile]);

  return (
    <>
      <div className={styles.topProfiles}>
        <button
          onClick={() => handleClick(1)}
          className={cn(styles.profile, {
            [styles.active]: activeProfile === 1
          })}
        >
          <ProfileBlock
            {...profile1}
            variant={1}
            activeProfile={activeProfile}
          />
        </button>
        <button
          onClick={() => handleClick(2)}
          className={cn(styles.profile, {
            [styles.active]: activeProfile === 2
          })}
        >
          <ProfileBlock
            {...profile2}
            variant={2}
            activeProfile={activeProfile}
          />
        </button>
      </div>

      <div
        className={styles.bottomContentContainer}
        style={{ height: bottomContentHeight() }}
      >
        <div
          className={cn(styles.bottomContent, {
            [styles.showRight]: mostRecentActiveProfile === 2
          })}
        >
          <BottomContent
            active={activeProfile === 1}
            innerRef={profile1BottomRef}
            profile={profile1}
          />
          <BottomContent
            active={activeProfile === 2}
            innerRef={profile2BottomRef}
            profile={profile2}
          />
        </div>
      </div>
    </>
  );
};

export default FounderProfilesDesktop;
