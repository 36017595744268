import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  active: boolean;
}

const AtfLetter1M = ({ active }: IProps) => {
  return (
    <div className={cn(styles.container, { [styles.active]: active })}>
      <div className={styles.top}>
        <div className={styles.col1} />
        <div className={styles.col2} />
        <div className={styles.col3} />
        <div className={styles.col4} />
        <div className={styles.col5} />
      </div>
      <div className={styles.bottom}>
        <div className={styles.col1} />
        <div className={styles.col2} />
        <div className={styles.col3} />
        <div className={styles.col4} />
        <div className={styles.col5} />
      </div>
    </div>
  );
};

export default AtfLetter1M;
