// extracted by mini-css-extract-plugin
export var button = "styles-module--button--590d4";
export var buttonPadding = "styles-module--buttonPadding--2528c";
export var caption = "styles-module--caption--40506";
export var chevronIcon = "styles-module--chevronIcon--2ecfb";
export var content = "styles-module--content--b0f1f";
export var contentContainer = "styles-module--contentContainer--2cb2f";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--291fa";
export var linkContent = "styles-module--linkContent--4706e";
export var linkIcon = "styles-module--linkIcon--efb9c";
export var linkTitleAndIcon = "styles-module--linkTitleAndIcon--d2bfc";
export var mobile = "400px";
export var rotate = "styles-module--rotate--25393";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";