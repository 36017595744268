// extracted by mini-css-extract-plugin
export var container = "styles-module--container--9d1d1";
export var desktop = "styles-module--desktop--7bd1b";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "styles-module--mobile--8188e";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";