import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  isActive: boolean;
  text: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const FilterItem = ({ isActive, onChange, text, className }: IProps) => {
  return (
    <label className={cn("button-text", styles.filterItem, className)}>
      <div className={cn(styles.customBox, { [styles.active]: isActive })} />

      <input
        className={styles.input}
        onChange={onChange}
        checked={isActive}
        type="checkbox"
      />

      <span className={styles.text}>{text}</span>
    </label>
  );
};

export default FilterItem;
