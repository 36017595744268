import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.scss";
import { IEntity } from "~schemas";
import cn from "classnames";
import { SVG } from "~components";

export interface IProps {
  entity: IEntity;
  isActive: boolean;
  mainColor: string;
  textColor?: string;
  leftColor?: string;
  rightColor?: string;
  handleClick: () => void;
}

const EntityAccordion = ({
  isActive,
  entity,
  mainColor,
  textColor,
  leftColor,
  rightColor,
  handleClick
}: IProps) => {
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  const calculateContentHeight = () => {
    const height = contentRef.current?.offsetHeight || 0;
    setContentHeight(height);
  };

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  return (
    <button
      type="button"
      onClick={handleClick}
      className={cn(styles.button, { [styles.active]: isActive })}
      style={{ color: textColor, background: mainColor }}
    >
      <div className={styles.tabContainer}>
        {leftColor && (
          <div style={{ background: leftColor }} className={styles.sideColor} />
        )}

        <div className={styles.tabContent}>
          <div>
            <h4 className={cn(`h2`, styles.title)}>{entity.title}</h4>
            <div className={cn(`b1`)}>{entity.statistic}</div>
          </div>
          <SVG svg="plusCircle" className={styles.expandIcon} />
        </div>

        {rightColor && (
          <div
            style={{ background: rightColor }}
            className={styles.sideColor}
          />
        )}
      </div>

      <div
        className={styles.contentContainer}
        style={{ height: isActive ? `${contentHeight}px` : `0px` }}
      >
        <div className={styles.content} ref={contentRef}>
          {leftColor && (
            <div
              style={{ background: leftColor }}
              className={styles.sideColor}
            />
          )}

          <p className={cn(`b2`, styles.description)}>{entity.description}</p>

          {rightColor && (
            <div
              style={{ background: rightColor }}
              className={styles.sideColor}
            />
          )}
        </div>
      </div>
    </button>
  );
};

export default EntityAccordion;
