import React from "react";
import * as styles from "./styles.module.scss";
import { NetworkLetterGraphic, SVG } from "~components";
import { getAdvisorCategoryInfo } from "~utils";
import cn from "classnames";
import {
  TActiveAdvisorBlockIndex,
  TAdvisorCategory
} from "src/slices/Advisors";

interface IProps {
  category: TAdvisorCategory;
  active: boolean;
  setActiveBlockIndex: React.Dispatch<
    React.SetStateAction<TActiveAdvisorBlockIndex>
  >;
}

const CategoryButton = ({ category, active, setActiveBlockIndex }: IProps) => {
  const { categoryPlural, graphic, textColor, index } =
    getAdvisorCategoryInfo(category);

  const handleClick = () => {
    setActiveBlockIndex((prev) => {
      if (prev === index) return null;
      return index;
    });
  };

  return (
    <button
      onClick={handleClick}
      className={cn(styles.container, { [styles.active]: active })}
    >
      <NetworkLetterGraphic
        graphic={graphic}
        active={active}
        className={styles.graphic}
      />

      <div className={styles.content} style={{ color: textColor }}>
        <h3 className={cn(`h2`)}>{categoryPlural}</h3>
        <SVG svg="plusCircle" className={styles.plusIcon} />
      </div>
    </button>
  );
};

export default CategoryButton;
