import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import { SwiperCarousel } from "~components";
import { ICaseStudy, IGlobalLink } from "~schemas";
import Slide from "./components/Slide";

interface IProps {
  quadrant1: IGlobalLink | ICaseStudy;
  quadrant2: IGlobalLink | ICaseStudy;
  quadrant3: IGlobalLink | ICaseStudy;
  quadrant4: IGlobalLink | ICaseStudy;
}

const HomepageAtfMobile = ({
  quadrant1,
  quadrant2,
  quadrant3,
  quadrant4
}: IProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <div className={styles.container}>
        <SwiperCarousel withTimer setActiveIndex={setActiveIndex}>
          <Slide
            contentData={quadrant1}
            slideIndex={0}
            activeIndex={activeIndex}
          />
          <Slide
            contentData={quadrant2}
            slideIndex={1}
            activeIndex={activeIndex}
          />
          <Slide
            contentData={quadrant3}
            slideIndex={2}
            activeIndex={activeIndex}
          />
          <Slide
            contentData={quadrant4}
            slideIndex={3}
            activeIndex={activeIndex}
          />
        </SwiperCarousel>
      </div>
    </>
  );
};

export default HomepageAtfMobile;
