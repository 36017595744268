// extracted by mini-css-extract-plugin
export var active = "styles-module--active--5b762";
export var button = "styles-module--button--30398";
export var container = "styles-module--container--6e02a";
export var content = "styles-module--content--93e7d";
export var contentContainer = "styles-module--contentContainer--af35f";
export var description = "styles-module--description--4abfb";
export var expandIcon = "styles-module--expandIcon--007d5";
export var sideColor = "styles-module--sideColor--051a6";
export var tabContainer = "styles-module--tabContainer--c1e77";
export var tabContent = "styles-module--tabContent--2b2d7";
export var title = "styles-module--title--43181";