// extracted by mini-css-extract-plugin
export var colorBlock = "styles-module--colorBlock--9c094";
export var container = "styles-module--container--eda1a";
export var desktop = "1340px";
export var giant = "2200px";
export var graphicContainer = "styles-module--graphicContainer--64186";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftColumn = "styles-module--leftColumn--9314c";
export var mobile = "400px";
export var rightColumn = "styles-module--rightColumn--93ee5";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--679df";