// extracted by mini-css-extract-plugin
export var container = "styles-module--container--ca376";
export var desktop = "1340px";
export var down = "styles-module--down--fb698";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var scrollDown = "styles-module--scroll-down--7cbb1";
export var scrollUp = "styles-module--scroll-up--e0578";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var up = "styles-module--up--8a6f3";
export var word = "styles-module--word--14bb0";
export var words = "styles-module--words--bc6ea";