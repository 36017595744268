// extracted by mini-css-extract-plugin
export var active = "styles-module--active--e2999";
export var appear = "styles-module--appear--2fa9c";
export var appearDown = "styles-module--appear-down--c0943";
export var appearLeft = "styles-module--appear-left--5c00d";
export var appearRight = "styles-module--appear-right--0984d";
export var appearUp = "styles-module--appear-up--d5f29";
export var bgOverlay = "styles-module--bgOverlay--3cf6d";
export var categoryGroup = "styles-module--categoryGroup--6f206";
export var contactsCount = "styles-module--contactsCount--9a53d";
export var container = "styles-module--container--a5403";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--6fe10";
export var headerHeight = "54px";
export var homeLink = "styles-module--homeLink--f5260";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logoImage = "styles-module--logoImage--f95fe";
export var menuButton = "styles-module--menuButton--ac6d6";
export var menuButtonIcon = "styles-module--menuButtonIcon--07877";
export var menuButtonIconX = "styles-module--menuButtonIconX--9bd97";
export var mobile = "400px";
export var nav = "styles-module--nav--566f7";
export var navContainer = "styles-module--navContainer--0a41d";
export var opacityAndPointerEvents = "styles-module--opacity-and-pointer-events--d275f";
export var open = "styles-module--open--7defd";
export var rightButtons = "styles-module--rightButtons--8edba";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var smaller = "styles-module--smaller--ac7d8";
export var tablet = "769px";
export var updatePointerEvents = "styles-module--update-pointer-events--727ef";
export var visible = "styles-module--visible--7add3";
export var wordmark = "styles-module--wordmark--2745d";