import React from "react";
import * as styles from "./styles.module.scss";
import PortfolioItem from "../PortfolioItem";
import Marquee from "react-fast-marquee";
import { IPortfolioItem, ISliceIntroText } from "~schemas";
import { SliceIntroText, SlicePadding } from "~components";

interface IProps {
  portfoliloItems: IPortfolioItem[];
  sliceIntroContent: ISliceIntroText;
}

const CarouselGallery = ({ portfoliloItems, sliceIntroContent }: IProps) => {
  const numberOfDuplications = portfoliloItems.length < 8 ? 2 : 1;
  const duplicatedArray = [];

  for (let i = 0; i < numberOfDuplications; i++) {
    duplicatedArray.push(portfoliloItems);
  }

  const flatDuplicatedArray = duplicatedArray.flat();

  return (
    <>
      <SliceIntroText content={sliceIntroContent} />
      <SlicePadding
        config={{
          paddingBottom: "regular",
          paddingTop: "none",
          paddingX: "none"
        }}
      >
        <Marquee gradient={false} speed={70}>
          {flatDuplicatedArray.map((item, i) => (
            <div className={styles.marqueeItem} key={i}>
              <PortfolioItem item={item} index={i} />
            </div>
          ))}
        </Marquee>
      </SlicePadding>
    </>
  );
};

export default CarouselGallery;
