import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { IPortfolioGallery, IPortfolioItem } from "~schemas";
import GridGallery from "./components/GridGallery";
import CarouselGallery from "./components/CarouselGallery";
import { useInView } from "react-intersection-observer";

interface IProps {
  data: IPortfolioGallery;
}

interface IPortfolioQueryResults {
  allSanityPortfolioItem: {
    nodes: IPortfolioItem[];
  };
}

const PortfolioGallery = ({
  data: {
    variant,
    _rawTextGrid,
    textCarousel,
    includeAllItems,
    portfolioItems: sanityItems
  }
}: IProps) => {
  const portfolioQueryResults: IPortfolioQueryResults = useStaticQuery(query);

  const portfolioItems = includeAllItems
    ? portfolioQueryResults.allSanityPortfolioItem.nodes
    : (sanityItems as IPortfolioItem[]);

  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <div ref={ref}>
      {variant === `carousel` && textCarousel && (
        <CarouselGallery
          portfoliloItems={portfolioItems}
          sliceIntroContent={textCarousel}
        />
      )}

      {variant === `grid` && (
        <GridGallery
          _rawTextGrid={_rawTextGrid}
          portfoliloItems={portfolioItems}
          inView={inView}
        />
      )}
    </div>
  );
};

export default PortfolioGallery;

const query = graphql`
  query PortfolioQuery {
    allSanityPortfolioItem {
      nodes {
        _id
        title
        logo {
          asset {
            gatsbyImageData
          }
        }
        country
        type
        category
        exit
        link {
          ... on SanityCaseStudyReference {
            _key
            _type
            caseStudy {
              slug {
                current
              }
            }
          }
          ... on SanityExternalUrl {
            _key
            _type
            url
          }
        }
      }
    }
  }
`;
