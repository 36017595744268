import React from "react";
import { IUniversalLinkWithText } from "~schemas";
import { Button, TSvg } from "~components";
import cn from "classnames";

interface IProps extends IUniversalLinkWithText {
  iconLeft?: TSvg;
  iconRight?: TSvg;
  className?: string;
}

const UniversalLink = ({
  link: linkArray,
  jumpToSection,
  text,
  iconLeft,
  iconRight,
  className
}: IProps) => {
  const link = linkArray?.[0];

  const jumpToSectionText = jumpToSection ? `#${jumpToSection}` : ``;

  switch (link._type) {
    case `externalUrl`:
      return (
        <Button
          iconLeft={iconLeft}
          iconRight={iconRight}
          href={`${link.url}${jumpToSectionText}`}
          className={cn(className)}
        >
          {text}
        </Button>
      );

    case `pageReference`:
      return (
        <Button
          iconLeft={iconLeft}
          iconRight={iconRight}
          to={`/${link.page.slug.current}${jumpToSectionText}`}
          className={cn(className)}
        >
          {text}
        </Button>
      );

    case `caseStudyReference`:
      return (
        <Button
          iconLeft={iconLeft}
          iconRight={iconRight}
          to={`/awesome-stories/${link.caseStudy.slug.current}${jumpToSectionText}`}
          className={cn(className)}
        >
          {text}
        </Button>
      );

    default:
      return null;
  }
};

export default UniversalLink;
