import React from "react";
import { IToolboxResource } from "~schemas";
import Category from "./components/Category";
import { TActiveAdvisorBlockIndex, TAdvisorCategory } from "../..";

interface IProps {
  advisors: IToolboxResource[];
  activeBlockIndex: TActiveAdvisorBlockIndex;
  setActiveBlockIndex: React.Dispatch<
    React.SetStateAction<TActiveAdvisorBlockIndex>
  >;
  categories: TAdvisorCategory[];
}

const Mobile = ({
  advisors,
  activeBlockIndex,
  setActiveBlockIndex,
  categories
}: IProps) => {
  const getCategoryAdvisors = (category: TAdvisorCategory) => {
    const categoryAdvisors = advisors.filter(
      (advisor) => advisor.category.title === category
    );

    return categoryAdvisors;
  };

  const categoryComponent = (category: TAdvisorCategory) => (
    <Category
      category={category}
      activeBlockIndex={activeBlockIndex}
      setActiveBlockIndex={setActiveBlockIndex}
      advisors={getCategoryAdvisors(category)}
    />
  );

  return (
    <>
      {categories.map((category, i) => (
        <div key={i}>{categoryComponent(category)}</div>
      ))}
    </>
  );
};

export default Mobile;
