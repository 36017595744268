import React from "react";
import type { ICallToAdventure } from "~schemas";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import WordScroller from "./components/WordScroller";
import { IntersectionAnimation, UniversalLink } from "~components";
import { useInView } from "react-intersection-observer";

interface IProps {
  data: ICallToAdventure;
}

const CallToAdventure = ({ data: { header, text, universalLink } }: IProps) => {
  const marqueeWords = [
    `Funding`,
    `Support`,
    `Mentorship`,
    `Community`,
    `Advice`
  ];

  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.scrollingText}>
        <WordScroller className={styles.wordScroller} words={marqueeWords} />
        <WordScroller
          className={cn(styles.wordScroller, styles.desktop)}
          words={marqueeWords}
          direction="up"
        />
        <WordScroller
          className={cn(styles.wordScroller, styles.desktop)}
          words={marqueeWords}
        />
      </div>

      <div className={styles.graphicGrid}>
        <div className={styles.mainContentContainer}>
          <div className={styles.mainContent}>
            <IntersectionAnimation trigger={inView} delay={300}>
              <h2 className={cn(`h1`, styles.header)}>{header}</h2>
            </IntersectionAnimation>
            <IntersectionAnimation trigger={inView} delay={450}>
              <p className={cn(`b1`, styles.text)}>{text}</p>
            </IntersectionAnimation>

            {universalLink?.link && (
              <IntersectionAnimation trigger={inView} delay={600}>
                <UniversalLink
                  link={universalLink.link}
                  jumpToSection={universalLink?.jumpToSection}
                  text={universalLink.text}
                />
              </IntersectionAnimation>
            )}
          </div>
        </div>

        <div className={styles.filled1} />
        <div className={styles.filled2} />
        <div className={styles.filled3} />
      </div>
    </div>
  );
};

export default CallToAdventure;
