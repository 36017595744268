import { useState, useEffect } from "react";
import * as bp from "~styles/breakpoints.module.scss";
import useWindowDimensions from "./useWindowDimensions";

type TBreakpoint =
  | `giant`
  | `largeDesktop`
  | `desktop`
  | `smallDesktop`
  | `largeTablet`
  | `tablet`
  | `smallTablet`
  | `largeMobile`
  | `mobile`
  | `smallMobile`;

type TBreakpoints = {
  [key in TBreakpoint]: boolean;
};

const useBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState<TBreakpoints>({
    ...bp
  });

  const { width } = useWindowDimensions();

  const bpArray = Object.keys(bp);

  const parseBpStringToInt = (bpStringWithPx: string) =>
    parseInt(bpStringWithPx.replace(`px`, ``));

  useEffect(() => {
    const updatedBreakpoints: TBreakpoints = {} as TBreakpoints;

    for (let i = 0; i < bpArray.length; i += 1) {
      updatedBreakpoints[bpArray[i] as TBreakpoint] =
        width >= parseBpStringToInt(bp[bpArray[i]]);
    }

    setBreakpoints(updatedBreakpoints);
  }, [width]);

  return breakpoints;
};

export default useBreakpoints;
