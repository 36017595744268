import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {}

const Graphic6 = (props: IProps) => {
  return (
    <>
      <div className={cn(styles.semicircle, styles.semicircle1)} />
      <div className={cn(styles.semicircle, styles.semicircle2)} />
      <div className={cn(styles.semicircle, styles.semicircle3)} />
      <div className={cn(styles.semicircle, styles.semicircle4)} />
    </>
  );
};

export default Graphic6;
