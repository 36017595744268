import React from "react";
import NetworkLetter1A from "./1A";
import NetworkLetter2L from "./2L";
import NetworkLetter3A from "./3A";
import NetworkLetter4P from "./4P";
import cn from "classnames";

export type TNetworkLetterGraphic = `1A` | `2L` | `3A` | `4P`;

interface IProps {
  graphic: TNetworkLetterGraphic;
  active?: boolean;
  className?: string;
}

const NetworkLetterGraphic = ({ graphic, active, className }: IProps) => {
  const graphicComponent = () => {
    switch (graphic) {
      case `1A`:
        return <NetworkLetter1A active={active} />;
      case `2L`:
        return <NetworkLetter2L active={active} />;
      case `3A`:
        return <NetworkLetter3A active={active} />;
      case `4P`:
        return <NetworkLetter4P active={active} />;
      default:
        return null;
    }
  };

  return <div className={cn(className)}>{graphicComponent()}</div>;
};

export default NetworkLetterGraphic;
