import React from "react";
import { ISliceConfig } from "~schemas";
import { SlicePadding } from "~components";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  children: React.ReactNode;
  config?: ISliceConfig;
  className?: string;
}

interface ISliceConfigColors {
  color?: string;
  backgroundColor?: string;
}

const SliceConfig = ({ children, config, className }: IProps) => {
  const { backgroundColor, textColor, slicePadding } = config || {};

  const colorStyles: ISliceConfigColors = {};
  if (textColor?.hex) {
    colorStyles.color = textColor.hex;
  }
  if (backgroundColor?.hex) {
    colorStyles.backgroundColor = backgroundColor.hex;
  }

  return (
    <div style={colorStyles} className={cn(className)}>
      <SlicePadding config={slicePadding}>
        <div className={styles.content}>{children}</div>
      </SlicePadding>
    </div>
  );
};

export default SliceConfig;
