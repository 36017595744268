interface IFormField {
  label: string;
  placeholder: string;
}

interface IFormFieldMaxLength extends IFormField {
  maxLength: number;
}

interface IContactFormData {
  name: IFormField;
  email: IFormField;
  how: IFormFieldMaxLength;
  message: IFormFieldMaxLength;
  honeypot: IFormField;
}

const contactFormData: IContactFormData = {
  name: {
    label: "Name",
    placeholder: "What's your name?"
  },
  email: {
    label: "Email address",
    placeholder: "Enter your email address"
  },
  how: {
    label: "How are you making awesome?",
    placeholder: "Tell us what you're working on",
    maxLength: 100
  },
  message: {
    label: "What do you need to make it awesome?",
    placeholder: "Funding, Hugs, Networking, Advice, Mentorship?",
    maxLength: 150
  },
  honeypot: {
    label: "Ignore this if you are a human",
    placeholder: "Type your message here..."
  }
};

export default contactFormData;
