// extracted by mini-css-extract-plugin
export var barrier = "styles-module--barrier--ca2a5";
export var columns = "10";
export var container = "styles-module--container--b9245";
export var desktop = "1340px";
export var giant = "2200px";
export var hidden = "styles-module--hidden--6c91e";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var quote = "styles-module--quote--7b435";
export var rows = "5";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tapGif = "styles-module--tapGif--27d7d";
export var tapGifContainer = "styles-module--tapGifContainer--c3261";
export var tile = "styles-module--tile--0d6b0";
export var topLine = "styles-module--topLine--e486c";