import React from "react";
import * as styles from "./styles.module.scss";
import {
  Grid,
  IntersectionAnimation,
  PortableTextRenderer,
  SlicePadding
} from "~components";
import { useInView } from "react-intersection-observer";
import Filter, { type IProps as IFilterProps } from "./components/Filter";

interface IProps extends IFilterProps {
  _rawHeaderText: any;
}

const HeaderAndFilter = ({
  _rawHeaderText,
  categories,
  selectedCategory,
  setSelectedCategory
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <div ref={ref}>
      <SlicePadding>
        <Grid>
          {_rawHeaderText && (
            <div className={styles.leftColumn}>
              <IntersectionAnimation trigger={inView} animation="fade">
                <PortableTextRenderer
                  textElement="h2"
                  rawText={_rawHeaderText}
                  className="h1"
                />
              </IntersectionAnimation>
            </div>
          )}

          {categories.length > 1 && (
            <div className={styles.rightColumn}>
              <IntersectionAnimation
                trigger={inView}
                animation="fade"
                delay={150}
              >
                <Filter
                  categories={categories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              </IntersectionAnimation>
            </div>
          )}
        </Grid>
      </SlicePadding>
    </div>
  );
};

export default HeaderAndFilter;
