import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.scss";
import { IToolboxResource } from "~schemas";
import Category from "./components/Category";
import cn from "classnames";
import { TActiveAdvisorBlockIndex, TAdvisorCategory } from "../..";

interface IProps {
  advisors: IToolboxResource[];
  activeBlockIndex: TActiveAdvisorBlockIndex;
  setActiveBlockIndex: React.Dispatch<
    React.SetStateAction<TActiveAdvisorBlockIndex>
  >;
  categories: TAdvisorCategory[];
}

const Desktop = ({
  advisors,
  activeBlockIndex,
  setActiveBlockIndex,
  categories
}: IProps) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [hoveredBlockIndex, setHoveredBlockIndex] =
    useState<TActiveAdvisorBlockIndex>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const calculateContainerWidth = () => {
    if (!containerRef.current) return;
    setContainerWidth(containerRef.current.clientWidth);
  };

  useEffect(() => {
    calculateContainerWidth();
    window.addEventListener(`resize`, calculateContainerWidth);
    return () => window.removeEventListener(`resize`, calculateContainerWidth);
  }, []);

  const getCategoryAdvisors = (category: TAdvisorCategory) => {
    const categoryAdvisors = advisors.filter(
      (advisor) => advisor.category.title === category
    );

    return categoryAdvisors;
  };

  const categoryComponent = (category: TAdvisorCategory) => (
    <Category
      category={category}
      activeBlockIndex={activeBlockIndex}
      setActiveBlockIndex={setActiveBlockIndex}
      advisors={getCategoryAdvisors(category)}
      containerWidth={containerWidth}
      hoveredBlockIndex={hoveredBlockIndex}
      setHoveredBlockIndex={setHoveredBlockIndex}
    />
  );

  const handleMouseLeave = () => {
    setHoveredBlockIndex(null);
  };

  return (
    <div
      className={styles.container}
      ref={containerRef}
      onMouseLeave={handleMouseLeave}
    >
      {categories.map((category, i) => (
        <div
          className={cn(styles.category, {
            [styles.active0]: activeBlockIndex === 0,
            [styles.active1]: activeBlockIndex === 1,
            [styles.active2]: activeBlockIndex === 2,
            [styles.active3]: activeBlockIndex === 3
          })}
          key={i}
        >
          {categoryComponent(category)}
        </div>
      ))}
    </div>
  );
};

export default Desktop;
