import React from "react";
import * as styles from "./styles.module.scss";

const NoJs = () => (
  <noscript>
    <div className={styles.container}>
      <p>
        For full functionality of this site it is necessary to enable
        JavaScript.
      </p>

      <a href="https://www.enable-javascript.com/" aria-label="Enable JS">
        Here are the instructions how to enable JavaScript in your web browser.
      </a>
    </div>
  </noscript>
);

export default NoJs;
