import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {}

const Graphic1 = (props: IProps) => {
  return (
    <>
      <div className={cn(styles.circle, styles.circle1)} />
      <div className={cn(styles.circle, styles.circle2)} />
      <div className={cn(styles.circle, styles.circle3)} />
    </>
  );
};

export default Graphic1;
