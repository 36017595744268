// extracted by mini-css-extract-plugin
export var blockContainer = "styles-module--blockContainer--1c026";
export var button = "styles-module--button--6d1a7";
export var container = "styles-module--container--ee15a";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--7ff23";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--56199";
export var textContainer = "styles-module--textContainer--a1629";