import React from "react";
import {
  SliceConfig,
  Grid,
  PortableTextRenderer,
  IntersectionAnimation,
  Button,
  LinkTag
} from "~components";
import { ITextArticle } from "~schemas";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  data: ITextArticle;
}

const TextArticle = ({
  data: { _rawLeftColumn, _rawRightColumn, links, tags, sliceConfig }
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  const hasBottomPadding = sliceConfig.slicePadding?.paddingBottom !== `none`;

  return (
    <div ref={ref}>
      <SliceConfig config={sliceConfig}>
        <Grid
          className={cn(styles.grid, {
            [styles.bottomMargin]: hasBottomPadding
          })}
        >
          {_rawLeftColumn && (
            <div className={styles.leftColumn}>
              <IntersectionAnimation trigger={inView}>
                <PortableTextRenderer
                  rawText={_rawLeftColumn}
                  textElement="h2"
                />
              </IntersectionAnimation>

              {tags?.[0] && (
                <IntersectionAnimation delay={150} className={styles.linkTags}>
                  {tags.map((tag, i) => (
                    <LinkTag
                      key={i}
                      linkType={tag.linkType}
                      hoverColor={tag.hoverColor}
                      linkExternal={tag.linkExternal}
                      linkInternal={tag.linkInternal}
                    />
                  ))}
                </IntersectionAnimation>
              )}
            </div>
          )}
          {_rawRightColumn && (
            <div className={styles.rightColumn}>
              <IntersectionAnimation delay={150} trigger={inView}>
                <PortableTextRenderer
                  rawText={_rawRightColumn}
                  textElement="p"
                />
              </IntersectionAnimation>

              {links?.[0] && (
                <IntersectionAnimation delay={300} className={styles.buttons}>
                  {links?.map((link, i) => {
                    if (link._type === `linkInternal`) {
                      return (
                        <IntersectionAnimation
                          animation="fadeGrow"
                          delay={300 + 100 * i}
                        >
                          <Button
                            key={i}
                            to={`/${link.reference.slug.current}`}
                          >
                            {link.title}
                          </Button>
                        </IntersectionAnimation>
                      );
                    }

                    if (link._type === `linkExternal`) {
                      return (
                        <IntersectionAnimation
                          animation="fadeGrow"
                          delay={300 + 100 * i}
                        >
                          <Button key={i} href={link.url}>
                            {link.title}
                          </Button>
                        </IntersectionAnimation>
                      );
                    }

                    return null;
                  })}
                </IntersectionAnimation>
              )}
            </div>
          )}
        </Grid>
      </SliceConfig>
    </div>
  );
};

export default TextArticle;
