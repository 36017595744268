// extracted by mini-css-extract-plugin
export var arrowIcon = "styles-module--arrowIcon--bca38";
export var button = "styles-module--button--8c6fa";
export var container = "styles-module--container--a8e56";
export var content = "styles-module--content--a39d8";
export var desktop = "1340px";
export var disableScroll = "styles-module--disableScroll--b736a";
export var fractureContainer = "styles-module--fractureContainer--f937d";
export var fracturePiece = "styles-module--fracturePiece--023b0";
export var fractureRelative = "styles-module--fractureRelative--a9b7b";
export var giant = "2200px";
export var hidden = "styles-module--hidden--a49f3";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var relative = "styles-module--relative--a541c";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textBlock = "styles-module--textBlock--562dd";