import { useStaticQuery, graphql } from "gatsby";

interface ISiteMetaData {
  description: string;
  title: string;
  siteUrl: string;
}

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            description
            title
            siteUrl
          }
        }
      }
    `
  );

  return site.siteMetadata as ISiteMetaData;
};

export default useSiteMetadata;
