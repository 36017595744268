// extracted by mini-css-extract-plugin
export var absoluteContainer = "styles-module--absoluteContainer--80886";
export var container = "styles-module--container--db51e";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var quadrantsDesktop = "styles-module--quadrantsDesktop--8b4ee";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";