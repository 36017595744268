// extracted by mini-css-extract-plugin
export var active = "styles-module--active--70014";
export var categoryButton = "styles-module--categoryButton--1b291";
export var chevronIcon = "styles-module--chevronIcon--f403c";
export var desktop = "1340px";
export var dropdown = "styles-module--dropdown--2b1ae";
export var dropdownButton = "styles-module--dropdownButton--7581d";
export var dropdownContainer = "styles-module--dropdownContainer--d1e72";
export var dropdownContent = "styles-module--dropdownContent--67008";
export var dropdownListItem = "styles-module--dropdownListItem--1fe81";
export var filterLabel = "styles-module--filterLabel--97f58";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var selectedFilter = "styles-module--selectedFilter--65dc2";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";