import React, { useState } from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { IToolboxResource, IToolboxResources } from "~schemas";
import { Button, IntersectionAnimation, SlicePadding } from "~components";
import ResourceCard from "./components/ResourceCard";
import { useBreakpoints, useIntersectionAnimation } from "~hooks";
import Filters from "./components/Filters";
import MobileFilterMenu from "./components/MobileFilterMenu";

interface IQueryResults {
  allSanityToolboxResource: {
    nodes: IToolboxResource[];
  };
}

export interface IFilter {
  title: string;
  active: boolean;
}

export interface ICategory {
  title: string;
  active: boolean;
  subcategories?: IFilter[];
}

export interface IFilters {
  categories: ICategory[];
  types: IFilter[];
}

interface IProps {
  data: IToolboxResources;
}

const ToolboxResources = ({ data: { header, subheader } }: IProps) => {
  const {
    allSanityToolboxResource: { nodes: toolboxResources }
  }: IQueryResults = useStaticQuery(query);

  const getInitialFilters: () => IFilters = () => {
    const categories: ICategory[] = [];
    const types: IFilter[] = [];

    toolboxResources.forEach((resource) => {
      const typeAlreadyExists = Boolean(
        types.find((type) => type.title === resource.type.title)
      );

      if (!typeAlreadyExists) {
        types.push({
          active: false,
          title: resource.type.title
        });
      }

      const categoryAlreadyExists = Boolean(
        categories.find(
          (category) => category.title === resource.category.title
        )
      );

      if (!categoryAlreadyExists) {
        categories.push({
          active: false,
          title: resource.category.title,
          subcategories: []
        });
      }

      resource.subcategories?.forEach((subcategory) => {
        const category = categories.find(
          (category) => category.title === resource.category.title
        );
        if (!category) return;

        const subcategoryAlreadyExists = Boolean(
          category.subcategories?.find(
            (currentSubcategory) =>
              currentSubcategory.title === subcategory.title
          )
        );

        if (!subcategoryAlreadyExists) {
          category.subcategories?.push({
            active: false,
            title: subcategory.title
          });
        }
      });
    });

    return {
      categories,
      types
    };
  };
  const initialFilters = getInitialFilters();

  const RESULTS_PER_PATCH = 6;

  const [numberOfResultsToDisplay, setNumberOfResultsToDisplay] =
    useState(RESULTS_PER_PATCH);
  const [filters, setFilters] = useState<IFilters>(initialFilters);
  const [isMobileFilteMenuOpen, setIsMobileFilterMenuOpen] = useState(false);
  const [staggerTileAnimation, setStaggerTileAnimation] = useState(true);

  const activeCategoryFilters = filters.categories
    .filter((category) => category.active)
    .map((category) => category.title);

  const activeTypeFilters = filters.types
    .filter((type) => type.active)
    .map((type) => type.title);

  const areActiveFilters = activeCategoryFilters?.[0] || activeTypeFilters?.[0];

  const isResourceInActiveSubcategory: (
    resource: IToolboxResource
  ) => boolean = (resource) => {
    const resourceCategoryTitle = resource.category.title;
    const resourceCategorySubcategories = filters.categories.find(
      (category) => category.title === resourceCategoryTitle
    )?.subcategories;

    const activeSubcategoryFilters = resourceCategorySubcategories
      ?.filter((subcategory) => subcategory.active)
      .map((subcategory) => subcategory.title);

    if (!activeSubcategoryFilters?.[0]) return true;
    if (!resource.subcategories?.[0]) return false;

    let isResourceInActiveSubcategory = false;
    resource.subcategories?.forEach((subcategory) => {
      if (activeSubcategoryFilters.includes(subcategory.title)) {
        isResourceInActiveSubcategory = true;
      }
    });

    return isResourceInActiveSubcategory;
  };

  const filterResourcesByActiveCategory: (
    resources: IToolboxResource[]
  ) => IToolboxResource[] = (resources) => {
    if (!activeCategoryFilters?.[0]) return resources;

    return resources.filter((resource) => {
      const isResourceCategoryActive = activeCategoryFilters.includes(
        resource.category.title
      );

      if (!isResourceCategoryActive) return false;

      return isResourceInActiveSubcategory(resource);
    });
  };

  const filterResourcesByActiveType: (
    resources: IToolboxResource[]
  ) => IToolboxResource[] = (resources) => {
    if (!activeTypeFilters?.[0]) return resources;

    return resources.filter((resource) =>
      activeTypeFilters.includes(resource.type.title)
    );
  };

  const getFilteredResources = () => {
    if (!areActiveFilters) return toolboxResources;

    const resourcesMatchingCategoryFilters =
      filterResourcesByActiveCategory(toolboxResources);

    const resourcesMatchingTypeFilters = filterResourcesByActiveType(
      resourcesMatchingCategoryFilters
    );

    return resourcesMatchingTypeFilters;
  };
  const filteredResources = getFilteredResources();

  const { smallDesktop } = useBreakpoints();

  const showMoreResults = () => {
    setStaggerTileAnimation(true);
    setNumberOfResultsToDisplay((prev) => prev + RESULTS_PER_PATCH);
  };

  const { inView, ref } = useIntersectionAnimation();

  const calculateDelay = (index: number) => {
    return staggerTileAnimation
      ? (index - (numberOfResultsToDisplay - RESULTS_PER_PATCH)) * 150
      : 0;
  };

  const handleUpdateFilters: React.Dispatch<React.SetStateAction<IFilters>> = (
    setStateAction
  ) => {
    setFilters(setStateAction);
    setStaggerTileAnimation(false);
  };

  return (
    <>
      <SlicePadding>
        <div className={styles.headerSection}>
          <h2 className={cn("h2", styles.title)}>{header}</h2>
          <p className={cn("h3", styles.subheader)}>{subheader}</p>
        </div>

        <div className={styles.filterButtonContainer}>
          <Button onClick={() => setIsMobileFilterMenuOpen(true)} fluid>
            Filter
          </Button>
        </div>

        <div className={styles.layoutGrid}>
          <div>
            <Filters
              setFilters={handleUpdateFilters}
              filters={filters}
              className={styles.filters}
            />
          </div>

          <div>
            <div ref={ref} className={styles.cardGrid}>
              {filteredResources
                .slice(0, numberOfResultsToDisplay)
                .map((resource, i) => (
                  <IntersectionAnimation
                    animation="fadeGrow"
                    delay={calculateDelay(i)}
                    key={resource._id}
                    trigger={inView}
                  >
                    <ResourceCard resource={resource} />
                  </IntersectionAnimation>
                ))}
            </div>

            <div className={styles.loadButtonContainer}>
              {numberOfResultsToDisplay < filteredResources.length && (
                <Button
                  onClick={showMoreResults}
                  className={styles.loadMoreButton}
                  fluid={!smallDesktop}
                >
                  Load More
                </Button>
              )}

              <p className={cn("b2", styles.resultsSummary)}>
                Displaying{" "}
                {Math.min(numberOfResultsToDisplay, filteredResources.length)}{" "}
                of {filteredResources.length} results
              </p>
            </div>
          </div>
        </div>
      </SlicePadding>

      <MobileFilterMenu
        setIsOpen={setIsMobileFilterMenuOpen}
        isOpen={isMobileFilteMenuOpen}
        filters={filters}
        setFilters={handleUpdateFilters}
      />
    </>
  );
};

export default ToolboxResources;

const query = graphql`
  query ToolboxResourcesQuery {
    allSanityToolboxResource(sort: { orderRank: ASC }) {
      nodes {
        ...ToolboxResourceFragment
      }
    }
  }
`;
