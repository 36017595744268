// extracted by mini-css-extract-plugin
export var accordionContent = "styles-module--accordionContent--c3786";
export var active = "styles-module--active--8b287";
export var arrowCircleIcon = "styles-module--arrowCircleIcon--db13c";
export var arrowIconContainer = "styles-module--arrowIconContainer--cec11";
export var carousel = "styles-module--carousel--f2ab6";
export var carouselContainer = "styles-module--carouselContainer--047d3";
export var carouselContent = "styles-module--carouselContent--0283a";
export var carouselSlide = "styles-module--carouselSlide--5a39b";
export var container = "styles-module--container--ba160";
export var desktop = "1340px";
export var flexContainer = "styles-module--flexContainer--48883";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var navButton = "styles-module--navButton--4fe0f";
export var navButtons = "styles-module--navButtons--d7c24";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--84816";
export var textAccordion = "styles-module--textAccordion--f5324";
export var textSection = "styles-module--textSection--c56aa";