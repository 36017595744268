import React, { useEffect, useState } from "react";
import { Swiper as SwiperType } from "swiper";
import * as styles from "./styles.module.scss";

interface IProps {
  swiperRef: React.MutableRefObject<SwiperType | null>;
  numberOfSlides: number;
}

const PaginationTimer = ({ swiperRef, numberOfSlides }: IProps) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [currentSlidePercentage, setCurrentSlidePercentage] = useState(0);
  const [key, setKey] = useState(0); // This key is used to prevent width transition animation when not wanted

  const updateKey = () => {
    setKey((prev) => (prev === 0 ? 1 : 0));
  };

  const goToNextSlide = () => {
    updateKey();
    setActiveSlideIndex((prev) => {
      if (prev === numberOfSlides - 1) {
        return 0;
      }

      return prev + 1;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlidePercentage((prev) => {
        if (prev === 100) {
          goToNextSlide();
          return 0;
        }
        return prev + 10;
      });
    }, 300); // Keep this in sync with the transition time

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    swiperRef.current?.slideTo(activeSlideIndex);
  }, [activeSlideIndex]);

  useEffect(() => {
    setActiveSlideIndex(swiperRef.current?.activeIndex || 0);
    setCurrentSlidePercentage(0);
    updateKey();
  }, [swiperRef.current?.activeIndex]);

  const getWidth = (correspondingSlideIndex: number) => {
    if (activeSlideIndex < correspondingSlideIndex) {
      return `0%`;
    }

    if (activeSlideIndex > correspondingSlideIndex) {
      return `100%`;
    }

    return `${currentSlidePercentage}%`;
  };

  return (
    <div className={styles.container} key={key}>
      <div className={styles.timerLine}>
        <div className={styles.fill} style={{ width: getWidth(0) }} />
      </div>
      <div className={styles.timerLine}>
        <div className={styles.fill} style={{ width: getWidth(1) }} />
      </div>
      <div className={styles.timerLine}>
        <div className={styles.fill} style={{ width: getWidth(2) }} />
      </div>
      <div className={styles.timerLine}>
        <div className={styles.fill} style={{ width: getWidth(3) }} />
      </div>
    </div>
  );
};

export default PaginationTimer;
