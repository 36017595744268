// extracted by mini-css-extract-plugin
export var container = "styles-module--container--d4ab2";
export var desktop = "1340px";
export var giant = "2200px";
export var graphic = "styles-module--graphic--2b38c";
export var grid = "styles-module--grid--f2fd3";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var quote = "styles-module--quote--a8cdd";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--cfa03";