import React, { useEffect, useRef, useState } from "react";
import { SVG } from "~components";
import cn from "classnames";
import type { ITalliedItem } from "../../../../index";
import * as styles from "./styles.module.scss";
import CheckboxFilterItem from "./components/CheckboxFilterItem";
import { IPortfolioItem } from "~schemas";

export interface IProps {
  allPortfolioItems: IPortfolioItem[];
  numberOfFilteredItems: number;
  filterOptions: ITalliedItem[];
  activeCategories: string[];
  onClickCategory: (category?: string) => void;
}

const CheckboxFilter = ({
  filterOptions,
  activeCategories,
  onClickCategory,
  allPortfolioItems,
  numberOfFilteredItems
}: IProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dropdownContentHeight, setDropdownContentHeight] = useState(0);

  const dropdownRef = useRef<HTMLUListElement>(null);

  const calculateContentHeight = () => {
    const height = dropdownRef.current?.offsetHeight || 0;
    const MAX_DROPDOWN_HEIGHT_PX = 300;
    const newDropdownHeight = Math.min(height, MAX_DROPDOWN_HEIGHT_PX);
    setDropdownContentHeight(newDropdownHeight);
  };

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  const isFilteringEnabled = activeCategories.length > 0;

  const selectedCategoryName = isFilteringEnabled ? `Selected` : `All`;
  const selectedCategoryTally = isFilteringEnabled
    ? numberOfFilteredItems
    : allPortfolioItems.length;

  const numberOfExitedItems = allPortfolioItems.filter(
    ({ exit }) => exit
  ).length;

  return (
    <>
      <button
        className={styles.dropdownButton}
        onClick={() => setIsFilterOpen((prev) => !prev)}
      >
        <div className={cn(`button-text`, styles.filterLabel)}>Filter</div>

        <div className={styles.selectedFilter}>
          <span className="button-text">{`${selectedCategoryName} (${selectedCategoryTally})`}</span>
          <SVG
            svg="chevronDown"
            className={cn(styles.chevronIcon, {
              [styles.active]: isFilterOpen
            })}
          />
        </div>
      </button>

      <div className={styles.dropdownContainer}>
        <div className={styles.dropdown}>
          <div
            className={styles.dropdownContent}
            style={{
              height: `${isFilterOpen ? dropdownContentHeight : 0}px`
            }}
          >
            <ul ref={dropdownRef}>
              <li className={styles.dropdownListItem}>
                <CheckboxFilterItem
                  active={!isFilteringEnabled}
                  text="All"
                  onClick={() => onClickCategory()}
                  numberOfItems={allPortfolioItems.length}
                />
              </li>

              <li className={styles.dropdownListItem}>
                <CheckboxFilterItem
                  active={activeCategories.includes(`exit`)}
                  text="Exit"
                  onClick={() => onClickCategory(`exit`)}
                  numberOfItems={numberOfExitedItems}
                />
              </li>

              {filterOptions.map(({ name, tally }, i) => {
                const isActive = activeCategories.includes(name);

                return (
                  <li key={i} className={styles.dropdownListItem}>
                    <CheckboxFilterItem
                      active={isActive}
                      text={name}
                      onClick={() => onClickCategory(name)}
                      numberOfItems={tally}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckboxFilter;
