import React from "react";
import { IFounderProfiles } from "~schemas";
import FounderProfilesDesktop from "./components/Desktop";
import FounderProfilesMobile from "./components/Mobile";
import { useBreakpoints } from "~hooks";

interface IProps {
  data: IFounderProfiles;
}

const FounderProfiles = ({ data: { profile1, profile2 } }: IProps) => {
  const { largeTablet } = useBreakpoints();

  return (
    <>
      {largeTablet && (
        <FounderProfilesDesktop profile1={profile1} profile2={profile2} />
      )}

      {!largeTablet && (
        <FounderProfilesMobile profile1={profile1} profile2={profile2} />
      )}
    </>
  );
};

export default FounderProfiles;
