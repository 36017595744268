import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.scss";
import { IScratchie, IScratchieQuote } from "~schemas";
import cn from "classnames";
import { useBreakpoints } from "~hooks";
import { SliceConfig } from "~components";

interface IProps {
  data: IScratchie;
}

const Scratchie = ({ data: { quotes } }: IProps) => {
  const [hasTapped, setHasTapped] = useState(false);
  const [hasMouseEntered, setHasMouseEntered] = useState(false);

  const { smallTablet } = useBreakpoints();

  const getRandomQuote: () => IScratchieQuote = () => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  };

  const randomQuoteRef = useRef<IScratchieQuote>(getRandomQuote());

  const { attribution, quote } = randomQuoteRef.current;

  const quoteNumber = quotes.indexOf(randomQuoteRef.current) + 1;

  const gridColumns = styles.columns;
  const gridRows = styles.rows;
  const gridSlots = gridColumns * gridRows;
  const gridSlotArray = [...Array(gridSlots)];

  const scratchieTileClassName = `scratchie-tile`;
  const fallenTileClassName = `fallen`;

  const handleMouseEnter: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!smallTablet) return;

    setHasMouseEntered(true);

    const tileDiv = e.target as HTMLDivElement;
    tileDiv.style.transition = `transform 2s, opacity 2s, box-shadow 0.5s`;
    tileDiv.style.transform = `translateY(800%) rotate(360deg) `;
    tileDiv.style.pointerEvents = `none`;
    tileDiv.style.opacity = `0.4`;
    tileDiv.style.boxShadow = `0 0 0px 5px rgba(0, 0, 0, 0.1)`;
    tileDiv.classList.add(fallenTileClassName);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  // Kinda complex but basically just a recursive to make all the tiles fall down
  // Tried to achieve using transition-delay but caused weird visual issues
  useEffect(() => {
    if (!hasTapped) return;

    const tiles = containerRef.current?.querySelectorAll<HTMLElement>(
      `.${scratchieTileClassName}`
    );

    const makeTilesFall = (tileIndex = 0, skippedTiles = 0) => {
      if (!tiles?.[tileIndex]) return;

      const tile = tiles[tileIndex];

      if (tile.classList.contains(fallenTileClassName)) {
        makeTilesFall(tileIndex + 1, skippedTiles + 1);
        return;
      }

      const tileTransitionDelay = (tileIndex - skippedTiles) * 50;

      setTimeout(() => {
        tile.style.transition = `transform 2s, opacity 2s, box-shadow 0.5s`;
        tile.style.transform = `translateY(800%) rotate(360deg) `;
        tile.style.pointerEvents = `none`;
        tile.style.opacity = `0.4`;
        tile.style.boxShadow = `0 0 0px 5px rgba(0, 0, 0, 0.1)`;
      }, tileTransitionDelay);

      makeTilesFall(tileIndex + 1, skippedTiles);
    };

    makeTilesFall();
  }, [hasTapped]);

  const padNumber = (number: number) => {
    return number.toString().padStart(2, "0");
  };

  return (
    <div
      ref={containerRef}
      className={styles.container}
      onClick={() => setHasTapped(true)}
    >
      <SliceConfig
        config={{
          slicePadding: { paddingTop: "small", paddingBottom: "none" }
        }}
      >
        <div className={cn(`h4`, styles.topLine)}>
          <h2>Awesome Excerpt</h2>
          <div>#{padNumber(quoteNumber)}</div>
        </div>
      </SliceConfig>

      <div className={styles.barrier}>
        {gridSlotArray.map((_e, i) => (
          <div
            onMouseEnter={handleMouseEnter}
            className={cn(scratchieTileClassName, styles.tile)}
            key={i}
          />
        ))}
      </div>

      <div
        className={cn(styles.tapGifContainer, {
          [styles.hidden]: hasTapped || hasMouseEntered
        })}
      >
        <img
          className={styles.tapGif}
          src={"/images/tap-prompt.gif"}
          alt="Tap prompt animation"
        />
        <div className="h2">Tap to reveal</div>
      </div>

      <SliceConfig
        config={{
          slicePadding: { paddingTop: "none", paddingBottom: "small" }
        }}
      >
        <p className={cn(`d2`, styles.quote)}>{quote}</p>
        <div className="h4">– {attribution}</div>
      </SliceConfig>
    </div>
  );
};

export default Scratchie;
