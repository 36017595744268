// extracted by mini-css-extract-plugin
export var blockGraphic = "styles-module--blockGraphic--4e361";
export var bottomLeft = "styles-module--bottomLeft--b9ae8";
export var bottomMiddle = "styles-module--bottomMiddle--bf803";
export var bottomRight = "styles-module--bottomRight--9524c";
export var content = "styles-module--content--29b8e";
export var desktop = "1340px";
export var giant = "2200px";
export var icon = "styles-module--icon--123c8";
export var iconContainer = "styles-module--iconContainer--b09fb";
export var image = "styles-module--image--af19e";
export var imageContainer = "styles-module--imageContainer--7a9da";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var linkTile = "styles-module--linkTile--385a6";
export var middleLeft = "styles-module--middleLeft--776be";
export var middleRight = "styles-module--middleRight--3e27a";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheading = "styles-module--subheading--3a828";
export var tablet = "769px";
export var text = "styles-module--text--9c37b";
export var topLeft = "styles-module--topLeft--9f2d1";