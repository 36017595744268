import React, { CSSProperties } from "react";
import * as styles from "./styles.module.scss";
import { TProfileVariant } from "../ProfileBlock";
import { IntersectionAnimation } from "~components";
import cn from "classnames";
import { SlicePadding } from "~components";

interface IProps {
  variant?: TProfileVariant;
  color?: string;
  name: string;
  position: string;
  triggerAnimation: boolean;
}

interface ITextContent {
  name: string;
  position: string;
  triggerAnimation: boolean;
}

const TextContent = ({ name, position, triggerAnimation }: ITextContent) => {
  return (
    <SlicePadding xOnly className={styles.textPadding}>
      <IntersectionAnimation trigger={triggerAnimation} delay={0}>
        <div className={cn(`b2`, styles.position)}>{position}</div>
      </IntersectionAnimation>
      <IntersectionAnimation trigger={triggerAnimation} delay={150}>
        <h3 className="h2">{name}</h3>
      </IntersectionAnimation>
    </SlicePadding>
  );
};

const OverlayGraphic = ({
  color,
  variant,
  name,
  position,
  triggerAnimation
}: IProps) => {
  const backgroundStyle: CSSProperties = { backgroundColor: color };

  return (
    <div
      className={cn(styles.container, {
        [styles.variant1]: variant === 1,
        [styles.variant2]: variant === 2
      })}
    >
      {variant === 1 && (
        <>
          <div />
          <div style={backgroundStyle} />
          <div />
          <div style={backgroundStyle} />
          <div />
          <div style={backgroundStyle} />
          <div style={backgroundStyle} className={styles.textContent}>
            <TextContent
              triggerAnimation={triggerAnimation}
              name={name}
              position={position}
            />
          </div>
        </>
      )}

      {variant === 2 && (
        <>
          <div />
          <div style={backgroundStyle} />
          <div style={backgroundStyle} className={styles.textContent}>
            <TextContent
              triggerAnimation={triggerAnimation}
              name={name}
              position={position}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OverlayGraphic;
