import {useState, useEffect, useRef} from 'react';
import {useDisableFocus} from '~hooks';

interface IOptions {
  openByDefault?: boolean;
}

const useAccordion = (options?: IOptions) => {
  const [contentHeight, setContentHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(options?.openByDefault || false);

  const contentRef = useRef<HTMLDivElement>(null);

  const calculateContentHeight = () => {
    const height = contentRef.current?.offsetHeight || 0;
    setContentHeight(height);
  };

  useDisableFocus(!isExpanded, contentRef);

  useEffect(() => {
    // Sometimes calculation is wrong without timeout
    setTimeout(() => {
      calculateContentHeight();
    }, 200);

    window.addEventListener('resize', calculateContentHeight);
    return () => window.removeEventListener('resize', calculateContentHeight);
  }, []);

  useEffect(() => {
    if (isExpanded) {
      calculateContentHeight();
    }
  }, [isExpanded]);

  const containerHeight = isExpanded ? contentHeight : 0;

  return {
    contentRef,
    containerHeight,
    isExpanded,
    setIsExpanded,
    calculateContentHeight,
  };
};

export default useAccordion;
