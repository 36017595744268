import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {}

const Graphic7 = (props: IProps) => {
  return (
    <>
      <div className={cn(styles.shape, styles.shape1)} />
      <div className={cn(styles.shape, styles.shape2)} />
      <div className={cn(styles.shape, styles.shape3)} />
      <div className={cn(styles.shape, styles.shape4)} />
    </>
  );
};

export default Graphic7;
