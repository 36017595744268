import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { formatNumberWithCommas } from "~utils";

interface IProps {
  number: number;
  className?: string;
  preNumberText?: string;
  postNumberText?: string;
  showPlus?: boolean;
  delayMs?: number;
}

const CountUp = ({
  number,
  className,
  showPlus,
  postNumberText,
  preNumberText,
  delayMs
}: IProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true
  });

  const [displayNumber, setDisplayNumber] = useState(0);
  const [isPlusVisible, setIsPlusVisible] = useState(false);

  const { number: animatedNumber } = useSpring({
    from: { number: 0 },
    number: displayNumber,
    config: { friction: 50, tension: 200 },
    onRest: () => setIsPlusVisible(true)
  });

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setDisplayNumber(number);
      }, delayMs || 0);
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <div className={cn(className, styles.number)}>
        {preNumberText && <span>{preNumberText}</span>}

        <animated.div>
          {animatedNumber.to((n) => formatNumberWithCommas(n.toFixed(0)))}
        </animated.div>

        {postNumberText && <span>{postNumberText}</span>}

        {showPlus && (
          <span
            className={cn(styles.plus, { [styles.visible]: isPlusVisible })}
          >
            +
          </span>
        )}
      </div>
    </div>
  );
};

export default CountUp;
