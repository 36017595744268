import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  words: string[];
  direction?: "up" | "down";
  className?: string;
}

const WordScroller = ({ words, direction = "down", className }: IProps) => {
  const doubleWords = [...words, ...words, ...words];

  return (
    <div className={cn(styles.container, className)}>
      <div
        className={cn(styles.words, {
          [styles.down]: direction === "down",
          [styles.up]: direction === "up"
        })}
      >
        {doubleWords.map((word, i) => (
          <div className={cn(`d2`, styles.word)} key={i}>
            {word}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WordScroller;
