import React, { useEffect, useRef, useState } from "react";
import { SVG } from "~components";
import cn from "classnames";
import type { ICategoryWithTally, TFilterCategory } from "../../../../index";
import * as styles from "./styles.module.scss";
import { useDisableFocus } from "~hooks";

export interface IProps {
  categories: ICategoryWithTally[];
  selectedCategory: TFilterCategory;
  setSelectedCategory: React.Dispatch<React.SetStateAction<TFilterCategory>>;
}

const Filter = ({
  selectedCategory,
  categories,
  setSelectedCategory
}: IProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dropdownContentHeight, setDropdownContentHeight] = useState(0);

  const dropdownRef = useRef<HTMLUListElement>(
    null
  ) as React.MutableRefObject<HTMLUListElement>;

  const calculateContentHeight = () => {
    const height = dropdownRef.current?.offsetHeight || 0;
    setDropdownContentHeight(height);
  };

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  const handleClickFilterItem = (newActiveCategory: TFilterCategory) => {
    setIsFilterOpen(false);
    setSelectedCategory(newActiveCategory);
  };

  const totalNumberOfLinks = categories.reduce(
    (acc, category) => acc + category.tally,
    0
  );

  const selectedCategoryName = selectedCategory?.name || "All";
  const selectedCategoryTally = selectedCategory?.tally || totalNumberOfLinks;

  useDisableFocus(!isFilterOpen, dropdownRef);

  return (
    <>
      <button
        className={styles.dropdownButton}
        onClick={() => setIsFilterOpen((prev) => !prev)}
      >
        <div className={cn(`button-text`, styles.filterLabel)}>Filter</div>

        <div className={styles.selectedFilter}>
          <span className="button-text">{`${selectedCategoryName} (${selectedCategoryTally})`}</span>
          <SVG
            svg="chevronDown"
            className={cn(styles.chevronIcon, {
              [styles.active]: isFilterOpen
            })}
          />
        </div>
      </button>

      <div className={styles.dropdownContainer}>
        <div className={styles.dropdown}>
          <div
            className={styles.dropdownContent}
            style={{
              height: `${isFilterOpen ? dropdownContentHeight : 0}px`
            }}
          >
            <ul ref={dropdownRef}>
              <li className={styles.dropdownListItem}>
                <button
                  className={styles.categoryButton}
                  onClick={() => handleClickFilterItem(null)}
                >
                  All
                </button>
              </li>

              {categories.map((category, i) => (
                <li key={i} className={styles.dropdownListItem}>
                  <button
                    className={styles.categoryButton}
                    onClick={() => handleClickFilterItem(category)}
                  >
                    {category.name}
                    {` `}({category.tally})
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
