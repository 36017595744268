// extracted by mini-css-extract-plugin
export var arrowIcon = "styles-module--arrowIcon--98153";
export var desktop = "1340px";
export var giant = "2200px";
export var graphic = "styles-module--graphic--86dc2";
export var graphicContainer = "styles-module--graphicContainer--9acb7";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var linkContainer = "styles-module--linkContainer--e5965";
export var linkTextContainer = "styles-module--linkTextContainer--41f1b";
export var mobile = "400px";
export var number = "styles-module--number--147ce";
export var numberAndGraphic = "styles-module--numberAndGraphic--14159";
export var padding = "styles-module--padding--df5d1";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--96dab";