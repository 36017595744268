import React, { useState } from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { CountUp } from "~components";
import { IStatistic } from "~schemas";
import { useBreakpoints } from "~hooks";

interface IProps {
  stat: IStatistic;
  disableProportions: boolean;
  index: number;
  hasHeroStatistic: boolean;
}

const BreadkdownItem = ({
  disableProportions,
  stat,
  index,
  hasHeroStatistic
}: IProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const { largeDesktop } = useBreakpoints();
  const largeTextTypestyle = largeDesktop ? "d2" : "h1";

  const getStatOpacity: (index: number) => number = (index) => {
    switch (index) {
      case 0:
        return 0.8;
      case 1:
        return 0.7;
      case 2:
        return 0.6;
      case 3:
        return 0.5;
      default:
        return 1;
    }
  };

  const getStatFlex: (value: string) => number = (value) => {
    if (disableProportions) return 1;

    // remove all non-numeric characters from string
    const numericValue = value.replace(/\D/g, "");
    const parsedValue = parseInt(numericValue);

    return isHovered ? parsedValue + 10 : parsedValue;
  };

  const { title, value } = stat;

  // remove all non-numeric characters from string
  const numericValue = value.replace(/\D/g, "");
  const parsedValue = parseInt(numericValue);

  // get the non-numeric characters
  const nonNumericValue = value.replace(/\d/g, "");

  const largeTextMinWidth = largeDesktop ? 200 : 120;

  const minWidth = hasHeroStatistic ? 120 : largeTextMinWidth;

  return (
    <div
      className={styles.statItem}
      style={{
        flex: getStatFlex(value),
        minWidth
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={styles.background}
        style={{
          opacity: getStatOpacity(index)
        }}
      />

      <div className={styles.statText}>
        <h3 className={cn("b1", styles.statTitle)}>{title}</h3>

        {hasHeroStatistic ? (
          <div className={cn("h2")}>{value}</div>
        ) : (
          <CountUp
            number={parsedValue}
            className={largeTextTypestyle}
            postNumberText={nonNumericValue}
            delayMs={index * 200}
          />
        )}
      </div>
    </div>
  );
};

export default BreadkdownItem;
