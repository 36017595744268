import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import * as styles from "./styles.module.scss";

const FooterPortal = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  const calculateContainerSize = () => {
    const container = containerRef.current;
    if (!container) return;

    const { width, height } = container.getBoundingClientRect();
    setContainerSize({ width, height });
  };

  // Container size
  useEffect(() => {
    window.addEventListener(`resize`, calculateContainerSize);
    setTimeout(calculateContainerSize, 100); // Don't know why but height is wrong without timeout

    return () => {
      window.removeEventListener(`resize`, calculateContainerSize);
    };
  }, []);

  // Canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container) return;

    // Boilerplate
    const scene = new THREE.Scene();

    const camera = new THREE.PerspectiveCamera(
      75,
      containerSize.width / containerSize.height,
      0.1,
      100
    );
    camera.position.z = 5;
    scene.add(camera);

    // Renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
      precision: `lowp`
    });
    renderer.setSize(containerSize.width, containerSize.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Create portal
    const colors = [
      `#5FFF95`,
      `#77007C`,
      `#FF8731`,
      `#FFABAB`,
      `#FFDE31`,
      `#007F4F`,
      `#50DBEE`,
      `#6E3E20`,
      `#C3423D`
    ]; // Order is first to last

    const circleRefs: THREE.Mesh<
      THREE.CircleGeometry,
      THREE.MeshBasicMaterial
    >[] = [];

    const geometry = new THREE.CircleGeometry(10, 30);

    for (let i = 0; i < colors.length; i++) {
      const material = new THREE.MeshBasicMaterial({ color: colors[i] });
      const circle = new THREE.Mesh(geometry, material);
      circle.position.z = i * -0.1;
      circleRefs.push(circle);
      scene.add(circle);
    }

    circleRefs.reverse();

    // Animation
    const clock = new THREE.Clock();

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      const DELAY = 0.5;
      const SIZE_MULTIPLIER = 0.75;

      for (let i = 0; i < circleRefs.length; i++) {
        const calculatedScale = (elapsedTime - DELAY * i) * SIZE_MULTIPLIER;

        let scale = 0;

        if (calculatedScale > 0) {
          scale = Math.min(calculatedScale, 2); // Optimisation, don't grow larger than needed to cover canvas
        }

        const circle = circleRefs[i];
        circle.scale.x = scale;
        circle.scale.y = scale;
      }

      renderer.render(scene, camera);

      window.requestAnimationFrame(tick);
    };

    tick();

    // Resize
    const calculateCanvasSizes = () => {
      camera.aspect = containerSize.width / containerSize.height;
      camera.updateProjectionMatrix();

      renderer.setSize(containerSize.width, containerSize.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    calculateCanvasSizes();
    window.addEventListener(`resize`, calculateCanvasSizes);

    return () => window.removeEventListener(`resize`, calculateCanvasSizes);
  }, [containerSize.height, containerSize.width]);

  return (
    <div className={styles.container} ref={containerRef}>
      <canvas className={styles.canvas} ref={canvasRef} />
    </div>
  );
};

export default FooterPortal;
