// extracted by mini-css-extract-plugin
export var active = "styles-module--active--e4df4";
export var container = "styles-module--container--3af31";
export var content = "styles-module--content--bbe70";
export var contentContainer = "styles-module--contentContainer--ae290";
export var enableAnimation = "styles-module--enableAnimation--1bbd8";
export var leftColumn = "styles-module--leftColumn--52420";
export var link = "styles-module--link--16b20";
export var linkIcon = "styles-module--linkIcon--f9eda";
export var quadrantPatternContainer = "styles-module--quadrantPatternContainer--0234d";
export var rightColumn = "styles-module--rightColumn--23629";
export var subheading = "styles-module--subheading--859cf";