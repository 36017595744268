import React from "react";
import { ICaseStudyDetails } from "~schemas";
import { Grid, IntersectionAnimation, SliceConfig } from "~components";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

interface IProps extends ICaseStudyDetails {}

const CaseStudyDetails = ({
  placeFounded,
  founders,
  valuation,
  yearFounded
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <div ref={ref} className={styles.container}>
      <SliceConfig>
        <Grid className={styles.grid}>
          <IntersectionAnimation
            className={styles.info}
            trigger={inView}
            delay={100}
          >
            <h4 className={cn(`caption`, styles.label)}>Year Founded</h4>
            <div className="h2">{yearFounded}</div>
          </IntersectionAnimation>

          {valuation && (
            <IntersectionAnimation
              className={styles.info}
              trigger={inView}
              delay={250}
            >
              <h4 className={cn(`caption`, styles.label)}>Valuation</h4>
              <div className="h2">{valuation}</div>
            </IntersectionAnimation>
          )}

          <IntersectionAnimation
            className={styles.info}
            trigger={inView}
            delay={400}
          >
            <h4 className={cn(`caption`, styles.label)}>Place Founded</h4>
            <div className="h2">{placeFounded}</div>
          </IntersectionAnimation>

          <IntersectionAnimation
            className={cn(styles.founders)}
            trigger={inView}
            delay={550}
          >
            <h4 className={cn(`caption`, styles.label)}>Founders</h4>
            <ul>
              {founders.map((founder, i) => (
                <li key={i} className={styles.founderListItem}>
                  <IntersectionAnimation
                    trigger={inView}
                    delay={700 + (i + 1) * 100}
                    className={styles.founder}
                  >
                    {founder.image && (
                      <GatsbyImage
                        image={founder.image.asset.gatsbyImageData}
                        alt={founder.name}
                        style={{
                          width: 43,
                          height: 43,
                          marginRight: `0.75rem`
                        }}
                      />
                    )}
                    <div className={cn(`button-text`, styles.founderName)}>
                      {founder.name}
                    </div>
                  </IntersectionAnimation>
                </li>
              ))}
            </ul>
          </IntersectionAnimation>
        </Grid>
      </SliceConfig>
    </div>
  );
};

export default CaseStudyDetails;
