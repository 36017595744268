import React, { CSSProperties } from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  color: string;
  className?: string;
}

const QuoteGraphic = ({ color, className }: IProps) => {
  const colorStyle: CSSProperties = {
    background: color
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.grid}>
        <div style={colorStyle} />
        <div style={colorStyle} />
        <div />
        <div style={colorStyle} />
        <div style={colorStyle} />

        <div />
        <div style={colorStyle} />
        <div />
        <div />
        <div style={colorStyle} />

        <div style={colorStyle} />
        <div />
        <div />
        <div style={colorStyle} />
        <div />
      </div>
    </div>
  );
};

export default QuoteGraphic;
