// extracted by mini-css-extract-plugin
export var abc = "styles-module--abc--9071b";
export var buttonCaption = "styles-module--buttonCaption--85235";
export var desktop = "1340px";
export var fileUpload = "styles-module--fileUpload--56eb2";
export var form = "styles-module--form--08dd0";
export var giant = "2200px";
export var inlineFields = "styles-module--inlineFields--27b44";
export var input = "styles-module--input--48d55";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var submitButton = "styles-module--submitButton--feda0";
export var tablet = "769px";
export var text = "styles-module--text--6ce7e";
export var visible = "styles-module--visible--e0872";