import React from "react";
import * as styles from "./styles.module.scss";
import { IFounderLink } from "~schemas";
import { SVG, SlicePadding } from "~components";
import cn from "classnames";
import { useBreakpoints } from "~hooks";

export interface IProps extends IFounderLink {
  isVisible: boolean;
}

const FounderLink = ({ caption, title, url, isVisible }: IProps) => {
  const { largeTablet } = useBreakpoints();

  return (
    <a
      tabIndex={isVisible ? 0 : -1}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      className={styles.link}
    >
      <SlicePadding xOnly className={styles.linkContent}>
        <div>
          <div className={cn(`caption`, styles.caption)}>{caption}</div>
          <span className={largeTablet ? `h4` : `b2`}>{title}</span>
        </div>
        <SVG svg="arrowRightCircle" className={styles.linkIcon} />
      </SlicePadding>
    </a>
  );
};

export default FounderLink;
