import React from "react";
import * as styles from "./styles.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { IPortfolioItem } from "~schemas";
import { Link } from "gatsby";
import cn from "classnames";
import { SVG } from "~components";
import { useBreakpoints } from "~hooks";

interface IProps {
  item: IPortfolioItem;
  index: number;
}

const PortfolioItem = ({
  item: { logo, title, link: linkArray, category, type },
  index
}: IProps) => {
  const link = linkArray?.[0];

  const getHoverColor = () => {
    const hoverColors = [
      `--color-red`,
      `--color-sky-blue`,
      `--color-forest-green`,
      `--color-construction-orange`,
      `--color-dark-blue`,
      `--color-teal`,
      `--color-dark-purple`
    ];

    return hoverColors[index % hoverColors.length];
  };

  const hoverStyles: any = {
    "--hover-color": `var(${getHoverColor()})`
  };

  const hoverText =
    link?._type === `caseStudyReference` ? `Read story` : `Learn more`;

  const label = category || type;

  const { largeTablet } = useBreakpoints();
  const labelTypestyle = largeTablet ? `button-text` : `caption`;

  const content = (
    <div style={hoverStyles} className={styles.container}>
      <div className={styles.content}>
        <GatsbyImage alt={`${title} logo`} image={logo.asset.gatsbyImageData} />
      </div>

      <div className={styles.labelAndLink}>
        <div className={cn(labelTypestyle, styles.label)}>{label}</div>

        {link && (
          <div className={cn(`button-text`, styles.linkBadge)}>
            <span className={styles.linkText}>{hoverText}</span>
            <SVG svg="arrowRight" className={styles.arrowIcon} />
          </div>
        )}
      </div>
    </div>
  );

  if (!link) {
    return content;
  }

  // Todo - use Universal Link
  if (link._type === `caseStudyReference`) {
    return (
      <Link to={`/awesome-stories/${link.caseStudy.slug.current}`}>
        {content}
      </Link>
    );
  }

  if (link._type === `externalUrl`) {
    return (
      <a href={link.url} target="_blank" rel="noreferrer noopener">
        {content}
      </a>
    );
  }

  return null; // Shouldn't ever reach this
};

export default PortfolioItem;
