import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  active?: boolean;
}

const NetworkLetter1A = ({ active }: IProps) => {
  return (
    <div className={cn(styles.container, { [styles.active]: active })}>
      <div className={cn(styles.row, styles.row1)}>
        <div className={cn(styles.column, styles.column1)} />
        <div className={cn(styles.column, styles.column2, styles.filled)} />
        <div className={cn(styles.column, styles.column3)} />
      </div>
      <div className={cn(styles.row, styles.row2)}>
        <div className={cn(styles.column, styles.column1, styles.filled)} />
        <div className={cn(styles.column, styles.column2)} />
        <div className={cn(styles.column, styles.column3, styles.filled)} />
      </div>
      <div className={cn(styles.row, styles.row3)}>
        <div className={cn(styles.column, styles.column1, styles.filled)} />
        <div className={cn(styles.column, styles.column2, styles.filled)} />
        <div className={cn(styles.column, styles.column3, styles.filled)} />
      </div>
      <div className={cn(styles.row, styles.row4)}>
        <div className={cn(styles.column, styles.column1, styles.filled)} />
        <div className={cn(styles.column, styles.column2)} />
        <div className={cn(styles.column, styles.column3, styles.filled)} />
      </div>
    </div>
  );
};

export default NetworkLetter1A;
