import React from "react";

import { ReactComponent as arrowDown } from "~assets/svg/arrow-down.svg";
import { ReactComponent as arrowLeft } from "~assets/svg/arrow-left.svg";
import { ReactComponent as arrowRight } from "~assets/svg/arrow-right.svg";
import { ReactComponent as chevronDown } from "~assets/svg/chevron-down.svg";
import { ReactComponent as chevronLeft } from "~assets/svg/chevron-left.svg";
import { ReactComponent as chevronRight } from "~assets/svg/chevron-right.svg";
import { ReactComponent as lookingGlass } from "~assets/svg/looking-glass.svg";
import { ReactComponent as minus } from "~assets/svg/minus.svg";
import { ReactComponent as plus } from "~assets/svg/plus.svg";
import { ReactComponent as warningTriangle } from "~assets/svg/warning-triangle.svg";
import { ReactComponent as x } from "~assets/svg/x.svg";
import { ReactComponent as arrowRightCircle } from "~assets/svg/arrow-right-circle.svg";
import { ReactComponent as contacts } from "~assets/svg/contacts.svg";
import { ReactComponent as hamburger } from "~assets/svg/hamburger.svg";
import { ReactComponent as pauseCircle } from "~assets/svg/pause-circle.svg";
import { ReactComponent as playCircle } from "~assets/svg/play-circle.svg";
import { ReactComponent as plusCircle } from "~assets/svg/plus-circle.svg";
import { ReactComponent as reset } from "~assets/svg/reset.svg";
import { ReactComponent as triangle } from "~assets/svg/triangle.svg";
import { ReactComponent as wordmark } from "~assets/svg/wordmark.svg";
import { ReactComponent as toolbox } from "~assets/svg/toolbox.svg";
import { ReactComponent as externalLink } from "~assets/svg/external-link.svg";
import { ReactComponent as checkCircle } from "~assets/svg/check-circle.svg";

const svgs = Object.freeze({
  arrowDown,
  arrowLeft,
  arrowRight,
  chevronDown,
  chevronLeft,
  checkCircle,
  externalLink,
  chevronRight,
  lookingGlass,
  minus,
  plus,
  warningTriangle,
  x,
  arrowRightCircle,
  contacts,
  hamburger,
  pauseCircle,
  playCircle,
  plusCircle,
  reset,
  toolbox,
  triangle,
  wordmark
});

export type TSvg = keyof typeof svgs;

interface IProps {
  svg: TSvg;
  className?: string;
  style?: React.CSSProperties;
}

const SVG = ({ svg, ...props }: IProps) => {
  if (!svgs[svg]) return null;

  const SVGElement = svgs[svg];

  return <SVGElement {...props} aria-hidden />;
};

export default SVG;
