// extracted by mini-css-extract-plugin
export var active = "styles-module--active--72ea5";
export var bottom = "styles-module--bottom--b4e35";
export var buttom = "styles-module--buttom--c6afa";
export var col1 = "styles-module--col1--1ea89";
export var col2 = "styles-module--col2--a95a8";
export var col3 = "styles-module--col3--f92e0";
export var col4 = "styles-module--col4--aaa5f";
export var col5 = "styles-module--col5--7547a";
export var container = "styles-module--container--f752b";
export var top = "styles-module--top--9d26d";