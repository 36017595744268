import React from "react";
import {
  IAltImage,
  ICaseStudy,
  ICaseStudyReference,
  IGlobalLink
} from "~schemas";
import AtfLetter1M from "../../../Letters/1M";
import AtfLetter2A from "../../../Letters/2A";
import AtfLetter3K from "../../../Letters/3K";
import AtfLetter4E from "../../../Letters/4E";
import * as styles from "./styles.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { SVG } from "~components";
import { Link } from "gatsby";
import cn from "classnames";

interface IProps {
  contentData: IGlobalLink | ICaseStudy;
  slideIndex: number;
  activeIndex: number;
}

const Slide = ({ contentData, slideIndex, activeIndex }: IProps) => {
  const title = contentData?.title;
  let category = ``;
  let image: IAltImage;
  let link;

  const isCaseStudy = (data: IGlobalLink | ICaseStudy): data is ICaseStudy => {
    return data._type === "caseStudy";
  };

  if (isCaseStudy(contentData)) {
    category = "Case Study";
    image = contentData.linkTileImage as IAltImage;
    const linkData: ICaseStudyReference = {
      _key: contentData.slug.current,
      _type: `caseStudyReference`,
      caseStudy: {
        slug: {
          current: contentData.slug.current
        }
      }
    };

    link = linkData;
  } else {
    category = contentData.category;
    image = contentData.image;
    link = contentData.link[0];
  }

  const getSlideBackgroundColor = (index = slideIndex) => {
    switch (index) {
      case 0:
        return `var(--color-light-pink)`;
      case 1:
        return `var(--color-gold)`;
      case 2:
        return `var(--color-beige)`;
      case 3:
        return `var(--color-construction-orange)`;
      default:
        return `var(--color-light-pink)`;
    }
  };

  let LetterPattern;
  switch (slideIndex) {
    case 0:
      LetterPattern = AtfLetter1M;
      break;
    case 1:
      LetterPattern = AtfLetter2A;
      break;
    case 2:
      LetterPattern = AtfLetter3K;
      break;
    case 3:
      LetterPattern = AtfLetter4E;
      break;

    default:
      LetterPattern = AtfLetter1M;
  }

  return (
    <div
      className={cn(styles.container, {
        [styles.active]: activeIndex === slideIndex
      })}
      style={{ background: getSlideBackgroundColor() }}
    >
      <div className={styles.mainContent}>
        <GatsbyImage
          className={styles.image}
          image={image.asset.gatsbyImageData}
          alt={image.altText || ``}
          style={{ height: `40vh` }}
        />
        <div className={styles.textAndLink}>
          <div className={styles.text}>
            <div className={cn(`b2`, styles.subtitle)}>{category}</div>
            <h3 className={cn(`h2`, styles.title)}>{title}</h3>
          </div>
          {link._type === "externalUrl" && (
            <a
              className={styles.link}
              href={link.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              <SVG className={styles.linkIcon} svg="arrowRightCircle" />
            </a>
          )}
          {link._type === `caseStudyReference` && (
            <Link
              className={styles.link}
              to={`/awesome-stories/${link.caseStudy.slug.current}`}
            >
              <SVG className={styles.linkIcon} svg="arrowRightCircle" />
            </Link>
          )}
        </div>
      </div>
      <div
        className={styles.letterGraphic}
        style={{ background: getSlideBackgroundColor() }}
      >
        <LetterPattern active={activeIndex !== slideIndex} />
      </div>
    </div>
  );
};

export default Slide;
