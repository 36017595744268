// extracted by mini-css-extract-plugin
export var animationDuration = "5000ms";
export var appear = "styles-module--appear--f5e32";
export var appearDown = "styles-module--appear-down--82c3a";
export var appearLeft = "styles-module--appear-left--ffe0b";
export var appearRight = "styles-module--appear-right--15452";
export var appearUp = "styles-module--appear-up--f4751";
export var columns = "10";
export var container = "styles-module--container--0d735";
export var opacityAndPointerEvents = "styles-module--opacity-and-pointer-events--0174a";
export var rows = "12";
export var updatePointerEvents = "styles-module--update-pointer-events--25d85";