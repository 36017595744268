// This has been superceeded by the Typeform slice
// Keeping around just in case

import React, { FormEvent, useState } from "react";
import { IContact } from "~schemas";
import {
  Button,
  Grid,
  PortableTextRenderer,
  SliceConfig,
  TextInput,
  IntersectionAnimation,
  FileUpload
} from "~components";
import cn from "classnames";
import { regex, type TFormField } from "~constants";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import contactFormData from "./contactFormData";
import * as styles from "./styles.module.scss";

interface IProps {
  data: IContact;
}

type TFormErrors = {
  [key in TFormField]?: boolean;
};
type TFormValues = {
  [key in TFormField]?: string;
};

const Contact = ({ data: { _rawText } }: IProps) => {
  const FORMSPARK_ENDPOINT = `https://api.formspark.io`;
  const FORMSPARK_ID = `wLDESgbZ`;

  const formFields: TFormField[] = [
    `name`,
    `email`,
    `how`,
    `message`,
    `honeypot`
  ];

  const initialFormValues: TFormValues = {};
  const initialFormErrors: TFormErrors = {};

  for (const field of formFields) {
    initialFormValues[field] = ``;
    initialFormErrors[field] = false;
  }

  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasErrorSubmitting, setHasErrorSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleUpdateValue = (value: string, formField: TFormField) => {
    setHasErrorSubmitting(false);
    setFormValues((prev) => ({ ...prev, [formField]: value }));
    setFormErrors((prev) => ({ ...prev, [formField]: false }));
  };

  const handleBlur = (field: TFormField) => {
    if (!regex[field]?.test(formValues[field] || ``)) {
      setFormErrors((prev) => ({
        ...prev,
        [field]: true
      }));
    }
  };

  const formHasError = () => {
    const relevantFormFields = formFields.filter(
      (field) => field !== `honeypot`
    );

    for (const field of relevantFormFields) {
      if (!formValues[field]) return true;
      if (formErrors[field]) return true;
    }
    return false;
  };

  const isButtonDisabled = () => {
    if (hasSubmitted || isSubmitting || formHasError()) return true;

    return false;
  };

  const uploadFileToFirebaseAndGetDownloadUrl = async () => {
    const firebaseConfig = {
      apiKey: "AIzaSyChvAjfWaKlfXG2m5gcewUDm95gb-HBE_4",
      authDomain: "eup-file-upload.firebaseapp.com",
      projectId: "eup-file-upload",
      storageBucket: "eup-file-upload.appspot.com",
      messagingSenderId: "804439983402",
      appId: "1:804439983402:web:c0b65a49593579d0ce4a53"
    };

    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);

    return new Promise(async (resolve, reject) => {
      if (file === null) {
        reject(`No file uploaded`);
        return;
      }

      const fileNameWithoutExtension = file.name.replace(`.pdf`, ``);

      try {
        // Upload to firebase + retrieve URL
        const storageRef = ref(
          storage,
          `${fileNameWithoutExtension}-${uuidv4()}`
        );

        await uploadBytes(storageRef, file);

        const downloadUrl = await getDownloadURL(storageRef);
        resolve(downloadUrl);
      } catch (error) {
        console.error(error);
        reject(error);
        setHasErrorSubmitting(true);
        setIsSubmitting(false);
      }
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formHasError()) return;
    if (formValues.honeypot) return;
    setHasErrorSubmitting(false);
    setIsSubmitting(true);

    const messageContent: any = {
      Name: formValues.name,
      Email: formValues.email,
      "What they're working on": formValues.how,
      "What they need": formValues.message
    };

    try {
      if (file) {
        messageContent.Attachment =
          await uploadFileToFirebaseAndGetDownloadUrl();
      }

      const response = await fetch(`${FORMSPARK_ENDPOINT}/${FORMSPARK_ID}`, {
        method: `POST`,
        headers: {
          "Content-Type": `application/json`,
          Accept: `application/json`
        },
        body: JSON.stringify(messageContent)
      });

      const data = await response.json();

      if (data.statusCode === 500) {
        throw new Error(`Something went wrong submitting form: ${data.body}`);
      }

      setHasSubmitted(true);
    } catch (error) {
      console.error(error);
      setHasErrorSubmitting(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const captionText = () => {
    if (hasErrorSubmitting) {
      return `Sorry, something went wrong. Please try again in a few minutes.`;
    }
    if (hasSubmitted) {
      return `Thanks for your message.`;
    }
    return `placeholder`; // To preserve div height and prevent layout shift
  };

  // Character limit hints
  const howFieldHint =
    formValues.how?.length === contactFormData.how.maxLength
      ? `${contactFormData.how.maxLength} character limit`
      : ``;

  const messageFieldHint =
    formValues.message?.length === contactFormData.message.maxLength
      ? `${contactFormData.message.maxLength} character limit`
      : ``;

  const disableFormFields = isSubmitting || hasSubmitted;

  return (
    <SliceConfig>
      <Grid>
        <IntersectionAnimation className={styles.text} delay={150}>
          <PortableTextRenderer rawText={_rawText} textElement="p" />
        </IntersectionAnimation>

        <IntersectionAnimation className={styles.form} delay={300}>
          <form onSubmit={handleSubmit}>
            <div className={styles.inlineFields}>
              <TextInput
                className={styles.input}
                required
                label={contactFormData.name.label}
                placeholder={contactFormData.name.placeholder}
                value={formValues["name"]}
                onChange={(value) => handleUpdateValue(value, "name")}
                hasError={formErrors["name"]}
                onBlur={() => handleBlur("name")}
                disabled={disableFormFields}
              />

              <TextInput
                className={styles.input}
                required
                label={contactFormData.email.label}
                placeholder={contactFormData.email.placeholder}
                value={formValues["email"]}
                onChange={(value) => handleUpdateValue(value, "email")}
                hasError={formErrors["email"]}
                onBlur={() => handleBlur("email")}
                disabled={disableFormFields}
              />
            </div>

            <TextInput
              className={styles.input}
              required
              textarea
              label={contactFormData.how.label}
              placeholder={contactFormData.how.placeholder}
              maxLength={contactFormData.how.maxLength}
              value={formValues["how"]}
              onChange={(value) => handleUpdateValue(value, "how")}
              hasError={formErrors["how"]}
              onBlur={() => handleBlur("how")}
              disabled={disableFormFields}
              hint={howFieldHint}
            />

            <TextInput
              className={styles.input}
              required
              textarea
              label={contactFormData.message.label}
              placeholder={contactFormData.message.placeholder}
              maxLength={contactFormData.message.maxLength}
              value={formValues["message"]}
              onChange={(value) => handleUpdateValue(value, "message")}
              hasError={formErrors["message"]}
              onBlur={() => handleBlur("message")}
              disabled={disableFormFields}
              hint={messageFieldHint}
            />

            <FileUpload
              label="Upload a file (optional)"
              setFile={setFile}
              className={styles.fileUpload}
              disabled={disableFormFields}
            />

            {/* Honeypot */}
            <TextInput
              className={cn(styles.input, styles.abc)}
              label={contactFormData.honeypot.label}
              placeholder={contactFormData.honeypot.placeholder}
              isHoneypot
            />

            <Button
              className={styles.submitButton}
              disabled={isButtonDisabled()}
              buttonType="submit"
              iconRight="arrowRight"
              loading={isSubmitting}
            >
              Submit{" "}
            </Button>

            <p
              aria-hidden={!(hasErrorSubmitting || hasSubmitted)}
              className={cn(`caption`, styles.buttonCaption, {
                [styles.visible]: hasErrorSubmitting || hasSubmitted
              })}
            >
              {captionText()}
            </p>
          </form>
        </IntersectionAnimation>
      </Grid>
    </SliceConfig>
  );
};

export default Contact;
