import * as React from "react";
import AppProvider from "./src/context/AppContext";

// eslint-disable-next-line
export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);

export const onRouteUpdate = ({ location }) => {
  if (!location.hash) return;

  const sectionToScrollTo = document.querySelector(location.hash);

  if (!sectionToScrollTo) return;

  setTimeout(() => {
    sectionToScrollTo.scrollIntoView({
      behavior: `smooth`,
      block: `start`,
      inline: `nearest`,
      offset: -100
    });
  }, 200); // Too prevent instant jumping
};
