import React from "react";
import * as styles from "./styles.module.scss";
import { IFounderProfile } from "~schemas";
import { PortableTextRenderer, SlicePadding } from "~components";
import FounderLink from "../../../FounderLink";
import cn from "classnames";

interface IProps {
  profile: IFounderProfile;
  innerRef: React.RefObject<HTMLDivElement>;
  active: boolean;
}

const BottomContent = ({ profile, innerRef, active }: IProps) => {
  return (
    <div
      className={styles.content}
      style={{
        background: profile.backgroundColor?.hex || `var(--color-white)`,
        color: profile.textColor?.hex || `var(--color-black)`
      }}
    >
      <div ref={innerRef}>
        <SlicePadding config={{ paddingBottom: `small`, paddingTop: `small` }}>
          <PortableTextRenderer
            className={cn(`b1`, styles.bio)}
            rawText={profile._rawBio}
            textElement="p"
          />
        </SlicePadding>
        {profile.links?.[0] && (
          <ul className={styles.linkList}>
            {profile.links.map((link, i) => (
              <li key={i}>
                <FounderLink {...link} isVisible={active} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default BottomContent;
