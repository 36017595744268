// extracted by mini-css-extract-plugin
export var active = "styles-module--active--b688c";
export var bottom = "styles-module--bottom--e62b2";
export var bottomBottom = "styles-module--bottomBottom--8d2ad";
export var bottomTop = "styles-module--bottomTop--2fc92";
export var col1 = "styles-module--col1--2507e";
export var col2 = "styles-module--col2--9d4b6";
export var col3 = "styles-module--col3--52ef2";
export var container = "styles-module--container--7280a";
export var top = "styles-module--top--ae708";
export var topBottom = "styles-module--topBottom--72f71";
export var topTop = "styles-module--topTop--00543";