import React from "react";
import * as styles from "./styles.module.scss";
import { IInvestmentAreas } from "~schemas";
import InvestmentAreaTile from "./components/InvestmentAreaTile";
import { useInView } from "react-intersection-observer";
import { IntersectionAnimation, SliceIntroText } from "~components";

interface IProps {
  data: IInvestmentAreas;
}

const InvestmentAreas = ({ data: { areas, introText } }: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <>
      <SliceIntroText content={introText} />

      <div ref={ref} className={styles.container}>
        {areas?.[0] &&
          areas.map(({ title, graphic: { variation } }, i) => {
            return (
              <IntersectionAnimation
                trigger={inView}
                animation="fadeGrow"
                key={i}
                delay={100 + 150 * i}
              >
                <InvestmentAreaTile area={title} variation={variation} />
              </IntersectionAnimation>
            );
          })}
      </div>
    </>
  );
};

export default InvestmentAreas;
