import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  active: boolean;
}

const AtfLetter2A = ({ active }: IProps) => {
  return (
    <div className={cn(styles.container, { [styles.active]: active })}>
      <div className={styles.top}>
        <div className={styles.topTop}>
          <div className={styles.col1} />
          <div className={styles.col2} />
          <div className={styles.col3} />
        </div>
        <div className={styles.topBottom}>
          <div className={styles.col1} />
          <div className={styles.col2} />
          <div className={styles.col3} />
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomTop}>
          <div className={styles.col1} />
          <div className={styles.col2} />
          <div className={styles.col3} />
        </div>
        <div className={styles.bottomBottom}>
          <div className={styles.col1} />
          <div className={styles.col2} />
          <div className={styles.col3} />
        </div>
      </div>
    </div>
  );
};

export default AtfLetter2A;
