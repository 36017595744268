// extracted by mini-css-extract-plugin
export var active = "styles-module--active--76e5e";
export var categoryButton = "styles-module--categoryButton--68a5e";
export var chevronIcon = "styles-module--chevronIcon--751c5";
export var desktop = "1340px";
export var dropdown = "styles-module--dropdown--4a4f3";
export var dropdownButton = "styles-module--dropdownButton--f01b4";
export var dropdownContainer = "styles-module--dropdownContainer--b28ce";
export var dropdownContent = "styles-module--dropdownContent--7d9ab";
export var dropdownListItem = "styles-module--dropdownListItem--f0eb1";
export var filterLabel = "styles-module--filterLabel--86fbe";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var selectedFilter = "styles-module--selectedFilter--c5e11";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";