// extracted by mini-css-extract-plugin
export var animationDuration = "5000ms";
export var appear = "styles-module--appear--a8d17";
export var appearDown = "styles-module--appear-down--fb144";
export var appearLeft = "styles-module--appear-left--5fb90";
export var appearRight = "styles-module--appear-right--72247";
export var appearUp = "styles-module--appear-up--2190f";
export var columns = "20";
export var container = "styles-module--container--c81fe";
export var opacityAndPointerEvents = "styles-module--opacity-and-pointer-events--6fead";
export var rows = "10";
export var tile = "styles-module--tile--c8408";
export var updatePointerEvents = "styles-module--update-pointer-events--777c3";