// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftColumn = "styles-module--leftColumn--cbfc2";
export var mobile = "400px";
export var rightColumn = "styles-module--rightColumn--38395";
export var rightColumnText = "styles-module--rightColumnText--880ce";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";