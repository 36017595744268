// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--cbf34";
export var container = "styles-module--container--63f02";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--940c6";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var learnMoreInfo = "styles-module--learnMoreInfo--c8f6d";
export var mobile = "400px";
export var section1 = "styles-module--section1--37aa1";
export var section1Content = "styles-module--section1Content--6989d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var statLabel = "styles-module--statLabel--1957f";
export var stats = "styles-module--stats--9c2e8";
export var tablet = "769px";
export var text1 = "styles-module--text1--b8a4d";