import React from "react";
import { IFounderProfile } from "~schemas";
import ProfileBlock from "./components/ProfileBlock";

interface IProps {
  profile1: IFounderProfile;
  profile2: IFounderProfile;
}

const FounderProfilesMobile = ({ profile1, profile2 }: IProps) => {
  return (
    <>
      <ProfileBlock {...profile1} variant={1} />
      <ProfileBlock {...profile2} variant={2} />
    </>
  );
};

export default FounderProfilesMobile;
