// extracted by mini-css-extract-plugin
export var animationDuration = "5000ms";
export var appear = "styles-module--appear--d5fb4";
export var appearDown = "styles-module--appear-down--ca2a1";
export var appearLeft = "styles-module--appear-left--26790";
export var appearRight = "styles-module--appear-right--b0176";
export var appearUp = "styles-module--appear-up--2a3a3";
export var container = "styles-module--container--01d8e";
export var opacityAndPointerEvents = "styles-module--opacity-and-pointer-events--9fd68";
export var row = "styles-module--row--7c653";
export var row1 = "styles-module--row1--e8933";
export var row2 = "styles-module--row2--96001";
export var row3 = "styles-module--row3--a709a";
export var row4 = "styles-module--row4--9dd4c";
export var row5 = "styles-module--row5--1b8de";
export var row6 = "styles-module--row6--40d8a";
export var rowSlide = "styles-module--row-slide--e7f21";
export var updatePointerEvents = "styles-module--update-pointer-events--5475c";