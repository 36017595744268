// extracted by mini-css-extract-plugin
export var animationDuration = "5000ms";
export var appear = "styles-module--appear--891ea";
export var appearDown = "styles-module--appear-down--e391f";
export var appearLeft = "styles-module--appear-left--51057";
export var appearRight = "styles-module--appear-right--38123";
export var appearUp = "styles-module--appear-up--bee41";
export var canvas = "styles-module--canvas--567a0";
export var container = "styles-module--container--909af";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var opacityAndPointerEvents = "styles-module--opacity-and-pointer-events--bef89";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var updatePointerEvents = "styles-module--update-pointer-events--b3707";