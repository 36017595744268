import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";
import { PortableTextRenderer, SVG, Grid } from "~components";
import { StaticImage } from "gatsby-plugin-image";
import { ISettings } from "~schemas";
import cn from "classnames";
import { useBreakpoints } from "~hooks";
import FooterStripes from "./animations/Stripes";
import FooterPortal from "./animations/Portal";
import FooterSpinner from "./animations/Spinner";
import FooterHoverTiles from "./animations/HoverTiles";
import FooterAnimateTiles from "./animations/AnimateTiles";
import { animationDuration as animationDurationString } from "./animation-duration.module.scss";

interface IQueryResults {
  sanitySettings: ISettings;
}

type TAnimation = `stripes` | `spinner` | `tiles` | `portal`;

const Footer = () => {
  const [animationIndex, setAnimationIndex] = useState(-1);
  const [isAnimationActive, setIsAnimationActive] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const {
    sanitySettings: { footer }
  }: IQueryResults = useStaticQuery(query);

  const { links, _rawFooterText } = footer || {};

  // Animations
  const animations: TAnimation[] = [`portal`, `stripes`, `spinner`, `tiles`]; // Order here determines animation order

  const updateAnimationIndex = () => {
    setAnimationIndex((prev) => {
      if (prev === undefined) {
        return 0;
      }

      if (prev === animations.length - 1) {
        return 0;
      }

      return prev + 1;
    });
  };

  const animationDuration = parseInt(animationDurationString.replace(`ms`, ``));

  const playAnimation = () => {
    updateAnimationIndex();
    setIsAnimationActive(true);
    setTimeout(() => {
      setIsAnimationActive(false);
    }, animationDuration);
  };

  const Animation = () => {
    switch (animations[animationIndex]) {
      case `portal`:
        return <FooterPortal />;
      case `spinner`:
        return <FooterSpinner />;
      case `stripes`:
        return <FooterStripes />;
      case `tiles`:
        return smallDesktop ? <FooterHoverTiles /> : <FooterAnimateTiles />;
      default:
        return null;
    }
  };

  const { smallDesktop } = useBreakpoints();

  return (
    <section className={cn(styles.container, { [styles.visible]: hasMounted })}>
      <button
        onClick={playAnimation}
        type="button"
        className={styles.interactionButton}
      >
        <div className="h3">
          Make
          <br />
          Awesome
        </div>
        <SVG svg="reset" className={styles.interactionButtonIcon} />
      </button>

      <Grid>
        <PortableTextRenderer
          textElement="p"
          className={styles.footerText}
          rawText={_rawFooterText}
        />
        <SVG className={styles.wordmark} svg="wordmark" />
        <nav className={styles.nav}>
          <Grid
            className={cn(styles.linkListGrid, {
              [styles.allLinksHorizontal]: links.length <= 5
            })}
            element="ul"
          >
            <li
              className={cn(styles.linkListItem, styles.linkListMobileLeftCol)}
            >
              <StaticImage
                className={styles.flag}
                src="./flags/flag-aboriginal.png"
                alt="Aboriginal flag"
              />
              <StaticImage
                className={styles.flag}
                src="./flags/flag-torres-strait.png"
                alt="Torres Strait flag"
              />
              <StaticImage
                className={styles.flag}
                src="./flags/flag-pride.png"
                alt="Pride flag"
              />
            </li>

            {links?.map((link, i) => (
              <li
                className={cn(`b2`, styles.linkListItem, {
                  // The first half of the links should be on the left, second half on the right.
                  // The first item in the list is the flags, so we account for this (length - 1)
                  [styles.linkListMobileLeftCol]: i < (links.length - 1) / 2,
                  [styles.linkListMobileRightCol]: i >= (links.length - 1) / 2
                })}
                key={link._key}
              >
                {link._type === `linkExternal` && (
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    {link.title}
                  </a>
                )}

                {link._type === `linkInternal` && (
                  <Link
                    className={styles.link}
                    to={`/${link.reference.slug.current}`}
                  >
                    {link.title}
                  </Link>
                )}
              </li>
            ))}
          </Grid>
        </nav>

        <p className={cn("b2", styles.lamMark)}>
          Made with{" "}
          <a className={styles.lamLink} href="https://loveandmoney.agency/">
            Love + Money
          </a>
        </p>
      </Grid>

      {/* Animations */}
      <div aria-hidden>{isAnimationActive && <Animation />}</div>
    </section>
  );
};

export default Footer;

const query = graphql`
  query FooterQuery {
    sanitySettings {
      footer {
        links {
          ... on SanityLinkExternal {
            _key
            _type
            url
            title
          }
          ... on SanityLinkInternal {
            _key
            _type
            title
            reference {
              title
              slug {
                current
              }
            }
          }
        }
        _rawFooterText
      }
    }
  }
`;
