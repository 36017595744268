import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

const FooterStripes = () => {
  return (
    <div className={styles.container}>
      <div className={cn(styles.row, styles.row1)} />
      <div className={cn(styles.row, styles.row2)} />
      <div className={cn(styles.row, styles.row3)} />
      <div className={cn(styles.row, styles.row4)} />
      <div className={cn(styles.row, styles.row5)} />
      <div className={cn(styles.row, styles.row6)} />
    </div>
  );
};

export default FooterStripes;
