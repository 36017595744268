// extracted by mini-css-extract-plugin
export var container = "styles-module--container--650e9";
export var description = "styles-module--description--70bc1";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mainContent = "styles-module--mainContent--f0c79";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tag = "styles-module--tag--03f87";
export var tags = "styles-module--tags--dc1dc";
export var title = "styles-module--title--c60a6";
export var titleAndTags = "styles-module--titleAndTags--1cb24";
export var video = "styles-module--video--1024e";
export var videoContainer = "styles-module--videoContainer--b7d7c";