import React from "react";
import * as styles from "./styles.module.scss";
import { IToolboxResource } from "~schemas";
import cn from "classnames";
import { Button } from "~components";
import { useApp } from "~hooks";

interface IProps {
  advisor: IToolboxResource;
}

const Advisor = ({
  advisor: {
    category,
    description,
    name,
    type,
    additionalResources,
    link,
    subcategories,
    subheading1,
    subheading2,
    _id
  }
}: IProps) => {
  const { setToolbox, setIsToolboxOpen } = useApp();

  const addToList = () => {
    setToolbox((prev) => {
      const existingItem = prev.find((item) => item._id === _id);

      if (existingItem) {
        return prev;
      }

      const newItem: IToolboxResource = {
        _id,
        category,
        description,
        name,
        type,
        additionalResources,
        link,
        subcategories,
        subheading1,
        subheading2
      };

      return [...prev, newItem];
    });
    setIsToolboxOpen(true);
  };

  const getCaptions: () => { caption1: string; caption2: string } = () => {
    const caption1 = [subheading1, subheading2].filter(Boolean).join(", ");

    let caption2 = "";

    if (subcategories?.length) {
      caption2 = subcategories
        ?.map((subcategory) => subcategory.title)
        ?.join(", ");
    }

    return {
      caption1,
      caption2
    };
  };
  const { caption1, caption2 } = getCaptions();

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <div>
          <h4 className={cn(`h4`, styles.name)}>{name}</h4>

          {caption1 && <div className={cn(`caption`)}>{caption1}</div>}

          {caption2 && (
            <div className={cn(`caption`, styles.subcategory)}>{caption2}</div>
          )}
        </div>
      </div>

      <p className={cn(`b2`, styles.bio)}>{description}</p>

      <Button onClick={addToList} lightTheme fluid iconRight="plus">
        Add to Toolbox
      </Button>
    </div>
  );
};

export default Advisor;
