import React, { useRef } from "react";
import * as styles from "./styles.module.scss";
import { IToolboxResource } from "~schemas";
import CategoryButton from "./components/CategoryButton";
import cn from "classnames";
import AdvisorLarge from "../../../AdvisorLarge";
import { getAdvisorCategoryInfo } from "~utils";
import { useDisableFocus } from "~hooks";
import {
  TActiveAdvisorBlockIndex,
  TAdvisorCategory
} from "src/slices/Advisors";
import { SVG } from "~components";

interface IProps {
  advisors: IToolboxResource[];
  category: TAdvisorCategory;
  activeBlockIndex: TActiveAdvisorBlockIndex;
  setActiveBlockIndex: React.Dispatch<
    React.SetStateAction<TActiveAdvisorBlockIndex>
  >;
  containerWidth: number;
  hoveredBlockIndex: TActiveAdvisorBlockIndex;
  setHoveredBlockIndex: React.Dispatch<
    React.SetStateAction<TActiveAdvisorBlockIndex>
  >;
}

const Category = ({
  activeBlockIndex,
  advisors,
  category,
  setActiveBlockIndex,
  containerWidth,
  hoveredBlockIndex,
  setHoveredBlockIndex
}: IProps) => {
  const { index } = getAdvisorCategoryInfo(category);

  const isActive = activeBlockIndex === index;
  const isHovered = hoveredBlockIndex === index;

  const containerRef = useRef<HTMLDivElement>(null);
  useDisableFocus(!isActive, containerRef);

  const handleMouseMove = () => {
    setHoveredBlockIndex(index);
  };

  const closeCategory = () => {
    setActiveBlockIndex(null);
  };

  return (
    <div
      className={cn(styles.container, { [styles.active]: isActive })}
      onMouseMove={handleMouseMove}
    >
      <div className={styles.buttonContainer}>
        <CategoryButton
          category={category}
          setActiveBlockIndex={setActiveBlockIndex}
          active={isActive}
          hovered={isHovered}
        />
      </div>

      <div className={styles.advisorsContainer} ref={containerRef}>
        <div
          className={styles.advisors}
          style={{ width: `${containerWidth * 0.6}px` }}
        >
          {advisors?.[0] && (
            <>
              {advisors.map((advisor, i) => (
                <AdvisorLarge advisor={advisor} key={i} />
              ))}

              <button
                onClick={closeCategory}
                className={cn("button-text", styles.backButton)}
              >
                <SVG svg="arrowLeft" className={styles.backButtonIcon} />
                <span>Back</span>
              </button>
            </>
          )}

          {!advisors?.[0] && (
            <p className={cn(`h4`, styles.noAdvisors)}>
              No people in this category... yet
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Category;
