interface IFormField {
  label?: string;
  placeholder?: string;
}

interface IFormFieldMaxLength extends IFormField {
  maxLength: number;
}

interface IContactFormData {
  name: IFormField;
  email: IFormField;
  message: IFormFieldMaxLength;
  honeypot: IFormField;
}

const requestContactsFormData: IContactFormData = {
  name: {
    placeholder: "Full name"
  },
  email: {
    placeholder: "Email"
  },
  message: {
    placeholder: "Tell us about your business",
    maxLength: 150
  },
  honeypot: {
    placeholder: "Type your message here..."
  }
};

export default requestContactsFormData;
