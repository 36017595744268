import React from "react";
import * as styles from "./styles.module.scss";
import { IFounderProfile } from "~schemas";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  PortableTextRenderer,
  SlicePadding,
  IntersectionAnimation
} from "~components";
import OverlayGraphic from "../OverlayGraphic";
import LinksAccordion from "../../../LinksAccordion";
import { useInView } from "react-intersection-observer";

export type TProfileVariant = 1 | 2;

interface IProps extends IFounderProfile {
  variant: TProfileVariant;
}

const ProfileBlock = ({
  _rawBio,
  headshot,
  links,
  name,
  nameShort,
  position,
  backgroundColor,
  textColor,
  variant
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-50%`,
    triggerOnce: true
  });

  return (
    <div
      ref={ref}
      style={{
        background: backgroundColor?.hex || `var(--color-black)`,
        color: textColor?.hex || `var(--color-white)`
      }}
    >
      <div className={styles.imageAndTitles}>
        <GatsbyImage
          image={headshot.asset.gatsbyImageData}
          alt={headshot.altText || ``}
          style={{ background: `red`, position: `absolute`, inset: 0 }}
        />
        <OverlayGraphic
          variant={variant}
          name={name}
          position={position}
          color={backgroundColor?.hex || `black`}
          triggerAnimation={inView}
        />
      </div>
      <SlicePadding config={{ paddingBottom: `small` }}>
        <IntersectionAnimation trigger={inView} delay={300}>
          <PortableTextRenderer
            className="b1"
            rawText={_rawBio}
            textElement="p"
          />
        </IntersectionAnimation>
      </SlicePadding>

      <LinksAccordion links={links} nameShort={nameShort} />
    </div>
  );
};

export default ProfileBlock;
