import React from "react";
import * as styles from "./styles.module.scss";
import {
  Grid,
  IntersectionAnimation,
  PortableTextRenderer,
  SlicePadding
} from "~components";
import { useInView } from "react-intersection-observer";
import CheckboxFilter, {
  type IProps as ICheckboxFilterProps
} from "./components/CheckboxFilter";

interface IProps extends ICheckboxFilterProps {
  _rawHeaderText: any;
}

const HeaderAndCheckboxFilter = ({
  _rawHeaderText,
  filterOptions,
  activeCategories,
  onClickCategory,
  allPortfolioItems,
  numberOfFilteredItems
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <div ref={ref}>
      <SlicePadding>
        <Grid>
          {_rawHeaderText && (
            <div className={styles.leftColumn}>
              <IntersectionAnimation trigger={inView} animation="fade">
                <PortableTextRenderer
                  textElement="h2"
                  rawText={_rawHeaderText}
                  className="h1"
                />
              </IntersectionAnimation>
            </div>
          )}

          <div className={styles.rightColumn}>
            <IntersectionAnimation
              trigger={inView}
              animation="fade"
              delay={150}
            >
              <CheckboxFilter
                filterOptions={filterOptions}
                activeCategories={activeCategories}
                onClickCategory={onClickCategory}
                allPortfolioItems={allPortfolioItems}
                numberOfFilteredItems={numberOfFilteredItems}
              />
            </IntersectionAnimation>
          </div>
        </Grid>
      </SlicePadding>
    </div>
  );
};

export default HeaderAndCheckboxFilter;
