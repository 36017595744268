// extracted by mini-css-extract-plugin
export var container = "styles-module--container--38351";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var notification = "styles-module--notification--3334c";
export var showNotification = "styles-module--show-notification--9b846";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";