import React, { useEffect, useRef, useState } from "react";
import { IGlobalLink, ICaseStudy } from "~schemas";
import * as styles from "./styles.module.scss";
import Quadrant from "./components/Quadrant";

export interface IProps {
  quadrant1: IGlobalLink | ICaseStudy;
  quadrant2: IGlobalLink | ICaseStudy;
  quadrant3: IGlobalLink | ICaseStudy;
  quadrant4: IGlobalLink | ICaseStudy;
}

export type TQuadrantNumber = 0 | 1 | 2 | 3 | 4;

export interface IRectangleDimensions {
  width: number;
  height: number;
}

const HomepageAtfDesktop = ({
  quadrant1,
  quadrant2,
  quadrant3,
  quadrant4
}: IProps) => {
  const [activeQuadrant, setActiveQuadrant] = useState<TQuadrantNumber>(0);
  const [quadrantContainerSize, setQuadrantContainerSize] =
    useState<IRectangleDimensions>({
      height: 0,
      width: 0
    });
  const [quadrantContentSize, setQuadrantContentSize] =
    useState<IRectangleDimensions>({
      height: 0,
      width: 0
    });
  const [isAnimationEnabled, setIsAnimationEnabled] = useState(false); // Need this to prevent growing animation on mount

  const QuadrantContainerRef = useRef<HTMLDivElement>(null);

  const expandedQuadrantSizeMultipler = 4;

  const calculateSizes = () => {
    const getExpandedSize = (size: number) => {
      const numberOfSegments = expandedQuadrantSizeMultipler + 1;
      const percentageLengthPerSegment = 100 / numberOfSegments / 100;
      const expandedAreaPercentageLength =
        percentageLengthPerSegment * expandedQuadrantSizeMultipler;
      return size * expandedAreaPercentageLength;
    };

    const containerSizeInfo =
      QuadrantContainerRef.current?.getBoundingClientRect();

    if (!containerSizeInfo) return;

    const { width, height } = containerSizeInfo;

    setQuadrantContainerSize({
      height,
      width
    });

    setQuadrantContentSize({
      height: getExpandedSize(height) || 0,
      width: getExpandedSize(width) || 0
    });
  };

  const handleResize = () => {
    calculateSizes();
    setIsAnimationEnabled(false);
  };

  useEffect(() => {
    calculateSizes();
    window.addEventListener(`resize`, handleResize);
    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  const quadrantDataArray = [quadrant1, quadrant2, quadrant3, quadrant4];

  return (
    <div className={styles.container} ref={QuadrantContainerRef}>
      <div className={styles.absoluteContainer}>
        <div
          className={styles.quadrantsDesktop}
          onMouseLeave={() => setActiveQuadrant(0)} // Reset
        >
          {quadrantDataArray.map((quadrantData, i) => (
            <Quadrant
              key={i}
              contentData={quadrantData}
              quadrantContentSize={quadrantContentSize}
              number={(i + 1) as TQuadrantNumber}
              activeQuadrant={activeQuadrant}
              setActiveQuadrant={setActiveQuadrant}
              expandedQuadrantSizeMultiplier={expandedQuadrantSizeMultipler}
              quadrantContainerSize={quadrantContainerSize}
              isAnimationEnabled={isAnimationEnabled}
              setIsAnimationEnabled={setIsAnimationEnabled}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomepageAtfDesktop;
