import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  active: boolean;
}

const AtfLetter4E = ({ active }: IProps) => {
  return (
    <div className={cn(styles.container, { [styles.active]: active })}>
      <div className={styles.left}>
        <div className={styles.row1} />
        <div className={styles.row2} />
        <div className={styles.row3} />
      </div>
      <div className={styles.right}>
        <div className={styles.row1} />
        <div className={styles.row2} />
        <div className={styles.row3} />
        <div className={styles.row4} />
        <div className={styles.row5} />
      </div>
    </div>
  );
};

export default AtfLetter4E;
