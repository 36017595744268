import React, { CSSProperties } from "react";
import { SlicePadding, IntersectionAnimation } from "~components";
import { IHero } from "~schemas";
import * as styles from "./styles.module.scss";
import HeroBackgroundGraphic from "./components/BackgroundGraphic";

interface IProps {
  data: IHero;
}

const Hero = ({
  data: { title, backgroundColor, graphicColor, textColor }
}: IProps) => {
  const colorStyles: CSSProperties = {
    background: backgroundColor?.hex || `var(--color-white)`,
    color: textColor?.hex || `var(--color-black)`
  };

  return (
    <div className={styles.container} style={colorStyles}>
      <HeroBackgroundGraphic
        color={graphicColor?.hex || `var(--color-black)`}
      />

      <SlicePadding xOnly className={styles.padding}>
        <IntersectionAnimation>
          <h1 className="d2">{title}</h1>
        </IntersectionAnimation>
      </SlicePadding>
    </div>
  );
};

export default Hero;
