import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { SVG } from "~components";
import { ISyndicateCompany } from "~schemas";

interface IProps {
  company: ISyndicateCompany;
  className?: string;
}

const CompanySlide = ({ company, className }: IProps) => {
  const { link, logo, name } = company;

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={cn(className, styles.logoLink)}
    >
      <div className={styles.logoLinkImage}>
        <GatsbyImage
          alt={`Visit ${name}`}
          image={logo.asset.gatsbyImageData}
          objectFit="contain"
          style={{ height: "100%", width: "100%" }}
        />
      </div>

      <div className={cn("button-text", styles.desktopLinkLabel)}>
        Visit {name}
        <SVG svg="arrowRight" className={styles.arrowRightIcon} />
      </div>

      <div className={cn(styles.hoverBlock, styles.hoverBlock1)} />
      <div className={cn(styles.hoverBlock, styles.hoverBlock2)} />
      <div className={cn(styles.hoverBlock, styles.hoverBlock3)} />
      <div className={cn(styles.hoverBlock, styles.hoverBlock4)} />
      <div className={cn(styles.hoverBlock, styles.hoverBlock5)} />
    </a>
  );
};

export default CompanySlide;
