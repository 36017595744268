import { CSSProperties } from "react";
import { IRectangleDimensions, TQuadrantNumber } from "../..";

const getQuadrantSizeStyles: (
  quadrantNumber: TQuadrantNumber,
  quadrantContainerSize: IRectangleDimensions,
  expandedQuadrantSizeMultipler: number,
  activeQuadrant: TQuadrantNumber
) => CSSProperties = (
  quadrantNumber,
  quadrantContainerSize,
  expandedQuadrantSizeMultipler,
  activeQuadrant
) => {
  const { height, width } = quadrantContainerSize;

  const smallRatio = 1 / (expandedQuadrantSizeMultipler + 1);
  const expandedRatio = 1 - smallRatio;

  switch (activeQuadrant) {
    case 0:
      return { height: height / 2, width: width / 2 };

    case 1:
      switch (quadrantNumber) {
        case 1:
          return {
            height: height * expandedRatio,
            width: width * expandedRatio
          };
        case 2:
          return {
            height: height * expandedRatio,
            width: width * smallRatio
          };
        case 3:
          return {
            height: height * smallRatio,
            width: width * expandedRatio
          };
        case 4:
          return {
            height: height * smallRatio,
            width: width * smallRatio
          };
        default:
          return { height: 0, width: 0 };
      }

    case 2:
      switch (quadrantNumber) {
        case 1:
          return {
            height: height * expandedRatio,
            width: width * smallRatio
          };
        case 2:
          return {
            height: height * expandedRatio,
            width: width * expandedRatio
          };
        case 3:
          return {
            height: height * smallRatio,
            width: width * smallRatio
          };
        case 4:
          return {
            height: height * smallRatio,
            width: width * expandedRatio
          };
        default:
          return { height: 0, width: 0 };
      }

    case 3:
      switch (quadrantNumber) {
        case 1:
          return {
            height: height * smallRatio,
            width: width * expandedRatio
          };
        case 2:
          return {
            height: height * smallRatio,
            width: width * smallRatio
          };
        case 3:
          return {
            height: height * expandedRatio,
            width: width * expandedRatio
          };
        case 4:
          return {
            height: height * expandedRatio,
            width: width * smallRatio
          };
        default:
          return { height: 0, width: 0 };
      }

    case 4:
      switch (quadrantNumber) {
        case 1:
          return {
            height: height * smallRatio,
            width: width * smallRatio
          };
        case 2:
          return {
            height: height * smallRatio,
            width: width * expandedRatio
          };
        case 3:
          return {
            height: height * expandedRatio,
            width: width * smallRatio
          };
        case 4:
          return {
            height: height * expandedRatio,
            width: width * expandedRatio
          };
        default:
          return { height: 0, width: 0 };
      }

    default:
      return { height: 0, width: 0 };
  }
};

export default getQuadrantSizeStyles;
