import React, { CSSProperties } from "react";
import * as styles from "./styles.module.scss";
import { TProfileVariant } from "../..";
import { IntersectionAnimation } from "~components";
import cn from "classnames";
import { SlicePadding } from "~components";

interface IProps {
  variant?: TProfileVariant;
  color?: string;
  name: string;
  position: string;
  hideText: boolean;
}

interface ITextContent extends IProps {
  rightSide?: boolean;
}

const OverlayGraphic = ({
  color,
  variant,
  name,
  position,
  hideText
}: IProps) => {
  const backgroundStyle: CSSProperties = { backgroundColor: color };

  return (
    <div
      className={cn(styles.container, {
        [styles.variant1]: variant === 1,
        [styles.variant2]: variant === 2
      })}
    >
      {variant === 1 && (
        <>
          <div style={backgroundStyle} />
          <div style={backgroundStyle} />
          <div />
          <div style={backgroundStyle} />
          <div />
          <div style={backgroundStyle} />
          <div style={backgroundStyle} className={styles.textContent}>
            <TextContent name={name} position={position} hideText={hideText} />
          </div>
        </>
      )}

      {variant === 2 && (
        <>
          <div />
          <div style={backgroundStyle} />
          <div style={backgroundStyle} className={styles.textContent}>
            <TextContent
              name={name}
              position={position}
              rightSide
              hideText={hideText}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OverlayGraphic;

const TextContent = ({ name, position, rightSide, hideText }: ITextContent) => {
  return (
    <SlicePadding
      xOnly
      className={cn(styles.textPadding, {
        [styles.rightSide]: rightSide,
        [styles.hideText]: hideText
      })}
    >
      <IntersectionAnimation delay={150}>
        <div className={cn(`b2`, styles.position)}>{position}</div>
      </IntersectionAnimation>
      <IntersectionAnimation delay={300}>
        <h3 className="h2">{name}</h3>
      </IntersectionAnimation>
    </SlicePadding>
  );
};
