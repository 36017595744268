// extracted by mini-css-extract-plugin
export var active = "styles-module--active--3b97f";
export var advisorNetworkLink = "styles-module--advisorNetworkLink--08980";
export var backdrop = "styles-module--backdrop--c6881";
export var closeButton = "styles-module--closeButton--08428";
export var contactItem = "styles-module--contactItem--bbb76";
export var contactName = "styles-module--contactName--1596c";
export var contactsList = "styles-module--contactsList--76e25";
export var container = "styles-module--container--990c5";
export var content = "styles-module--content--ec2ab";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var removeButton = "styles-module--removeButton--2fbef";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--62ba8";
export var topRow = "styles-module--topRow--8139e";
export var xIcon = "styles-module--xIcon--570da";