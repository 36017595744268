import React from "react";
import type { ITypeform } from "~schemas";
import { useExternalScript } from "~hooks";
import { SliceConfig } from "~components";
import * as styles from "./styles.module.scss";

interface IProps {
  data: ITypeform;
}

const Typeform = ({ data: { formId } }: IProps) => {
  useExternalScript("//embed.typeform.com/next/embed.js");

  return (
    <SliceConfig>
      <div className={styles.formContainer}>
        <div data-tf-live={formId} />

        <div className={styles.hideIframeTopBorder} />
      </div>
    </SliceConfig>
  );
};

export default Typeform;
