// extracted by mini-css-extract-plugin
export var arrowIcon = "styles-module--arrowIcon--b7146";
export var container = "styles-module--container--f5a84";
export var content = "styles-module--content--4f795";
export var desktop = "1340px";
export var giant = "2200px";
export var label = "styles-module--label--2b733";
export var labelAndLink = "styles-module--labelAndLink--60cf1";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var linkBadge = "styles-module--linkBadge--a076c";
export var linkText = "styles-module--linkText--b706d";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";