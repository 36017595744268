import React from "react";
import { IMenuItem } from "~schemas";
import * as styles from "./styles.module.scss";
import { Link } from "gatsby";
import cn from "classnames";
import { useApp } from "~hooks";

const MenuItem = ({
  backgroundColor,
  highlightColor,
  page,
  textColor,
  title
}: IMenuItem) => {
  const { isMenuOpen, setIsMenuOpen, pathname } = useApp();

  const handleClick = () => {
    if (pathname === `/${page.slug.current}/`) {
      setIsMenuOpen(false);
    }
  };

  return (
    <Link
      to={`/${page.slug.current}`}
      onClick={handleClick}
      aria-hidden={!isMenuOpen}
      tabIndex={isMenuOpen ? 0 : -1}
      className={styles.container}
      style={{
        background: backgroundColor.hex || `var(--color-white)`,
        color: textColor.hex || `var(--color-black)`
      }}
    >
      <div className={styles.graphicContainer}>
        <div className={styles.leftColumn}>
          <div
            className={styles.colorBlock}
            style={{ background: highlightColor.hex }}
          />
        </div>
        <div className={styles.rightColumn}>
          <div
            className={styles.colorBlock}
            style={{ background: highlightColor.hex }}
          />
        </div>
      </div>
      <div className={cn(`h1`, styles.text)}>{title}</div>
    </Link>
  );
};

export default MenuItem;
