import { useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import type { EmblaOptionsType } from "embla-carousel";

export const useEmbla = (numberOfItems: number, options?: EmblaOptionsType) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const scrollNext = () =>
    setActiveSlideIndex((prev) => Math.min(prev + 1, numberOfItems - 1));

  const scrollPrev = () => setActiveSlideIndex((prev) => Math.max(prev - 1, 0));

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      const index = emblaApi.selectedScrollSnap();
      setActiveSlideIndex(index);
    };

    emblaApi.on("select", onSelect);

    return () => {
      emblaApi.off("select", onSelect);
    };
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.scrollTo(activeSlideIndex);
  }, [activeSlideIndex, emblaApi]);

  useEffect(() => {
    if (activeSlideIndex > numberOfItems - 1) {
      setActiveSlideIndex(numberOfItems - 1);
    }
  }, [numberOfItems, activeSlideIndex]);

  return {
    emblaRef,
    activeSlideIndex,
    setActiveSlideIndex,
    scrollNext,
    scrollPrev
  };
};

export default useEmbla;
