import React from "react";
import * as styles from "./styles.module.scss";
import { NetworkLetterGraphic, SVG } from "~components";
import { getAdvisorCategoryInfo } from "~utils";
import cn from "classnames";
import {
  TActiveAdvisorBlockIndex,
  TAdvisorCategory
} from "src/slices/Advisors";

interface IProps {
  category: TAdvisorCategory;
  active: boolean;
  hovered: boolean;
  setActiveBlockIndex: React.Dispatch<
    React.SetStateAction<TActiveAdvisorBlockIndex>
  >;
}

const CategoryButton = ({
  category,
  active,
  hovered,
  setActiveBlockIndex
}: IProps) => {
  const { categoryPlural, graphic, textColor, index } =
    getAdvisorCategoryInfo(category);

  const handleClick = () => {
    setActiveBlockIndex((prev) => {
      if (prev === index) return null;
      return index;
    });
  };

  return (
    <button onClick={handleClick} className={styles.button} type="button">
      <NetworkLetterGraphic
        active={active || hovered}
        graphic={graphic}
        className={styles.graphic}
      />

      <div className={styles.content} style={{ color: textColor }}>
        <h3 className={cn(`h2`, styles.title)}>{categoryPlural}</h3>

        <div className={cn(styles.back, { [styles.visible]: active })}>
          <SVG svg="arrowLeft" className={styles.arrowIcon} />
          <span className={cn(`button-text`)}>Back</span>
        </div>
      </div>
    </button>
  );
};

export default CategoryButton;
