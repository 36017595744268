import Hero from "./Hero";
import TextArticle from "./TextArticle";
import FeaturedText from "./FeaturedText";
import SimpleImage from "./SimpleImage";
import Video from "./Video";
import ImageGrid from "./ImageGrid";
import HomepageAtf from "./HomepageAtf";
import LinkTiles from "./LinkTiles";
import Advisors from "./Advisors";
import NetworkPreview from "./NetworkPreview";
import FounderProfiles from "./FounderProfiles";
import FeaturedCaseStudy from "./FeaturedCaseStudy";
import CountUp from "./CountUp";
import InvestmentAreas from "./InvestmentAreas";
import Entities from "./Entities";
import Quote from "./Quote";
import Contact from "./Contact";
import Scratchie from "./Scratchie";
import PortfolioGallery from "./PortfolioGallery";
import CallToAdventure from "./CallToAdventure";
import Syndicate from "./Syndicate";
import Typeform from "./Typeform";
import Statistics from "./Statistics";
import ToolboxResources from "./ToolboxResources";

const slices = Object.freeze({
  Hero,
  TextArticle,
  Typeform,
  FeaturedText,
  SimpleImage,
  Syndicate,
  Video,
  ImageGrid,
  HomepageAtf,
  LinkTiles,
  Advisors,
  NetworkPreview,
  FounderProfiles,
  FeaturedCaseStudy,
  CountUp,
  InvestmentAreas,
  Entities,
  Quote,
  Contact,
  Scratchie,
  PortfolioGallery,
  CallToAdventure,
  Statistics,
  ToolboxResources
});

export type TSlice = keyof typeof slices;

export default slices;
