import React from "react";
import * as styles from "./styles.module.scss";

const FooterHoverTiles = () => {
  const gridColumns = styles.columns;
  const gridRows = styles.rows;
  const gridSlots = gridColumns * gridRows;
  const gridSlotArray = [...Array(gridSlots)];

  return (
    <div className={styles.container}>
      {gridSlotArray.map((_e, i) => (
        <div className={styles.tile} key={i} />
      ))}
    </div>
  );
};

export default FooterHoverTiles;
