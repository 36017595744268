import React, { FormEvent, useEffect, useState } from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { TFormField } from "~constants";
import { Button, TextInput } from "~components";
import { ISendContactDetailsRequestBody } from "~api";
import requestContactsFormData from "./requestContactsFormData";
import { useApp } from "~hooks";

type TFormValues = {
  [key in TFormField]?: string;
};

type TState = "idle" | "submitting" | "submitted" | "error";

const RequestContactsForm = () => {
  const formFields: TFormField[] = [`name`, `email`, `honeypot`];

  const initialFormValues: TFormValues = {};

  for (const field of formFields) {
    initialFormValues[field] = ``;
  }

  const [formValues, setFormValues] = useState(initialFormValues);
  const [state, setState] = useState<TState>(`idle`);

  const { isToolboxOpen, setIsToolboxOpen, setToolbox, toolbox } = useApp();

  const handleUpdateValue = (value: string, formField: TFormField) => {
    setFormValues((prev) => ({ ...prev, [formField]: value }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formValues.honeypot) return;

    setState(`submitting`);

    const messageContent: ISendContactDetailsRequestBody = {
      email: formValues.email || ``,
      name: formValues.name || ``,
      toolbox
    };

    try {
      const response = await fetch(`/api/send-contact-information`, {
        method: `POST`,
        body: JSON.stringify(messageContent),
        headers: {
          "content-type": `application/json`
        }
      });

      const data = await response.json();

      if (data.statusCode === 500) {
        throw new Error(`Something went wrong submitting form: ${data.body}`);
      }

      setState(`submitted`);
    } catch (error) {
      console.error(error);
      setState(`error`);
    }
  };

  const isSubmitting = state === `submitting`;
  const hasSubmitted = state === `submitted`;
  const hasError = state === `error`;

  const captionText = () => {
    if (hasError) {
      return `Sorry, something went wrong. Please try again in a few minutes.`;
    }
    if (hasSubmitted) {
      return `Your connection request has been sent. Congratulations on tapping into the power of the network effect!`;
    }
    return `PLACEHOLDER`; // To preserve div height and prevent layout shift
  };

  const disableFormFields = isSubmitting || hasSubmitted;

  const isButtonDisabled = () => {
    if (hasSubmitted || isSubmitting) return true;

    return false;
  };

  // Reset if closing completed form
  const resetAllFields = () => {
    setFormValues(initialFormValues);
    setToolbox([]);
    setState("idle");
  };

  const resetIfContactsIsClosed = () => {
    // This allows us to get current value of isToolboxOpen in the setTimeout
    setIsToolboxOpen((current) => {
      if (!current) resetAllFields();
      return current;
    });
  };

  useEffect(() => {
    if (!isToolboxOpen && hasSubmitted) {
      setTimeout(resetIfContactsIsClosed, 500); // Time for form to close
    }
  }, [isToolboxOpen]);

  const showButtonMessage = hasError || hasSubmitted;

  return (
    <div className={styles.container}>
      <p className={cn(`b1`, styles.text)}>
        You’re almost there! Enter your details to receive information about
        your selected resources*
      </p>

      <form onSubmit={handleSubmit}>
        <div className={styles.inlineFields}>
          <TextInput
            className={styles.input}
            autocomplete="name"
            label={requestContactsFormData.name.label}
            placeholder={requestContactsFormData.name.placeholder}
            value={formValues["name"]}
            onChange={(value) => handleUpdateValue(value, "name")}
            disabled={disableFormFields}
            required
          />

          <TextInput
            className={styles.input}
            type="email"
            autocomplete="email"
            label={requestContactsFormData.email.label}
            placeholder={requestContactsFormData.email.placeholder}
            value={formValues["email"]}
            onChange={(value) => handleUpdateValue(value, "email")}
            disabled={disableFormFields}
            required
          />
        </div>

        {/* Honeypot */}
        <TextInput
          className={cn(styles.input, styles.abc)}
          label={requestContactsFormData.honeypot.label}
          placeholder={requestContactsFormData.honeypot.placeholder}
          isHoneypot
        />

        <p className={cn(`caption`, styles.disclaimer)}>
          *We will put you in touch with selected contacts if appropriate
        </p>

        <div className={styles.submitButtonContainer}>
          <Button
            className={cn(styles.submitButton, {
              [styles.hidden]: showButtonMessage
            })}
            fluid
            disabled={isButtonDisabled()}
            buttonType="submit"
            iconRight="arrowRight"
            loading={isSubmitting}
          >
            Submit{" "}
          </Button>

          <p
            aria-hidden={!showButtonMessage}
            className={cn(`caption`, styles.buttonCaption, {
              [styles.visible]: showButtonMessage
            })}
          >
            {captionText()}
          </p>
        </div>
      </form>
    </div>
  );
};

export default RequestContactsForm;
