import React from "react";
import type { IAltImage, IColor, IFeaturedCaseStudy } from "~schemas";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { IntersectionAnimation, SVG } from "~components";
import { Link } from "gatsby";
import { SliceConfig } from "~components";
import { useInView } from "react-intersection-observer";

interface IProps {
  data: IFeaturedCaseStudy;
}

const FeaturedCaseStudy = ({
  data: { caseStudy, backgroundColor, textColor, sliceConfig }
}: IProps) => {
  if (caseStudy._type === `globalLink`) {
    const { link: linkArray } = caseStudy;

    const link = linkArray?.[0];

    if (!link) return null;

    const Content = () => (
      <FeaturedCaseStudyContent
        backgroundColor={backgroundColor}
        textColor={textColor}
        category={caseStudy.category}
        description={caseStudy.description}
        image={caseStudy.image}
        title={caseStudy.title}
      />
    );

    return (
      <SliceConfig config={sliceConfig}>
        <a
          className={styles.linkTile}
          href={link.url}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Content />
        </a>
      </SliceConfig>
    );
  }

  if (caseStudy._type === `caseStudy`) {
    return (
      <SliceConfig config={sliceConfig}>
        <Link
          to={`/awesome-stories/${caseStudy.slug.current}`}
          className={styles.linkTile}
        >
          <FeaturedCaseStudyContent
            backgroundColor={backgroundColor}
            textColor={textColor}
            category="Case Study"
            image={{ ...caseStudy.linkTileImage, altText: caseStudy.title }}
            title={caseStudy.title}
          />
        </Link>
      </SliceConfig>
    );
  }

  return null;
};

export default FeaturedCaseStudy;

interface IFeaturedCaseStudyContent {
  backgroundColor: IColor;
  textColor: IColor;
  image: IAltImage;
  category: string;
  title: string;
  description?: string;
}

const FeaturedCaseStudyContent = ({
  backgroundColor,
  textColor,
  category,
  description,
  image,
  title
}: IFeaturedCaseStudyContent) => {
  const backgroundStyle = {
    background: backgroundColor?.hex || `var(--color-black)`
  };

  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <div
      style={{
        background: backgroundColor?.hex || `var(--color-black)`,
        color: textColor?.hex || `var(--color-black)`
      }}
      className={styles.container}
      ref={ref}
    >
      <div className={styles.text}>
        <div>
          <IntersectionAnimation trigger={inView} delay={150}>
            <div className={cn(styles.subheading, `b1`)}>{category}</div>
          </IntersectionAnimation>
          <IntersectionAnimation trigger={inView} delay={300}>
            <h3 className={cn(styles.title, `h2`)}>{title}</h3>
          </IntersectionAnimation>
        </div>

        {description && <p className="b1">{description}</p>}
      </div>
      <div className={styles.imageContainer}>
        <GatsbyImage
          alt={image.altText || ``}
          image={image.asset.gatsbyImageData}
          className={styles.image}
          style={{ position: `absolute`, inset: 0 }}
        />
        <div className={styles.iconContainer} style={backgroundStyle}>
          <SVG svg="arrowRightCircle" className={styles.icon} />
        </div>
        <div
          className={cn(styles.blockGraphic, styles.topLeft)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.middleLeft)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.bottomLeft)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.bottomMiddle)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.bottomRight)}
          style={backgroundStyle}
        />
        <div
          className={cn(styles.blockGraphic, styles.middleRight)}
          style={backgroundStyle}
        />
      </div>
    </div>
  );
};
