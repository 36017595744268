import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {}

const Graphic8 = (props: IProps) => {
  return (
    <>
      <div className={cn(styles.circle, styles.circle1)} />
      <div className={cn(styles.circle, styles.circle2)} />
      <div className={cn(styles.circle, styles.circle3)} />
      <div className={cn(styles.circle, styles.circle4)} />
      <div className={cn(styles.circle, styles.circle5)} />
    </>
  );
};

export default Graphic8;
