// extracted by mini-css-extract-plugin
export var container = "styles-module--container--f0560";
export var desktop = "1340px";
export var fullHeight = "styles-module--fullHeight--88b69";
export var giant = "2200px";
export var graphicContainer = "styles-module--graphicContainer--b6660";
export var graphicSegment = "styles-module--graphicSegment--2376c";
export var header = "styles-module--header--13449";
export var heroStat = "styles-module--heroStat--14f38";
export var heroStatContainer = "styles-module--heroStatContainer--eec26";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var segment1 = "styles-module--segment1--843bd";
export var segment2 = "styles-module--segment2--7e219";
export var segment3 = "styles-module--segment3--b4c7b";
export var segment4 = "styles-module--segment4--68af1";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var statItems = "styles-module--statItems--d5222";
export var statsContainer = "styles-module--statsContainer--bc917";
export var tablet = "769px";
export var textContainer = "styles-module--textContainer--a466b";