import { useInView } from "react-intersection-observer";

const useIntersectionAnimation = (margin = "90px") => {
  const { ref, inView } = useInView({
    rootMargin: margin
  });

  return { ref, inView };
};

export default useIntersectionAnimation;
