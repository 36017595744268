import React from "react";
import * as styles from "./styles.module.scss";
import { IFounderProfile } from "~schemas";
import { GatsbyImage } from "gatsby-plugin-image";
import OverlayGraphic from "./components/OverlayGraphic";
import cn from "classnames";
import { TActiveProfile } from "../..";

export type TProfileVariant = 1 | 2;

interface IProps extends IFounderProfile {
  variant: TProfileVariant;
  className?: string;
  activeProfile: TActiveProfile;
}

const ProfileBlock = ({
  headshot,
  name,
  position,
  backgroundColor,
  textColor,
  variant,
  className,
  activeProfile
}: IProps) => {
  return (
    <div
      className={cn(styles.container, className)}
      style={{
        background: backgroundColor?.hex || `var(--color-black)`,
        color: textColor?.hex || `var(--color-white)`
      }}
    >
      <div className={styles.imageAndTitles}>
        <GatsbyImage
          image={headshot.asset.gatsbyImageData}
          alt={headshot.altText || ``}
          style={{ width: `100%`, height: `100%` }}
        />
        <OverlayGraphic
          variant={variant}
          name={name}
          position={position}
          color={backgroundColor?.hex || `black`}
          hideText={activeProfile !== null && activeProfile !== variant}
        />
      </div>
    </div>
  );
};

export default ProfileBlock;
