// extracted by mini-css-extract-plugin
export var active = "styles-module--active--6e957";
export var container = "styles-module--container--54ef2";
export var image = "styles-module--image--6d4fb";
export var letterGraphic = "styles-module--letterGraphic--b0c35";
export var link = "styles-module--link--a5a90";
export var linkIcon = "styles-module--linkIcon--7b735";
export var mainContent = "styles-module--mainContent--28df7";
export var subtitle = "styles-module--subtitle--5d41d";
export var text = "styles-module--text--59ed8";
export var textAndLink = "styles-module--textAndLink--b2123";
export var title = "styles-module--title--483e7";