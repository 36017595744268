import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {}

const Graphic3 = (props: IProps) => {
  return (
    <>
      <div className={cn(styles.square, styles.square1)} />
      <div className={cn(styles.square, styles.square2)} />
      <div className={cn(styles.square, styles.square3)} />
    </>
  );
};

export default Graphic3;
