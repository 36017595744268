import React, { useEffect, useState } from "react";
import Mobile from "./components/Mobile";
import Desktop from "./components/Desktop";
import { graphql, useStaticQuery } from "gatsby";
import * as styles from "./styles.module.scss";
import { IToolboxResource } from "~schemas";
import { useBreakpoints } from "~hooks";
import cn from "classnames";

export type TActiveAdvisorBlockIndex = 0 | 1 | 2 | 3 | null;
export type TAdvisorCategory = "Accountants" | "Lawyers" | "Advisory" | "Peers";

interface IQueryResults {
  allSanityToolboxResource: {
    nodes: IToolboxResource[];
  };
}

const Advisors = () => {
  const [loaded, setLoaded] = useState(false);
  const [activeBlockIndex, setActiveBlockIndex] =
    useState<TActiveAdvisorBlockIndex>(null);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const {
    allSanityToolboxResource: { nodes: advisors }
  } = useStaticQuery(query) as IQueryResults;

  const { desktop } = useBreakpoints();

  const categories: TAdvisorCategory[] = [
    "Accountants",
    "Lawyers",
    "Advisory",
    "Peers"
  ];

  return (
    <div className={cn(styles.container, { [styles.visible]: loaded })}>
      {desktop ? (
        <Desktop
          advisors={advisors}
          activeBlockIndex={activeBlockIndex}
          setActiveBlockIndex={setActiveBlockIndex}
          categories={categories}
        />
      ) : (
        <Mobile
          advisors={advisors}
          activeBlockIndex={activeBlockIndex}
          setActiveBlockIndex={setActiveBlockIndex}
          categories={categories}
        />
      )}
    </div>
  );
};

export default Advisors;

const query = graphql`
  query AdvisorQuery {
    allSanityToolboxResource(
      filter: { type: { title: { eq: "Contacts" } } }
      sort: { orderRank: ASC }
    ) {
      nodes {
        ...ToolboxResourceFragment
      }
    }
  }
`;
