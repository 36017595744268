// extracted by mini-css-extract-plugin
export var active = "styles-module--active--6c2e1";
export var cardContent = "styles-module--cardContent--9e998";
export var container = "styles-module--container--734bd";
export var contrastBackdrop = "styles-module--contrastBackdrop--19d40";
export var filters = "styles-module--filters--8d203";
export var filtersContainer = "styles-module--filtersContainer--8f50f";
export var flexContainer = "styles-module--flexContainer--c9a6d";
export var headerContainer = "styles-module--headerContainer--e82a9";
export var relativeContainer = "styles-module--relativeContainer--83a9d";
export var xButton = "styles-module--xButton--33a43";