import React, { CSSProperties } from "react";
import * as styles from "./styles.module.scss";
import { IQuote } from "~schemas";
import { SlicePadding, Grid, IntersectionAnimation } from "~components";
import QuoteGraphic from "./components/QuoteGraphic";
import cn from "classnames";
import { useInView } from "react-intersection-observer";

interface IProps {
  data: IQuote;
}

const Quote = ({
  data: { attribution, backgroundColor, graphicColor, quote, textColor }
}: IProps) => {
  const colorStyles: CSSProperties = {
    background: backgroundColor?.hex || `var(--color-white)`,
    color: textColor?.hex || `var(--color-black)`
  };

  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <div className={styles.container} style={colorStyles} ref={ref}>
      <Grid className={styles.grid}>
        <IntersectionAnimation
          className={styles.graphic}
          animation="fadeGrow"
          trigger={inView}
        >
          <QuoteGraphic color={graphicColor?.hex || `var(--color-black)`} />
        </IntersectionAnimation>

        <SlicePadding className={styles.text}>
          <IntersectionAnimation trigger={inView} delay={300}>
            <p className={cn(`h3`, styles.quote)}>{quote}</p>
            <p className="caption">{attribution}</p>
          </IntersectionAnimation>
        </SlicePadding>
      </Grid>
    </div>
  );
};

export default Quote;
