import React, { useEffect, useRef, useState } from "react";
import { IntersectionAnimation, SlicePadding, SVG, TSvg } from "~components";
import * as styles from "./styles.module.scss";
import { IVideo } from "~schemas";
import cn from "classnames";

interface IProps {
  data: IVideo;
}

const Video = ({
  data: { videoURL, caption, placeholderImage, videoSource, videoFile }
}: IProps) => {
  const [hasVideoPlaybackBegun, setHasVideoPlaybackBegun] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const videoSrc = videoSource === `url` ? videoURL : videoFile?.asset.url;

  const handleClick = () => {
    setHasVideoPlaybackBegun(true);
    setIsVideoPlaying((prev) => !prev);
  };

  useEffect(() => {
    if (isVideoPlaying) {
      videoRef.current?.play();
      setIsAtEnd(false);
    } else {
      videoRef.current?.pause();
    }
  }, [isVideoPlaying]);

  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
    setIsAtEnd(true);
  };

  useEffect(() => {
    videoRef.current?.addEventListener(`ended`, handleVideoEnd);
    return () => videoRef.current?.removeEventListener(`ended`, handleVideoEnd);
  }, [videoRef.current?.currentTime]);

  const buttonIcon: () => TSvg = () => {
    if (isAtEnd) {
      return `reset`;
    }
    if (isVideoPlaying) {
      return `pauseCircle`;
    }
    return `playCircle`;
  };

  const poster = placeholderImage?.asset.url;

  const showControls = hasVideoPlaybackBegun && !isAtEnd;

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: poster ? `url(${poster})` : undefined }} // Doing this so we don't need to enforce poster being same aspect ratio as video
    >
      <video
        playsInline
        onPause={() => setIsVideoPlaying(false)}
        onPlay={() => setIsVideoPlaying(true)}
        controls={showControls}
        className={cn(styles.video, {
          [styles.visible]: hasVideoPlaybackBegun
        })}
        ref={videoRef}
        src={poster ? videoSrc : `${videoSrc}#t=0.1`} // #t=0.1 sets first frame of video as poster
      />

      <div className={styles.absoluteContent}>
        {caption && (
          <div className={styles.captionContainer}>
            <SlicePadding xOnly>
              <IntersectionAnimation>
                <p className="b2">{caption}</p>
              </IntersectionAnimation>
            </SlicePadding>
          </div>
        )}

        <button
          type="button"
          onClick={handleClick}
          className={cn(styles.playPauseButton, {
            [styles.hidden]: hasVideoPlaybackBegun && !isAtEnd
          })}
        >
          <SVG svg={buttonIcon()} className={styles.icon} />
        </button>
      </div>
    </div>
  );
};

export default Video;
