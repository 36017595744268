import React from "react";
import { ISyndicate } from "~schemas";
import { Button, PortableTextRenderer, SlicePadding } from "~components";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import SyndicateCompanies from "./components/SyndicateCompanies";

interface IProps {
  data: ISyndicate;
}

const Syndicate = ({
  data: {
    _rawDisclaimer,
    _rawLearnMoreInfo,
    header,
    stats,
    syndicateLink,
    companies,
    text
  }
}: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.section1}>
        <SlicePadding className={styles.section1Content}>
          <h2 className={cn("h1", styles.header)}>{header}</h2>
          <div>
            <p className={cn("b1", styles.text1)}>{text}</p>

            <div className={styles.stats}>
              {stats.map(({ title, value }, i) => (
                <div key={i}>
                  <div className={cn("caption", styles.statLabel)}>{title}</div>
                  <div className={cn("h2")}>{value}</div>
                </div>
              ))}
            </div>

            <div className={styles.buttons}>
              <Button href={syndicateLink} iconRight="arrowRight">
                Join Our Syndicate
              </Button>

              <Button variant="secondary" to="/about">
                About Us
              </Button>
            </div>

            {_rawLearnMoreInfo && (
              <PortableTextRenderer
                className={cn("caption", styles.learnMoreInfo)}
                rawText={_rawLearnMoreInfo}
              />
            )}
          </div>
        </SlicePadding>
      </div>

      <SyndicateCompanies
        _rawDisclaimer={_rawDisclaimer}
        companies={companies}
      />
    </div>
  );
};

export default Syndicate;
