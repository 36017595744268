import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { MagicGraphic } from "~components";
import { TMagicGraphicVariation } from "~schemas";
import { getMagicGraphicColorStyles } from "~utils";

interface IProps {
  area: string;
  variation: TMagicGraphicVariation;
}

const InvestmentAreaTile = ({ area, variation }: IProps) => {
  return (
    <div className={styles.overflowContainer}>
      <div
        className={`${styles.container} investmentAreaTileContainer`}
        style={getMagicGraphicColorStyles(variation)}
      >
        <div className={cn(`h2`, styles.title)}>{area}</div>
        <div className={styles.graphicContainer}>
          <MagicGraphic graphicVariation={variation} />
        </div>
      </div>
    </div>
  );
};

export default InvestmentAreaTile;
