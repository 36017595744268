import React, { useState } from "react";
import { IEntity } from "~schemas";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  entity1: IEntity;
  entity2: IEntity;
  entity3: IEntity;
  entity4: IEntity;
}

export type TActiveEntity = null | 1 | 2 | 3 | 4;

const DesktopEntities = ({ entity1, entity2, entity3, entity4 }: IProps) => {
  const [activeEntity, setActiveEntity] = useState<TActiveEntity>(null);

  const reset = () => setActiveEntity(null);

  return (
    <div
      onMouseLeave={reset}
      className={cn(styles.container, {
        [styles.active1]: activeEntity === 1,
        [styles.active2]: activeEntity === 2,
        [styles.active3]: activeEntity === 3,
        [styles.active4]: activeEntity === 4
      })}
    >
      <EntityBlock
        className={cn(styles.entity, styles.entity1)}
        handleMouseMove={() => setActiveEntity(1)}
        entity={entity1}
      />

      <EntityBlock
        className={cn(styles.entity, styles.entity2)}
        handleMouseMove={() => setActiveEntity(2)}
        entity={entity2}
      />

      <EntityBlock
        className={cn(styles.entity, styles.entity3)}
        handleMouseMove={() => setActiveEntity(3)}
        entity={entity3}
      />

      <EntityBlock
        className={cn(styles.entity, styles.entity4)}
        handleMouseMove={() => setActiveEntity(4)}
        entity={entity4}
      />
    </div>
  );
};

export default DesktopEntities;

interface IEntityBlock {
  handleMouseMove: () => void;
  entity: IEntity;
  className: string;
}

const EntityBlock = ({ className, entity, handleMouseMove }: IEntityBlock) => (
  <div onMouseMove={handleMouseMove} className={cn(className)}>
    <div className={styles.entityContent}>
      <div className={styles.background} />
      <div className={styles.textContent}>
        <h4 className={cn(`h2`, styles.title)}>{entity.title}</h4>
        <div className={cn(`b1`, styles.statistic)}>{entity.statistic}</div>
        <p className={styles.description}>{entity.description}</p>
      </div>
    </div>
  </div>
);
